import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import * as teamMode from '../../../store/teamMode';
import { PRIVILEGES } from '../../../store/teamMode';
import TextButton from '../../buttons/TextButton/TextButton';
import './delegatingModal.scss';
import CaptainIcon from '../../icons/roles/CaptainIcon';
import DelegatingIcon from '../../icons/DelegatingIcon';
import WarningModalIcon from '../../icons/modal/WarningModalIcon';
import { CloseIcon } from '../../icons';
import classNames from '../../../common/classNames';
import AddNewSpeciality from './AddNewSpeciality';
import { Checkbox } from '../Checkbox';
import TeamAvatar from '../TeamAvatar/TeamAvatar';
import { Switcher } from '../Switcher';
import MinusIcon from '../../icons/MinusIcon';
import TextSwitcher from '../TextSwitcher/TextSwitcher';
import {
  CORP_DELEGATING_PRIVILEGES,
  DELEGATING_STATE_TITLES,
  DELEGATING_STATES,
  LIMITS_TITLE,
  TASK_TYPES,
} from '../../../store/constants';
import corpStore, { LIMITS_INFO } from '../../../store/CorpStore';
import SliderComponent from '../../../modules/slider/SliderComponent';
import SpecialityIcon from '../SpecialityIcon/SpecialityIcon';
import {
  ADDITIONAL_PRIVILEGES,
  PLAYER_ROLE,
  PRIVILEGES_I18N_RU,
  SPECIALITIES,
  SPECIALITIES_DEFAULT_PRIVILEGES,
  SPECIALITIES_RUSSIAN_NAME,
} from '../../../common/constants';
import { SLIDER_THEME } from '../../../store/constants';
import { DELEGATING_SWITCHER_PROPERTY } from './classes/DelegatingSwitcher';
import { CORP_DELEGATING_SWITCHERS } from './constants/CorpDelegatingSwithchers';

const TeamDelegatingComponent = observer(() => {
  const { appStore } = RootStore;
  const singlePlayer = appStore.company_players.length - appStore.timedOutCompanyPlayers.length === 1;

  if (!appStore.me) {
    return null;
  }
  const creditsSwitchersVisibled = appStore.isCorpMode
    ? appStore.credits.credit_mechanics &&
      (teamMode.playerHasPrivileges(
        appStore.myPrivileges,
        [teamMode.PRIVILEGES.GET_CREDIT, teamMode.PRIVILEGES.CREDIT_REPAY],
        true,
      ) ||
        appStore.hasSpecialities(SPECIALITIES.CAPTAIN))
    : appStore.credits.credit_mechanics;

  function getChangingPrivileges(captainPrivilege, forSpeciality) {
    return appStore
      .privilegesNeededForDelegatingToRoleByCaptain(captainPrivilege)
      .map((privilege) => [appStore.playerByRealSpeciality(forSpeciality).player_id, privilege]);
  }

  function getChangingTwoPrivileges(captainPrivilege1, captainPrivilege2, forSpeciality) {
    const ceoPrivilege1 = appStore.privilegesNeededForDelegatingToRoleByCaptain(captainPrivilege1);
    const ceoPrivilege2 = appStore.privilegesNeededForDelegatingToRoleByCaptain(captainPrivilege2);

    const player1Id = appStore.playerByRealSpeciality(forSpeciality).player_id;

    const privileges1 = [captainPrivilege1, ...ceoPrivilege1].map((privilege) => [player1Id, privilege]);
    const privileges2 = [captainPrivilege2, ...ceoPrivilege2].map((privilege) => [player1Id, privilege]);

    return [...privileges1, ...privileges2];
  }

  const ACTIONS_ITEMS = {
    [SPECIALITIES.CAPTAIN]: [],
    [SPECIALITIES.MARKETEER]: [
      {
        title: 'изменение витринных цен',
        disabled:
          appStore.isCorpMode &&
          !teamMode.playerHasPrivileges(
            appStore.myPrivileges,
            CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CHANGE_SHOWCASE_PRICES],
            true,
          ),
        switcher: {
          value: teamMode.playerHasPrivileges(
            appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER).privileges,
            appStore.privilegesNeededForDelegatingToRoleByCaptain(
              teamMode.PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_CAPTAIN,
            ),
            true,
          ),
          callback: (value) => {
            const changingPrivileges = getChangingPrivileges(
              teamMode.PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_CAPTAIN,
              SPECIALITIES.MARKETEER,
            );
            if (!value) {
              appStore.modifyPrivileges(changingPrivileges);
            } else {
              appStore.modifyPrivileges([], changingPrivileges);
            }
          },
        },
      },
      {
        title: 'взятие кредита',
        disabled:
          appStore.isCorpMode &&
          (!teamMode.playerHasPrivileges(
            appStore.myPrivileges,
            CORP_DELEGATING_PRIVILEGES[TASK_TYPES.GET_CREDIT],
            true,
          ) ||
            !teamMode.playerHasPrivileges(
              appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER).privileges,
              [PRIVILEGES.GET_CREDIT, PRIVILEGES.CREDIT_REPAY],
              true,
            )),
        visibled: creditsSwitchersVisibled,
        switcher: {
          value: teamMode.playerHasPrivileges(
            appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER).privileges,
            appStore.privilegesNeededForDelegatingToRoleByCaptain(teamMode.PRIVILEGES.APPROVE_GET_CREDIT_BY_CAPTAIN),
            true,
          ),
          callback: (value) => {
            const changingPrivileges = getChangingPrivileges(
              teamMode.PRIVILEGES.APPROVE_GET_CREDIT_BY_CAPTAIN,
              SPECIALITIES.MARKETEER,
            );
            if (!value) {
              appStore.modifyPrivileges(changingPrivileges);
            } else {
              appStore.modifyPrivileges([], changingPrivileges);
            }
          },
        },
      },
      {
        title: 'досрочное погашение кредита',
        disabled:
          appStore.isCorpMode &&
          (!teamMode.playerHasPrivileges(
            appStore.myPrivileges,
            CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CREDIT_REPAY],
            true,
          ) ||
            !teamMode.playerHasPrivileges(
              appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER).privileges,
              [PRIVILEGES.GET_CREDIT, PRIVILEGES.CREDIT_REPAY],
              true,
            )),
        visibled: creditsSwitchersVisibled,
        switcher: {
          value: teamMode.playerHasPrivileges(
            appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER).privileges,
            appStore.privilegesNeededForDelegatingToRoleByCaptain(teamMode.PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CAPTAIN),
            true,
          ),
          callback: (value) => {
            const changingPrivileges = getChangingPrivileges(
              teamMode.PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CAPTAIN,
              SPECIALITIES.MARKETEER,
            );
            if (!value) {
              appStore.modifyPrivileges(changingPrivileges);
            } else {
              appStore.modifyPrivileges([], changingPrivileges);
            }
          },
        },
      },
      {
        needCaptain: true,
        title: 'визирование цен в сделках',
        titleForMe: 'визирование цен в сделках (дополнительно)',
        visibled: !appStore.withDelegationLtd,
        checkbox: {
          value:
            !appStore
              .playerByRealSpeciality(SPECIALITIES.SALES)
              .privileges.includes(teamMode.PRIVILEGES.PUSH_TO_CAPTAIN) &&
            !appStore
              .playerByRealSpeciality(SPECIALITIES.SUPPLY)
              .privileges.includes(teamMode.PRIVILEGES.PUSH_TO_CAPTAIN),
          callback: (value) => {
            if (!value) {
              appStore.modifyPrivileges(
                [],
                [
                  [appStore.playerByRealSpeciality(SPECIALITIES.SALES).player_id, teamMode.PRIVILEGES.PUSH_TO_CAPTAIN],
                  [appStore.playerByRealSpeciality(SPECIALITIES.SUPPLY).player_id, teamMode.PRIVILEGES.PUSH_TO_CAPTAIN],
                ],
              );
            } else {
              appStore.modifyPrivileges([
                [appStore.playerByRealSpeciality(SPECIALITIES.SALES).player_id, teamMode.PRIVILEGES.PUSH_TO_CAPTAIN],
                [appStore.playerByRealSpeciality(SPECIALITIES.SUPPLY).player_id, teamMode.PRIVILEGES.PUSH_TO_CAPTAIN],
              ]);
            }
          },
        },
      },
      {
        title: 'визирование продаж котят городу',
        visibled: appStore.me.role === PLAYER_ROLE.SHOP,
        checkbox: {
          // задизейблено, если у продажника включена продажа котят городу без капитана,
          disabled:
            appStore
              .playerByRealSpeciality(SPECIALITIES.SALES)
              .privileges.includes(teamMode.PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN) ||
            (appStore.isCorpMode &&
              !teamMode.playerHasPrivileges(appStore.myPrivileges, CORP_DELEGATING_PRIVILEGES.CATS_SELL_TO_CITY)),
          disabledTip:
            'визирование продаж котят городу недоступно для маркетолога, так как Продажник получил делегирование продажи котят городу без согласования капитана',
          value: !appStore
            .playerByRealSpeciality(SPECIALITIES.SALES)
            .privileges.includes(teamMode.PRIVILEGES.CITY_SELL_PUSH_TO_CAPTAIN),
          callback: (value) => {
            if (!value) {
              appStore.modifyPrivileges(
                [],
                [
                  [
                    appStore.playerByRealSpeciality(SPECIALITIES.SALES).player_id,
                    teamMode.PRIVILEGES.CITY_SELL_PUSH_TO_CAPTAIN,
                  ],
                ],
              );
            } else {
              appStore.modifyPrivileges([
                [
                  appStore.playerByRealSpeciality(SPECIALITIES.SALES).player_id,
                  teamMode.PRIVILEGES.CITY_SELL_PUSH_TO_CAPTAIN,
                ],
              ]);
            }
          },
        },
        switcher: {
          disabled:
            appStore
              .playerByRealSpeciality(SPECIALITIES.SALES)
              .privileges.includes(teamMode.PRIVILEGES.CITY_SELL_PUSH_TO_CAPTAIN) ||
            (appStore.isCorpMode &&
              !teamMode.playerHasPrivileges(appStore.myPrivileges, CORP_DELEGATING_PRIVILEGES.CATS_SELL_TO_CITY)),
          value: teamMode.playerHasPrivileges(
            appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER).privileges,
            appStore.privilegesNeededForDelegatingToRoleByCaptain(
              teamMode.PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN,
            ),
            true,
          ),
          callback: (value) => {
            const changingPrivileges = getChangingPrivileges(
              teamMode.PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN,
              SPECIALITIES.MARKETEER,
            );
            if (!value) {
              appStore.modifyPrivileges(changingPrivileges);
            } else {
              appStore.modifyPrivileges([], changingPrivileges);
            }
          },
        },
      },
      {
        title: 'разрешить маркетологу отправлять сделки на доработку',
        titleForMe: 'отправка сделок на доработку',
        visibled: !appStore.withDelegationLtd,
        checkbox: {
          value: appStore
            .playerByRealSpeciality(SPECIALITIES.MARKETEER)
            .privileges.includes(teamMode.PRIVILEGES.CAN_DO_TO_REVISION),
          callback: (value) => {
            if (!value) {
              appStore.modifyPrivileges([
                [
                  appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER).player_id,
                  teamMode.PRIVILEGES.CAN_DO_TO_REVISION,
                ],
              ]);
            } else {
              appStore.modifyPrivileges(
                [],
                [
                  [
                    appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER).player_id,
                    teamMode.PRIVILEGES.CAN_DO_TO_REVISION,
                  ],
                ],
              );
            }
          },
        },
      },
      {
        title: 'Взаимодействие с долгосрочными сделками',
        visibled: appStore.withDelegationLtd,
        disabled:
          appStore.isCorpMode &&
          !teamMode.playerHasPrivileges(
            appStore.myPrivileges,
            CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CREATE_CHANGE_LTD_REQUEST],
            true,
          ),
        switcher: {
          value: !teamMode.playerHasPrivileges(
            appStore.playerByRealSpeciality(SPECIALITIES.SUPPLY).privileges,
            teamMode.PRIVILEGES.PUSH_LTD_TO_CAPTAIN,
            true,
          ),
          callback: (value) => {
            if (!value) {
              appStore.modifyPrivileges(
                [],
                [
                  [
                    appStore.playerByRealSpeciality(SPECIALITIES.SUPPLY).player_id,
                    teamMode.PRIVILEGES.PUSH_LTD_TO_CAPTAIN,
                  ],
                  [
                    appStore.playerByRealSpeciality(SPECIALITIES.SALES).player_id,
                    teamMode.PRIVILEGES.PUSH_LTD_TO_CAPTAIN,
                  ],
                ],
                teamMode.PRIVILEGES.PUSH_LTD_TO_CAPTAIN,
              );
            } else {
              appStore.modifyPrivileges(
                [
                  [
                    appStore.playerByRealSpeciality(SPECIALITIES.SUPPLY).player_id,
                    teamMode.PRIVILEGES.PUSH_LTD_TO_CAPTAIN,
                  ],
                  [
                    appStore.playerByRealSpeciality(SPECIALITIES.SALES).player_id,
                    teamMode.PRIVILEGES.PUSH_LTD_TO_CAPTAIN,
                  ],
                ],
                [],
              );
            }
          },
        },
      },
      {
        title: 'Оплата долгосрочных сделок',
        visibled: appStore.withDelegationLtd,
        disabled:
          appStore.isCorpMode &&
          !teamMode.playerHasPrivileges(
            appStore.myPrivileges,
            CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CREATE_CHANGE_LTD_REQUEST],
            true,
          ),
        switcher: {
          value: teamMode.playerHasPrivileges(
            appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER).privileges,
            teamMode.PRIVILEGES.LTD_PAYMENT_ACTION_BY_MARKET,
            true,
          ),
          callback: (value) => {
            const changingPrivileges = getChangingPrivileges(
              teamMode.PRIVILEGES.LTD_PAYMENT_ACTION_BY_MARKET,
              SPECIALITIES.MARKETEER,
            );
            if (!value) {
              appStore.modifyPrivileges([
                [
                  appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER).player_id,
                  teamMode.PRIVILEGES.LTD_PAYMENT_ACTION_BY_MARKET,
                ],
                ...changingPrivileges,
              ]);
            } else {
              appStore.modifyPrivileges([], changingPrivileges);
            }
          },
        },
      },
    ],
    [SPECIALITIES.SALES]: [
      {
        needCaptain: true,
        title: `продажа котят ${appStore.me.role === PLAYER_ROLE.SHOP ? 'питомникам' : 'зоомагазинам'}`,
        visibled: !appStore.withDelegationLtd,
      },
      {
        title: 'продажа котят городу',
        visibled: appStore.me.role === PLAYER_ROLE.SHOP,
        switcher: {
          value: teamMode.playerHasPrivileges(
            appStore.playerByRealSpeciality(SPECIALITIES.SALES).privileges,
            appStore.privilegesNeededForDelegatingToRoleByCaptain(
              teamMode.PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN,
            ),
            true,
          ),
          disabled:
            appStore.isCorpMode &&
            !teamMode.playerHasPrivileges(appStore.myPrivileges, CORP_DELEGATING_PRIVILEGES.CATS_SELL_TO_CITY, true),
          callback: (value) => {
            const changingPrivileges = getChangingPrivileges(
              teamMode.PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN,
              SPECIALITIES.SALES,
            );
            if (!value) {
              appStore.modifyPrivileges([
                [
                  appStore.playerByRealSpeciality(SPECIALITIES.SALES).player_id,
                  teamMode.PRIVILEGES.CITY_SELL_PUSH_TO_CAPTAIN,
                ],
                ...changingPrivileges,
              ]);
            } else {
              appStore.modifyPrivileges([], changingPrivileges);
            }
          },
        },
      },
      {
        title: 'отмена собственной заявки',
        visibled: !appStore.withDelegationLtd,
        switcher: {
          value: appStore
            .playerByRealSpeciality(SPECIALITIES.SALES)
            .privileges.includes(teamMode.PRIVILEGES.CANCEL_SELL_REQUEST_BY_CAPTAIN),
          disabled: appStore
            .playerByRealSpeciality(SPECIALITIES.SALES)
            .privileges.includes(teamMode.PRIVILEGES.APPROVE_TASK_BY_CAPTAIN),
          callback: (value) => {
            if (!value) {
              appStore.modifyPrivileges([
                [
                  appStore.playerByRealSpeciality(SPECIALITIES.SALES).player_id,
                  teamMode.PRIVILEGES.CANCEL_SELL_REQUEST_BY_CAPTAIN,
                ],
              ]);
            } else {
              appStore.modifyPrivileges(
                [],
                [
                  [
                    appStore.playerByRealSpeciality(SPECIALITIES.SALES).player_id,
                    teamMode.PRIVILEGES.CANCEL_SELL_REQUEST_BY_CAPTAIN,
                  ],
                ],
              );
            }
          },
        },
      },
      {
        title: 'Взаимодействие с долгосрочными сделками на продажу',
        visibled: appStore.withDelegationLtd,
        disabled:
          appStore.isCorpMode &&
          !teamMode.playerHasPrivileges(
            appStore.myPrivileges,
            CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CREATE_CHANGE_LTD_REQUEST],
            true,
          ),
        switcher: {
          value: teamMode.playerHasPrivileges(
            appStore.playerByRealSpeciality(SPECIALITIES.SALES).privileges,
            [teamMode.PRIVILEGES.CREATE_LTD_TASK],
            true,
          ),
          callback: (value) => {
            if (!value) {
              appStore.modifyPrivileges(
                [[appStore.playerByRealSpeciality(SPECIALITIES.SALES).player_id, teamMode.PRIVILEGES.CREATE_LTD_TASK]],
                [],
              );
            } else {
              appStore.modifyPrivileges(
                [],
                [[appStore.playerByRealSpeciality(SPECIALITIES.SALES).player_id, teamMode.PRIVILEGES.CREATE_LTD_TASK]],
              );
            }
          },
        },
      },
    ],
    [SPECIALITIES.SUPPLY]: [
      {
        needCaptain: true,
        title: `покупка котят у ${appStore.me.role === PLAYER_ROLE.SHOP ? 'питомника' : 'зоомагазинов'}`,
        visibled: !appStore.withDelegationLtd,
      },
      {
        title: 'покупка котят у города',
        visibled: appStore.me.role === PLAYER_ROLE.SHOP,
        switcher: {
          value: teamMode.playerHasPrivileges(
            appStore.playerByRealSpeciality(SPECIALITIES.SUPPLY).privileges,
            appStore.privilegesNeededForDelegatingToRoleByCaptain(teamMode.PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CAPTAIN),
            true,
          ),
          disabled:
            appStore.isCorpMode &&
            !teamMode.playerHasPrivileges(appStore.myPrivileges, CORP_DELEGATING_PRIVILEGES.CATS_BUY_FROM_CITY, true),
          callback: (value) => {
            const changingPrivileges = getChangingPrivileges(
              teamMode.PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CAPTAIN,
              SPECIALITIES.SUPPLY,
            );
            if (!value) {
              appStore.modifyPrivileges(changingPrivileges);
            } else {
              appStore.modifyPrivileges([], changingPrivileges);
            }
          },
        },
      },
      {
        title: 'отмена собственной сделки',
        visibled: !appStore.withDelegationLtd,
        switcher: {
          value: appStore
            .playerByRealSpeciality(SPECIALITIES.SUPPLY)
            .privileges.includes(teamMode.PRIVILEGES.CANCEL_BUY_REQUEST_BY_CAPTAIN),
          disabled: appStore
            .playerByRealSpeciality(SPECIALITIES.SUPPLY)
            .privileges.includes(teamMode.PRIVILEGES.APPROVE_TASK_BY_CAPTAIN),
          callback: (value) => {
            if (!value) {
              appStore.modifyPrivileges([
                [
                  appStore.playerByRealSpeciality(SPECIALITIES.SUPPLY).player_id,
                  teamMode.PRIVILEGES.CANCEL_BUY_REQUEST_BY_CAPTAIN,
                ],
              ]);
            } else {
              appStore.modifyPrivileges(
                [],
                [
                  [
                    appStore.playerByRealSpeciality(SPECIALITIES.SUPPLY).player_id,
                    teamMode.PRIVILEGES.CANCEL_BUY_REQUEST_BY_CAPTAIN,
                  ],
                ],
              );
            }
          },
        },
      },
      {
        title: 'Взаимодействие с долгосрочными сделками на покупку',
        visibled: appStore.withDelegationLtd,
        disabled:
          appStore.isCorpMode &&
          !teamMode.playerHasPrivileges(
            appStore.myPrivileges,
            CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CREATE_CHANGE_LTD_REQUEST],
            true,
          ),
        switcher: {
          value: teamMode.playerHasPrivileges(
            appStore.playerByRealSpeciality(SPECIALITIES.SUPPLY).privileges,
            [teamMode.PRIVILEGES.CREATE_LTD_TASK],
            true,
          ),
          callback: (value) => {
            if (!value) {
              appStore.modifyPrivileges(
                [[appStore.playerByRealSpeciality(SPECIALITIES.SUPPLY).player_id, teamMode.PRIVILEGES.CREATE_LTD_TASK]],
                [],
              );
            } else {
              appStore.modifyPrivileges(
                [],
                [[appStore.playerByRealSpeciality(SPECIALITIES.SUPPLY).player_id, teamMode.PRIVILEGES.CREATE_LTD_TASK]],
              );
            }
          },
        },
      },
    ],
    [SPECIALITIES.DOCTOR]: [
      {
        title: 'страхование домиков',
        visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
        switcher: {
          value: appStore
            .playerByRealSpeciality(SPECIALITIES.DOCTOR)
            .privileges.includes(teamMode.PRIVILEGES.APPROVE_INSURANCE_PURCHASE_BY_CAPTAIN),
          callback: (value) => {
            if (!value) {
              appStore.modifyPrivileges([
                [
                  appStore.playerByRealSpeciality(SPECIALITIES.DOCTOR).player_id,
                  teamMode.PRIVILEGES.APPROVE_INSURANCE_PURCHASE_BY_CAPTAIN,
                ],
              ]);
            } else {
              appStore.modifyPrivileges(
                [],
                [
                  [
                    appStore.playerByRealSpeciality(SPECIALITIES.DOCTOR).player_id,
                    teamMode.PRIVILEGES.APPROVE_INSURANCE_PURCHASE_BY_CAPTAIN,
                  ],
                ],
              );
            }
          },
        },
      },
      {
        title: 'покупка корма',
        visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
        disabled:
          appStore.isCorpMode &&
          !teamMode.playerHasPrivileges(appStore.myPrivileges, CORP_DELEGATING_PRIVILEGES[TASK_TYPES.BUY_FOOD], true),
        switcher: {
          value: teamMode.playerHasPrivileges(
            appStore.playerByRealSpeciality(SPECIALITIES.DOCTOR).privileges,
            appStore.privilegesNeededForDelegatingToRoleByCaptain(teamMode.PRIVILEGES.APPROVE_FOOD_PURCHASE_BY_CAPTAIN),
            true,
          ),
          callback: (value) => {
            const changingPrivileges = getChangingPrivileges(
              teamMode.PRIVILEGES.APPROVE_FOOD_PURCHASE_BY_CAPTAIN,
              SPECIALITIES.DOCTOR,
            );
            if (!value) {
              appStore.modifyPrivileges(changingPrivileges);
            } else {
              appStore.modifyPrivileges([], changingPrivileges);
            }
          },
        },
      },
      {
        title: 'покупка домиков',
        visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
        disabled:
          appStore.isCorpMode &&
          !teamMode.playerHasPrivileges(appStore.myPrivileges, CORP_DELEGATING_PRIVILEGES[TASK_TYPES.BUY_HOUSE], true),
        switcher: {
          value: teamMode.playerHasPrivileges(
            appStore.playerByRealSpeciality(SPECIALITIES.DOCTOR).privileges,
            appStore.privilegesNeededForDelegatingToRoleByCaptain(
              teamMode.PRIVILEGES.APPROVE_HOUSE_PURCHASE_BY_CAPTAIN,
            ),
            true,
          ),
          callback: (value) => {
            const changingPrivileges = getChangingPrivileges(
              teamMode.PRIVILEGES.APPROVE_HOUSE_PURCHASE_BY_CAPTAIN,
              SPECIALITIES.DOCTOR,
            );
            if (!value) {
              appStore.modifyPrivileges(changingPrivileges);
            } else {
              appStore.modifyPrivileges([], changingPrivileges);
            }
          },
        },
      },
      {
        title: 'покупка лекарств',
        visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
        disabled:
          appStore.isCorpMode &&
          !teamMode.playerHasPrivileges(appStore.myPrivileges, CORP_DELEGATING_PRIVILEGES[TASK_TYPES.BUY_DRUGS], true),
        switcher: {
          value: teamMode.playerHasPrivileges(
            appStore.playerByRealSpeciality(SPECIALITIES.DOCTOR).privileges,
            appStore.privilegesNeededForDelegatingToRoleByCaptain(
              teamMode.PRIVILEGES.APPROVE_DRUGS_PURCHASE_BY_CAPTAIN,
            ),
            true,
          ),
          callback: (value) => {
            const changingPrivileges = getChangingPrivileges(
              teamMode.PRIVILEGES.APPROVE_DRUGS_PURCHASE_BY_CAPTAIN,
              SPECIALITIES.DOCTOR,
            );
            if (!value) {
              appStore.modifyPrivileges(changingPrivileges);
            } else {
              appStore.modifyPrivileges([], changingPrivileges);
            }
          },
        },
      },
      {
        title: 'скрещивание котиков',
        visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
        disabled:
          appStore.isCorpMode &&
          !teamMode.playerHasPrivileges(appStore.myPrivileges, CORP_DELEGATING_PRIVILEGES[TASK_TYPES.MATING], true),
        switcher: {
          value: teamMode.playerHasPrivileges(
            appStore.playerByRealSpeciality(SPECIALITIES.DOCTOR).privileges,
            appStore.privilegesNeededForDelegatingToRoleByCaptain(teamMode.PRIVILEGES.APPROVE_MATING_BY_CAPTAIN),
            true,
          ),
          callback: (value) => {
            const changingPrivileges = getChangingPrivileges(
              teamMode.PRIVILEGES.APPROVE_MATING_BY_CAPTAIN,
              SPECIALITIES.DOCTOR,
            );
            if (!value) {
              appStore.modifyPrivileges(changingPrivileges);
            } else {
              appStore.modifyPrivileges([], changingPrivileges);
            }
          },
        },
      },
      {
        title: 'осмотр котят в сделках',
      },
      {
        title: 'кормление котиков',
        visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
      },
      {
        title: 'лечение котят',
        visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
      },
      {
        title: 'расселение котят',
        visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
      },
    ],
  };

  Object.keys(ACTIONS_ITEMS).forEach((speciality) => {
    ACTIONS_ITEMS[speciality] = ACTIONS_ITEMS[speciality].filter(
      (action) => action.visibled || action.visibled === undefined,
    );
  });

  const ActionsRender = observer((props) => {
    const actions = ACTIONS_ITEMS[props.speciality];
    if (props.readonly) {
      return actions
        .filter((item) => (item.checkbox && item.checkbox.value) || item.switcher || (!item.switcher && !item.checkbox))
        .filter((item) => !item.readonlyTitle)
        .map((item, ind) => {
          let icon = <SpecialityIcon speciality={props.speciality} />;
          if (item.needCaptain || (item.switcher && !item.switcher.value)) {
            icon = <CaptainIcon />;
          }
          return (
            <div
              key={ind}
              className={classNames({
                delegating_actions__item: true,
              })}
            >
              <span className="delegating_actions__item-title">
                <span>
                  {icon}
                  {!appStore.hasSpecialities([SPECIALITIES.CAPTAIN]) && item.titleForMe ? item.titleForMe : item.title}
                </span>
              </span>
            </div>
          );
        });
    }
    return actions.map((item, ind) => {
      return (
        <div
          key={ind}
          className={classNames({
            delegating_actions__item: !item.readonlyTitle,
            delegating_actions_title: item.readonlyTitle,
            'delegating_actions__item--disabled': item.disabled,
          })}
          title={(item.checkbox && item.checkbox.disabled === true && item.checkbox.disabledTip) || null}
        >
          {item.checkbox && (
            <Checkbox
              disabled={item.disabled || item.checkbox.disabled === true}
              onClick={() => item.checkbox.callback(item.checkbox.value)}
              value={item.checkbox.value}
            />
          )}
          {item.switcher && (
            <Switcher
              disabled={item.disabled || item.switcher.disabled === true}
              onClick={() => item.switcher.callback(item.switcher.value)}
              value={item.switcher.value}
            />
          )}
          <span
            className={classNames({
              'delegating_actions__item-title': !item.readonlyTitle,
              delegating_actions_title_name: item.readonlyTitle,
            })}
            style={{ textTransform: item.readonlyTitle ? 'none' : 'uppercase' }}
          >
            <span className="delegating_actions_title_name">
              {item.needCaptain && <CaptainIcon />}
              {item.title}
            </span>
          </span>
        </div>
      );
    });
  });

  const RoleBlock = observer((props) => {
    const name = SPECIALITIES_RUSSIAN_NAME[props.speciality];
    const player = appStore.companyPlayerBySpeciality(props.speciality);
    const additionalSpecialities = appStore.timedOutCompanyPlayers
      .filter((timedOutPlayer) => teamMode.playerHasSpecialities(player.privileges, [timedOutPlayer.specialty]))
      .filter((timedOutPlayer) => teamMode.hasDefaultSpecialtyPrivileges(player.privileges, timedOutPlayer.specialty))
      .map((timedOutPlayer) => timedOutPlayer.specialty);
    const specialitiesToAdd = Object.values(SPECIALITIES).filter((spec) => {
      const activePlayers = appStore.company_players.filter((p) => !p.is_timed_out);
      return (
        spec !== SPECIALITIES.CEO &&
        !activePlayers.find((activePlayer) => {
          return teamMode.playerHasSpecialities(activePlayer.privileges, [spec]);
        })
      );
    });
    const privilegesToAdd = [];
    const additionalPrivileges = [];
    if (appStore.withPrivilegesChange && appStore.me.role === PLAYER_ROLE.SHOP) {
      ADDITIONAL_PRIVILEGES.forEach((privilege) => {
        if (player.privileges.includes(privilege)) {
          if (!SPECIALITIES_DEFAULT_PRIVILEGES[player.specialty].includes(privilege)) {
            additionalPrivileges.push(privilege);
          }
        } else {
          privilegesToAdd.push(privilege);
        }
      });
    }
    const addSpecialitiesToCurrentSpeciality = (specialityList) => {
      specialityList.map((speciality) => {
        appStore.delegateSpecialty(player.player_id, speciality);
      });
    };
    function addPrivileges(privileges) {
      privileges.map((privelege) => {
        appStore.addPrivilege(player.player_id, privelege);
      });
    }
    const Logo = appStore.me.logo;
    return (
      player && (
        <div
          className={classNames({
            delegating_actions: true,
            disabled: player.is_timed_out,
            'delegating_actions--readonly': props.readonly,
          })}
        >
          {!props.readonly && (
            <div className="delegating_actions_title">
              <span className="delegating_actions_title_name">
                <TeamAvatar Logo={Logo} speciality={props.speciality} />
                {name}
              </span>
              {player.is_timed_out && <span className="delegating_actions_title_timeout">Участник выбыл из игры</span>}
              {!player.is_timed_out &&
                (specialitiesToAdd.length > 0 || privilegesToAdd.length > 0) &&
                !teamMode.playerHasSpecialities(player.privileges, [SPECIALITIES.CAPTAIN]) && (
                  <AddNewSpeciality
                    specialties={specialitiesToAdd}
                    privileges={privilegesToAdd}
                    addSpecialties={addSpecialitiesToCurrentSpeciality}
                    addPrivileges={addPrivileges}
                  />
                )}
            </div>
          )}
          {!player.is_timed_out && (
            <ActionsRender playerId={player.player_id} speciality={props.speciality} readonly={props.readonly} />
          )}
          {!player.is_timed_out &&
            additionalSpecialities.map((speciality) => {
              return (
                <div key={speciality}>
                  <div className="delegating_actions_title delegating_actions_title--additional">
                    <span>
                      {!props.readonly && <SpecialityIcon speciality={speciality} />} Дополнительная роль:{' '}
                      {SPECIALITIES_RUSSIAN_NAME[speciality]}
                    </span>
                    {!props.readonly && speciality !== SPECIALITIES.CAPTAIN && (
                      <div
                        onClick={() => {
                          appStore.delegateSpecialty(player.player_id, speciality, true);
                        }}
                        className="add-new-role"
                      >
                        <MinusIcon width={21} height={21} />
                      </div>
                    )}
                  </div>
                  <ActionsRender playerId={player.player_id} speciality={speciality} readonly={props.readonly} />
                </div>
              );
            })}
          {additionalPrivileges.length > 0 && (
            <>
              <div className="delegating_actions_title">Дополнительные полномочия</div>
              {additionalPrivileges.map((privilege) => {
                return (
                  <div key={privilege}>
                    <div className="delegating_actions_item delegating_actions_item__privileges">
                      <span className="delegating_actions__item-title">
                        <span className="delegating_actions_title_name">
                          <CaptainIcon />
                          {PRIVILEGES_I18N_RU[privilege]}
                        </span>
                      </span>
                      {!props.readonly && (
                        <div
                          onClick={() => {
                            appStore.removePrivilege(player.player_id, privilege, true);
                          }}
                          className="add-new-role"
                        >
                          <MinusIcon width={21} height={21} />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      )
    );
  });

  let content = null;
  if (singlePlayer) {
    content = (
      <>
        <div className="delegating_text">
          Вы играете один.
          <br />
          Все участники игры выбыли.
        </div>
        <div className="modal__body-actions">
          <TextButton color="blue" title="понятно" onClick={appStore.hideDelegatingModal} />
        </div>
      </>
    );
  } else if (!appStore.hasSpecialities(SPECIALITIES.CAPTAIN)) {
    return (
      <Modal
        isOpen={appStore.delegatingModal}
        onClose={appStore.hideDelegatingModal}
        size="big"
        head={true}
        headLeft={
          <div className="modal__head_left" onClick={appStore.openDelegatingInfoModal}>
            <WarningModalIcon />
            Описание <br /> ролей
          </div>
        }
        headIcon={
          <div className="delegating-no-captain-head">
            <TeamAvatar Logo={appStore.me.logo} speciality={appStore.mySpeciality} />
            <span className="delegating-no-captain-head__name">{SPECIALITIES_RUSSIAN_NAME[appStore.mySpeciality]}</span>
          </div>
        }
        headRight={
          <div className="modal__head_right">
            <CloseIcon
              onClick={appStore.hideDelegatingModal}
              style={{ width: 28, height: 28, background: '#02516c', fill: '#fdf6ee', padding: 4, borderRadius: 3 }}
            />
          </div>
        }
      >
        <RoleBlock speciality={appStore.mySpeciality} readonly />
        <div className="modal__body-actions">
          <TextButton color="blue" title="закрыть" onClick={appStore.hideDelegatingModal} />
        </div>
      </Modal>
    );
  } else {
    content = (
      <>
        <RoleBlock speciality={SPECIALITIES.MARKETEER} />
        <RoleBlock speciality={SPECIALITIES.SALES} />
        <RoleBlock speciality={SPECIALITIES.SUPPLY} />
        <RoleBlock speciality={SPECIALITIES.DOCTOR} />
        <div
          className={classNames({
            'modal__body-actions': true,
            'delegating-actions': appStore.isCorpMode && appStore.hasSpecialities(SPECIALITIES.CAPTAIN),
          })}
        >
          <TextButton color="blue" title="закрыть" onClick={appStore.hideDelegatingModal} />
          {appStore.isCorpMode && appStore.hasSpecialities(SPECIALITIES.CAPTAIN) && (
            <TextButton
              color="green"
              title="лимиты"
              onClick={() =>
                corpStore.openCurrentSeasonLimitsModal({
                  closeButtonText: 'Назад',
                })
              }
            />
          )}
        </div>
      </>
    );
  }
  return content;
});

const CorpDelegatingComponent = observer(() => {
  // TODO: bool значение в useState должно браться из appStore

  const [currentArea, setCurrentArea] = useState('all_areas');
  const { appStore } = RootStore;

  if (!corpStore.myActiveCompanies.length) {
    return (
      <>
        <div className="delegating_text">
          Вы играете один.
          <br />
          Все Ваши города выбыли.
        </div>
        <div className="modal__body-actions">
          <TextButton color="blue" title="понятно" onClick={appStore.hideDelegatingModal} />
        </div>
      </>
    );
  }

  if (!appStore.me) {
    return null;
  }

  const areaNums = ['all_areas', ...corpStore.allActiveAreaNums];
  const areaNumsLabels = areaNums.map((area) => (area === 'all_areas' ? 'Все города' : 'Город ' + area));

  return (
    <div>
      <div className="delegating__area_nums">
        <SliderComponent
          sliderCustomClass="delegating-slider"
          sliderItemCustomClass="delegating-slider__item"
          labels={areaNumsLabels}
          afterChange={(current) => {
            setCurrentArea(areaNums[current]);
          }}
          theme={SLIDER_THEME.DARK}
          currentSlideIndex={areaNums.indexOf('all_areas')}
        />
      </div>
      <div className="delegating__switcher-blocks">
        {CORP_DELEGATING_SWITCHERS.filter((switcher) => switcher.visible).map((switcher) => {
          const switcherValue = switcher.getState({ areaNum: currentArea });
          const checkBoxForReadonly = switcher[DELEGATING_SWITCHER_PROPERTY.CHECK_BOX_FOR_READONLY];
          const checkBoxForReadonlyValue = checkBoxForReadonly
            ? checkBoxForReadonly.value({ areaNum: currentArea })
            : null;

          return (
            <div className="delegating__switcher-block" key={switcher[DELEGATING_SWITCHER_PROPERTY.LABEL]}>
              {checkBoxForReadonly && checkBoxForReadonly.visible && (
                <Checkbox
                  onClick={() => {
                    checkBoxForReadonly.changeValue({ areaNum: currentArea });
                    switcher.changePrivileges({ state: DELEGATING_STATES.CEO, areaNum: currentArea });
                  }}
                  value={checkBoxForReadonlyValue}
                />
              )}
              <TextSwitcher
                options={Object.keys(switcher[DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]).map((switcherType) => {
                  return {
                    label: DELEGATING_STATE_TITLES[switcherType],
                    value: switcherType,
                  };
                })}
                disabled={checkBoxForReadonly && !checkBoxForReadonlyValue}
                value={switcherValue}
                onChange={(state) => {
                  if (state !== switcherValue) {
                    switcher.changePrivileges({ state, areaNum: currentArea });
                  }
                }}
              />
              <span
                className={classNames({
                  'delegating__switcher-label': true,
                  'delegating__switcher-label--disabled':
                    !switcherValue || (checkBoxForReadonly && !checkBoxForReadonlyValue),
                })}
              >
                {switcher[DELEGATING_SWITCHER_PROPERTY.LABEL]}
              </span>
            </div>
          );
        })}
      </div>
      <div className="delegating__limits">
        <div className="delegating__limits-title">Устанавливать лимит команде по статьям расхода на каждый сезон?</div>
        {Object.keys(LIMITS_INFO).map((checkboxType) => {
          const checkBox = LIMITS_INFO[checkboxType];
          const limitType = checkBox.limitType;
          let value;
          let disabled;
          if (currentArea === 'all_areas') {
            disabled = !corpStore.allPlayersOfAllAreasHasPrivileges(
              SPECIALITIES.CAPTAIN,
              checkBox.privileges,
              checkBox.allPrivilegesNeeded,
              true,
            );
            value = corpStore.companiesOfAllAreasHasTeamLimit(checkBox.limitType, true);
            if (!value && corpStore.companiesOfAllAreasHasTeamLimit(checkBox.limitType, false)) {
              value = null;
            }
          } else {
            disabled = !teamMode.playerHasPrivileges(
              corpStore.playerBySpecialityAndAreaNum(SPECIALITIES.CAPTAIN, currentArea).privileges,
              checkBox.privileges,
              checkBox.allPrivilegesNeeded,
            );
            value = corpStore.companyHasTeamLimitLocally(currentArea, checkBox.limitType);
          }
          if (!(checkBox.visibled ? checkBox.visibled() : true)) {
            return;
          }
          return (
            <div key={limitType} className="delegating__limits-checkbox-block">
              <Checkbox
                disabled={disabled}
                onClick={() => {
                  corpStore.setLocalLimitCheckboxValue(currentArea, checkBox.limitType, value);
                  corpStore.sendCorpLimits(currentArea);
                }}
                value={value}
              />
              <span
                className={classNames({
                  'delegating__limits-checkbox-label': true,
                  'delegating__limits-checkbox-label--active': value,
                })}
              >
                {LIMITS_TITLE[limitType]}
              </span>
            </div>
          );
        })}
      </div>

      <div className="modal__body-actions delegating-actions">
        <TextButton
          title="Закрыть"
          color="blue"
          onClick={() => {
            appStore.hideDelegatingModal();
            if (appStore.meIsCEO) {
              corpStore.resetLocalLimitCheckBox();
            }
          }}
        />
        <TextButton
          title="Настроить лимиты"
          color="green"
          isDisabled={corpStore.noActiveLimitsOfAllAreaNums}
          onClick={() => {
            if (corpStore.noActiveLimitsOfAllAreaNums) {
              return;
            }
            corpStore.openLimitModal({
              closeButtonText: 'Назад',
            });
          }}
        />
      </div>
    </div>
  );
});

function DelegatingModal() {
  const { appStore } = RootStore;
  if ((!appStore.company_players.length && !appStore.isCorpMode) || !appStore.delegatingModal) {
    return null;
  }

  const singlePlayer = appStore.company_players.length - appStore.timedOutCompanyPlayers.length === 1;

  let title;
  let desc;
  let Content;

  if (appStore.isCorpMode && appStore.meIsCEO) {
    title = 'Согласование действий корпорации';
    desc = corpStore.myActiveCompanies.length
      ? 'Вы можете закрепить за капитанами команд согласование действий. Либо дать капитанам возможность делегировать согласование своей команде.'
      : '';
    Content = CorpDelegatingComponent;
  } else {
    title = 'ДЕЛЕГИРОВАНИЕ';
    desc =
      !singlePlayer &&
      appStore.hasSpecialities(SPECIALITIES.CAPTAIN) &&
      'У Вас есть возможность передать членам команды часть своих функций.';
    Content = TeamDelegatingComponent;
  }

  function close() {
    if (appStore.meIsCEO) {
      corpStore.resetLocalLimitCheckBox();
    }
    appStore.hideDelegatingModal();
  }

  return (
    <Modal
      isOpen={appStore.delegatingModal}
      onClose={close}
      title={title}
      titleIcon={<DelegatingIcon className="modal-title-icon" />}
      desc={desc}
      size="big"
      head={true}
      headLeft={
        <div className="modal__head_left" onClick={appStore.openDelegatingInfoModal}>
          <WarningModalIcon />
          Описание <br /> {appStore.meIsCEO ? 'СЕО' : 'ролей'}
        </div>
      }
      headIcon={
        appStore.isTeamMode &&
        !appStore.isCorpMode && <TeamAvatar Logo={appStore.me.logo} speciality={appStore.mySpeciality} />
      }
      headRight={
        <div className="modal__head_right">
          <CloseIcon
            onClick={close}
            style={{ width: 28, height: 28, background: '#02516c', fill: '#fdf6ee', padding: 4, borderRadius: 3 }}
          />
        </div>
      }
      customClass="delegating"
    >
      <Content />
    </Modal>
  );
}

export default observer(DelegatingModal);
