import { observer } from 'mobx-react';
import RootStore from '../../../../store';
import React from 'react';
import TextButton from '../../../../ui/buttons/TextButton/TextButton';
import { LTD_DOCTOR_REQUEST_STATUSES, TASK_TYPES } from '../../../../store/constants';
import routes from '../../../../routes';
import { DislikeIcon, LikeIcon, SendIcon, TeamDoctorIcon } from '../../../../ui/icons';
import * as teamMode from '../../../../store/teamMode';
import classNames from '../../../../common/classNames';
import { LTD_ACTIONS, PLAYER_ROLE, PRIVILEGES, SPECIALITIES } from '../../../../common/constants';

function LotPanelActionsLTD() {
  const { appStore } = RootStore;
  const liabilityLTD = appStore.currentLiabilityLTD;
  const paymentBeforeShipmentTimerFailed =
    appStore.me.actionLTD.actionDetail.action_type === LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT &&
    appStore.paymentBeforeShipmentTimerFailed;
  const value = appStore.timerValue.subtract(appStore.me.actionLTD.actionDetail.timer, 'seconds');
  let timerFail = false;
  if (value.$ms < 0) {
    timerFail = true;
  }
  const dealId = appStore.me.actionLTD.ltd_info.deal_id;
  const ltdType =
    appStore.me.actionLTD.actionDetail.action_type === LTD_ACTIONS.TO_STORAGE
      ? 'sell'
      : appStore.me.actionLTD.actionDetail.action_type === LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT && 'buy';
  const statusOfDoctorLTDStorageReviewingRequest = appStore.statusOfDoctorLTDStorageReviewingRequest;
  const meIsNurseryOnToStorageAction =
    appStore.me.role === PLAYER_ROLE.NURSERY &&
    appStore.me.actionLTD.actionDetail.action_type === LTD_ACTIONS.TO_STORAGE;
  const LTDHasLowQualityLiability =
    appStore.me.actionLTD.ltd_info.low_quality_liability &&
    (appStore.me.actionLTD.actionDetail.action_type === LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT ||
      appStore.me.actionLTD.actionDetail.action_type === LTD_ACTIONS.TO_STORAGE);
  const sendToDoctorReviewFlag =
    ((appStore.isCorpMode && appStore.meIsCEO) ||
      (appStore.isRealTeamMode && appStore.hasSpecialities(SPECIALITIES.CAPTAIN)) ||
      ((appStore.hasSpecialities(SPECIALITIES.SUPPLY) || appStore.hasSpecialities(SPECIALITIES.SALES)) &&
        appStore.myPrivileges.find((privilege) => privilege === PRIVILEGES.APPROVE_LTD_TASK))) &&
    statusOfDoctorLTDStorageReviewingRequest === LTD_DOCTOR_REQUEST_STATUSES.AWAITING_REQUEST &&
    (meIsNurseryOnToStorageAction || LTDHasLowQualityLiability);
  const storageCheck = appStore.ltdBuyContent(dealId)?.storage_checked;

  let title;
  if (sendToDoctorReviewFlag) {
    title = <SendIcon style={{ fill: 'white' }} />;
  } else if (liabilityLTD.action_type === LTD_ACTIONS.TO_STORAGE) {
    title = 'Отправить контрагенту';
  } else {
    title = 'Подтвердить';
  }

  function handleSendClick() {
    if (
      liabilityLTD.action_type === LTD_ACTIONS.TO_STORAGE &&
      appStore.catsLTDFromStorage.find((cat) => cat.factCount !== cat.needCount)
    ) {
      appStore.openLTDModalIncompleteStorage({
        deal_id: liabilityLTD.deal_id,
        action: appStore.me.actionLTD.actionDetail,
      });
    } else {
      const actionWasPerformed = appStore.LTDPerfomAction(
        {
          deal_id: liabilityLTD.deal_id,
          action_type:
            liabilityLTD.action_type === LTD_ACTIONS.TO_STORAGE
              ? LTD_ACTIONS.TO_STORAGE
              : LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT,
          action: appStore.me.actionLTD.actionDetail,
        },
        routes.main,
      );

      if (actionWasPerformed) {
        RootStore.goTo(routes.main);
      }
    }
  }

  function handleSendToDoctorClick() {
    appStore.sendLTDDoctorReviewRequest(dealId, ltdType);
    appStore.setTeamTaskNotificationsModal({
      latestAction: {
        act_name: teamMode.PRIVILEGES.DOCTOR_REQUEST,
        params: {
          visa: true,
          to_revision: false,
        },
      },
      taskType: TASK_TYPES.FAKE_DOCTOR_REVIEW_REQUEST,
      task_id: null,
    });
  }

  function handleLikeClick() {
    appStore.LTDPerfomAction({
      deal_id: liabilityLTD.deal_id,
      action_type: LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT,
      action: appStore.me.actionLTD.actionDetail,
    });
    RootStore.goTo(routes.main);
  }

  function handleDislikeClick() {
    appStore.openLTDLiabilityModal(liabilityLTD, true, true);
  }

  return (
    <div
      className={classNames({
        'lot-panel__actions': true,
        'lot-panel__actions--lots-of-buttons':
          !appStore.LTDActionIsFine && paymentBeforeShipmentTimerFailed && sendToDoctorReviewFlag,
      })}
    >
      {appStore.LTDActionIsFine ? (
        <TextButton
          title={title}
          customClass="lot-panel__ltd-button"
          color={storageCheck && appStore.hasSpecialities(SPECIALITIES.SUPPLY) ? 'grey' : 'green'}
          onClick={handleSendClick}
          isDisabled={storageCheck && appStore.hasSpecialities(SPECIALITIES.SUPPLY)}
        />
      ) : (
        <>
          {paymentBeforeShipmentTimerFailed && (
            <TextButton title={<DislikeIcon style={{ fill: '#FDF6EE' }} />} color="red" onClick={handleDislikeClick} />
          )}
          <TextButton
            title={<LikeIcon style={{ fill: '#FDF6EE' }} />}
            color={storageCheck && appStore.hasSpecialities(SPECIALITIES.SUPPLY) ? 'grey' : 'green'}
            onClick={handleLikeClick}
            isDisabled={storageCheck && appStore.hasSpecialities(SPECIALITIES.SUPPLY)}
          />
        </>
      )}
      {sendToDoctorReviewFlag && (
        <TextButton
          onClick={handleSendToDoctorClick}
          color="blue"
          title={<TeamDoctorIcon isActive={true} />}
          isDisabled={false}
        />
      )}
    </div>
  );
}

export default observer(LotPanelActionsLTD);
