import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import Modal from '../Modal/Modal';
import Cat, { BOTTOM_TEXT, CAT_STATE } from '../Cat/Cat';
import Price from '../Cat/Price';
import TextButton from '../../buttons/TextButton/TextButton';
import * as teamMode from '../../../store/teamMode';
import './styles.scss';
import { TEAM_TASK_NOTIFICATIONS } from '../TeamNotificationsModal/TeamTaskNotificationsModal';
import classNames from '../../../common/classNames';
import { CatFaceIcon, EatIcon, HouseIcon } from '../../icons';
import InsuranceModalIcon from '../../icons/modal/InsuranceModalIcon';
import { REQUEST_TYPE, SPECIALITIES } from '../../../common/constants';
import SicknessRingworm from '../../icons/SicknessRingworm';
import SicknessFracture from '../../icons/SicknessFracture';
import SicknessPoisoning from '../../icons/SicknessPoisoning';
import SicknessFleas from '../../icons/SicknessFleas';
import CreditField from '../Credit/CreditField';
import { CREDIT_TYPE_NAME, LIMITS_TYPE, TRANSFER_STATUS, TASK_TYPES, ROLE_NAME } from '../../../store/constants';
import EarlyRepaymentComponent from '../Credit/EarlyRepaymentComponent';
import corpStore from '../../../store/CorpStore';
import WalletIcon from '../../icons/modal/WalletIcon';
import { declOfNum } from '../../../common/declensionOfWords';
import AttentionIcon from '../../icons/AttentionIcon';
import CurrentTaskModalContentPrice from './CurrentTaskModalContentPrice';
import CurrentTaskModalPriceLimitExceeded from './CurrentTaskModalPriceLimitExceeded';
import { Swiper, SwiperSlide } from 'swiper/react';
import { currentTaskModalPriceLimitTextsByLimitType, teamLimitTypeText } from './CurrentTaskModalFunctions';
import useWindow from '../../hooks/useWindow';

function getTaskConstructor(currentTask) {
  const { appStore } = RootStore;
  let taskConstructor;

  if (currentTask.type === TASK_TYPES.CHANGE_SHOWCASE_PRICES) {
    let modalSize = 'very-big';
    const modalTitle = 'Витринные цены';
    const contents = currentTask.content.contents[0];
    const defaultShowcasePrices = currentTask.content.showcase_prices[`${contents.color}_${contents.gender}`];
    let groupedCats;
    if (appStore.meIsCEO) {
      groupedCats = corpStore.corpOurCats[corpStore.areaNumByPlayerId(currentTask.company_id)];
    } else {
      groupedCats = appStore.playerCats[appStore.me.uuid];
    }
    const defaultCat = groupedCats.find((c) => {
      return c.color === contents.color && c.gender === contents.gender;
    });
    if (defaultCat.sell_price === contents.sell_price || defaultCat.buy_price === contents.buy_price) {
      modalSize = 'big';
    }
    taskConstructor = { modalTitle, modalSize, contents, defaultCat, defaultShowcasePrices };
  } else if (currentTask.type === TASK_TYPES.BUY_FOOD) {
    const modalTitle = 'Покупка корма';
    const modalSize = '';
    const price = appStore.gameCosts.food * currentTask.content.quantity;
    taskConstructor = { modalTitle, modalSize, price, buyFood: true };
  } else if (currentTask.type === TASK_TYPES.BUY_HOUSE) {
    const modalTitle = 'Покупка домика';
    const modalSize = '';
    const price = appStore.gameCosts.house;
    taskConstructor = { modalTitle, modalSize, price, buyHouse: true };
  } else if (currentTask.type === TASK_TYPES.BUY_HOUSE_INSURANCE) {
    const modalTitle = 'Страховка домика';
    const modalSize = '';
    const price = appStore.gameCosts.insurance;
    taskConstructor = { modalTitle, modalSize, price, insuranceHouse: true };
  } else if (currentTask.type === TASK_TYPES.CITY_TRADE) {
    const cat = currentTask.content.contents[0];
    const price = cat.price;
    const modalTitle = currentTask.content.request_type === REQUEST_TYPE.BUY ? 'покупка у города' : 'продажа городу';
    const modalSize = '';
    taskConstructor = { price, cat, modalTitle, modalSize, cityTrade: true };
  } else if (currentTask.type === TASK_TYPES.BUY_DRUGS) {
    const drugs = currentTask.content.drugs;
    let drugCount = 1;
    const typeOfDrug = Object.keys(drugs).find((drugKey) => {
      if (drugs[drugKey] !== 0) {
        drugCount = drugs[drugKey];
        return true;
      } else {
        return false;
      }
    });
    const modalTitle = 'покупка лекарства';
    const modalSize = '';
    const price =
      appStore.gameCosts.treatment *
      Object.values(currentTask.content.drugs).reduce((acc, drug) => {
        return acc + drug;
      }, 0);
    taskConstructor = { drugCount, typeOfDrug, price, modalTitle, modalSize, buyDrugs: true };
  } else if (currentTask.type === TASK_TYPES.MATING) {
    const modalSize = '';
    const modalTitle = 'скрещивание';
    let houseNumber = 1;

    let houses;
    let groupedHouses;

    if (appStore.isCorpMode && appStore.meIsCEO) {
      houses = corpStore.allHousesOfCorporation;
      groupedHouses = appStore.groupedHouses[corpStore.areaNumByHouseId(currentTask.content.house_id)];
    } else {
      houses = appStore.houses;
      groupedHouses = appStore.groupedHouses;
    }

    const house = groupedHouses.find((groupedHouse, index) => {
      if (
        groupedHouse.left.data.house_id === currentTask.content.house_id ||
        groupedHouse.right.data.house_id === currentTask.content.house_id
      ) {
        houseNumber = index + 1;
        return true;
      } else {
        return false;
      }
    });

    const currentHouse = houses.find((house) => house.house_id === currentTask.content.house_id);
    const leftCat = appStore.catById(currentHouse.left_cat_id);
    const rightCat = appStore.catById(currentHouse.right_cat_id);
    const cats = [leftCat, rightCat];
    taskConstructor = { cats, houseNumber, modalSize, modalTitle, mating: true };
  } else if (currentTask.type === TASK_TYPES.GET_CREDIT) {
    const creditData = currentTask.content;
    const modalTitle = 'взятие кредита';
    const modalDescription = CREDIT_TYPE_NAME[creditData.credit_type];
    const modalSize = 'big-get-credit';
    const price = creditData.money_quantity;
    taskConstructor = { modalTitle, modalSize, creditData, price, modalDescription, getCredit: true };
  } else if (currentTask.type === TASK_TYPES.CREDIT_REPAY) {
    let credits = [];
    if (appStore.meIsCEO) {
      corpStore.getInfoFromAllAreasByProperty('credits').forEach((credit) => credits.push(...credit.credits_info));
    } else {
      credits = appStore.credits.credits_info;
    }
    const creditData = credits.find((credit) => credit.credit_id === currentTask.content.credit_id);
    const modalTitle = 'досрочное погашение кредита';
    const modalDescription = CREDIT_TYPE_NAME[creditData.credit_type];
    const modalSize = 'big-credit-repay';
    taskConstructor = { modalTitle, modalSize, creditData, modalDescription, creditRepay: true };
  } else if (currentTask.type === TASK_TYPES.FAKE_IMPORT_TASK) {
    const areaNum = corpStore.areaNumByPlayerId(currentTask.buyer);
    const price = currentTask.post_payment_price;
    const lotId = currentTask.lot_id;
    const status = currentTask.import_status;
    const modalTitle =
      currentTask.import_status === TRANSFER_STATUS.PAID ? (
        <span>
          Вы совершили <br /> постоплату импорта
        </span>
      ) : (
        <span>
          Хочешь совершить
          <br />
          постоплату импорта?
        </span>
      );
    const modalDescription = `импорт котиков в город ${areaNum}`;
    taskConstructor = { modalTitle, price, modalDescription, import: true, lotId, status };
  } else if (currentTask.type === TASK_TYPES.CREATE_CHANGE_LTD_REQUEST) {
    const partner = appStore.playerByUuid(currentTask.content.to);

    const modalTitle = 'создание долгосрочной \n сделки на покупку и продажу';

    const modalDescription = `в ${ROLE_NAME[partner.role]} ${partner.name}`;

    const modalSize = 'big';

    taskConstructor = { modalTitle, modalDescription, modalSize, approveBuySellLtd: true };
  }
  return taskConstructor;
}

function CurrentTaskModal() {
  const { isMobile } = useWindow();
  const [sell_price, setSellPrice] = React.useState(0);
  const [buy_price, setBuyPrice] = React.useState(0);
  React.useEffect(() => {
    if (RootStore.appStore.currentTaskModalData) {
      if (appStore.currentTaskModalData.type === TASK_TYPES.CHANGE_SHOWCASE_PRICES) {
        setSellPrice(RootStore.appStore.currentTaskModalData.content.contents[0].sell_price);
        setBuyPrice(RootStore.appStore.currentTaskModalData.content.contents[0].buy_price);
      }
    }
  }, [!!RootStore.appStore.currentTaskModalData]);
  const { appStore } = RootStore;
  const isItActionInCurrentTaskModal = true;
  const currentAction = appStore.currentAction(isItActionInCurrentTaskModal);
  const currentTask = appStore.currentTaskModalData;

  // Уведомления по превышениям лимитов
  const currentTaskExceededLimits = corpStore.currentTaskExceededLimits;
  const hasExceededLimits = currentTaskExceededLimits && currentTaskExceededLimits.length;
  const notDeviationFromShowcaseLimit =
    hasExceededLimits &&
    currentTaskExceededLimits.find(
      ([limitType, value]) =>
        ![LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_BUY, LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_SELL].some(
          (_limitType) => limitType === _limitType,
        ),
    );
  const notDeviationFromShowcaseLimitType = notDeviationFromShowcaseLimit && notDeviationFromShowcaseLimit[0];
  const deviationFromShowcaseLimit =
    hasExceededLimits &&
    currentTaskExceededLimits.find(([limitType, value]) =>
      [LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_BUY, LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_SELL].includes(
        limitType,
      ),
    );
  const deviationFromShowcaseLimitType = deviationFromShowcaseLimit && deviationFromShowcaseLimit[0];
  const deviationFromShowcaseLimitInfo = deviationFromShowcaseLimit && deviationFromShowcaseLimit[1];

  if (
    !currentTask ||
    (currentTask.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST &&
      !deviationFromShowcaseLimit &&
      notDeviationFromShowcaseLimitType !== LIMITS_TYPE.SPENDING_ON_CAT_BUY)
  ) {
    return null;
  }

  let taskConstructor = {};
  if (
    appStore.isCorpMode &&
    appStore.hasSpecialities([SPECIALITIES.CEO, SPECIALITIES.CAPTAIN]) &&
    corpStore.currentTaskExceededLimits
  ) {
    // Уведомления по превышениям лимитов
    const exceededLimits = corpStore.currentTaskExceededLimits;
    const exceededLimitsCount = exceededLimits.length;
    const defaultTaskConstructor = getTaskConstructor(currentTask);
    let modalTitle;
    let modalDescription;
    let contents;
    let swiperIsActive;
    let modalSize = defaultTaskConstructor?.modalSize;
    let catsWithShowcaseLimits;
    let isCityTradeLimit;
    let price = defaultTaskConstructor?.price;
    const areaNum = appStore.meIsCEO ? corpStore.areaNumByPlayerId(currentTask.company_id) : appStore.areaNum;
    const limitInfo = (limitType) => {
      if ([LIMITS_TYPE.GET_CREDIT, LIMITS_TYPE.GET_CREDIT_BY_TEAM].includes(limitType)) {
        return {
          limitType,
          creditType: defaultTaskConstructor.creditData.credit_type,
        };
      }
      return { limitType };
    };
    if (exceededLimitsCount > 1) {
      const texts = corpStore.currentTaskExceededLimits
        .map(([limitType, value]) => teamLimitTypeText(limitInfo(limitType)))
        .join(' и ');
      modalTitle = `${exceededLimitsCount} ${declOfNum(exceededLimitsCount, ['Лимит', 'Лимита', 'Лимитов'])} превышены`;
      modalDescription = `${texts} в городе ${areaNum}`;
    } else {
      const limit = corpStore.currentTaskExceededLimits[0];
      const limitType = limit[0];
      modalTitle = 'Лимит превышен';
      modalDescription = `${teamLimitTypeText(limitInfo(limitType))} в городе ${areaNum}`;
    }

    if (deviationFromShowcaseLimit || notDeviationFromShowcaseLimitType === LIMITS_TYPE.SPENDING_ON_CAT_BUY) {
      modalSize = 'small';
      const task_contents = appStore.groupCats(currentTask.content)[0];
      contents = [];
      catsWithShowcaseLimits = [];
      task_contents.forEach((cat) => {
        const catLimitInfo =
          deviationFromShowcaseLimit &&
          deviationFromShowcaseLimitInfo.find((_cat) => {
            return _cat.gender === cat.gender && _cat.color === cat.color;
          });
        if (catLimitInfo) {
          catsWithShowcaseLimits.push({ ...cat, limitInfo: catLimitInfo });
        } else {
          contents.push(cat);
        }
      });
      price = appStore.getLotPrice(appStore.currentPlayer?.lot);
      if (deviationFromShowcaseLimit) {
        swiperIsActive = catsWithShowcaseLimits.length > 1 || contents.length;
        if ((contents.length || catsWithShowcaseLimits.length > 1) && !isMobile) {
          modalSize = 'very-big';
        }
      } else {
        swiperIsActive = contents.length > 1;
        modalSize = 'small';
        if (defaultTaskConstructor?.cityTrade) {
          isCityTradeLimit = true;
          defaultTaskConstructor.cityTrade = false;
          price = defaultTaskConstructor.cat.price;
        }
      }

      if (
        isMobile &&
        (deviationFromShowcaseLimit || notDeviationFromShowcaseLimitType === LIMITS_TYPE.SPENDING_ON_CAT_BUY)
      ) {
        modalSize = 'small-limits-with-cat-contents';
      }
    }
    taskConstructor = {
      ...defaultTaskConstructor,
      modalTitle,
      contents,
      price,
      catsWithShowcaseLimits,
      modalDescription,
      modalSize,
      swiperIsActive,
      isAttention: true,
      isCityTradeLimit,
    };
  } else {
    taskConstructor = getTaskConstructor(currentTask);
  }

  const taskDecision = (visa, to_revision) => {
    if (appStore.isSpectatorModeOn) {
      appStore.openSpectatorModal();
      return;
    }
    appStore.hideCurrentTaskModal();
    let newContent = {};
    if (currentTask.type === TASK_TYPES.CHANGE_SHOWCASE_PRICES) {
      newContent = { ...currentTask.content };
      const oldSellPrice = currentTask.content.contents[0].sell_price;
      const oldBuyPrice = currentTask.content.contents[0].buy_price;
      if (oldSellPrice && oldSellPrice !== sell_price) {
        currentTask.content.contents[0].sell_price = sell_price;
      }
      if (oldBuyPrice && oldBuyPrice !== buy_price) {
        currentTask.content.contents[0].buy_price = buy_price;
      }
    } else {
      newContent = currentTask.content;
    }
    appStore.sendMessageActionsOnTask(currentTask.task_id, newContent, visa, to_revision, currentTask.type);
  };

  const constructorContent = () => {
    if (taskConstructor.buyDrugs) {
      const drugs = currentTask.content.drugs;
      return (
        <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
          {Object.keys(drugs).map((currentDrugName, ind) => {
            if (drugs[currentDrugName] === 0) {
              return null;
            }
            const getCurrentDrugIcon = () => {
              if (currentDrugName === 'salve') {
                return <SicknessRingworm style={{ fill: '#C6A788', width: 80, height: 80 }} />;
              } else if (currentDrugName === 'bandage') {
                return <SicknessFracture style={{ fill: '#C6A788', width: 80, height: 80 }} />;
              } else if (currentDrugName === 'pills') {
                return <SicknessPoisoning style={{ fill: '#C6A788', width: 80, height: 80 }} />;
              } else if (currentDrugName === 'shampoo') {
                return <SicknessFleas style={{ fill: '#C6A788', width: 80, height: 80 }} />;
              } else {
                return null;
              }
            };
            const currentDrug = getCurrentDrugIcon();
            if (!currentDrug) {
              return null;
            }
            return (
              <div
                key={ind}
                className={classNames({
                  'task-constructor__content-icon': true,
                })}
              >
                <span className="task-constructor__content-quantity">
                  {taskConstructor.buyDrugs && '×' + currentTask.content.drugs[currentDrugName]}
                </span>
                {currentDrug}
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div
          className={classNames({
            'task-constructor__content-icon': true,
            'task-constructor__content-icon--cat': taskConstructor.cityTrade,
          })}
        >
          {!taskConstructor.cityTrade && !taskConstructor.mating && (
            <span className="task-constructor__content-quantity">
              {taskConstructor.buyFood && '×' + currentTask.content.quantity}
              {(taskConstructor.buyHouse || taskConstructor.insuranceHouse) && '×1'}
            </span>
          )}
          {taskConstructor.buyFood && <EatIcon stylePath={{ fill: '#C6A788' }} style={{ width: 80, height: 80 }} />}
          {taskConstructor.buyHouse && <HouseIcon style={{ width: 80, height: 80, fill: '#C6A788' }} />}
          {taskConstructor.insuranceHouse && (
            <InsuranceModalIcon stylePath={{ fill: '#C6A788' }} style={{ width: 80, height: 80 }} />
          )}
          {taskConstructor.cityTrade && (
            <Cat bottomText={BOTTOM_TEXT.KIND} state={CAT_STATE.SINGLE} cat={taskConstructor.cat} readonly={true} />
          )}
        </div>
      );
    }
  };

  return (
    <Modal
      isOpen={!!appStore.currentTaskModalData}
      onClose={appStore.hideCurrentTaskModal}
      title={
        <span
          className={classNames({
            'current-task__title': true,
            'current-task__title--attention': taskConstructor.isAttention,
          })}
        >
          {taskConstructor.isAttention && <AttentionIcon />}
          <span>{taskConstructor.modalTitle}</span>
        </span>
      }
      size={classNames({
        [taskConstructor.modalSize]: true,
      })}
      desc={!!taskConstructor.modalDescription && taskConstructor.modalDescription}
      exitIcon={true}
    >
      <div
        className={classNames({
          'current-task': true,
          'current-task--import': taskConstructor.import,
          'current-task--limit': hasExceededLimits,
          'current-task--limit-with-deviation-showcase-limits': deviationFromShowcaseLimit,
        })}
      >
        {taskConstructor.mating && (
          <>
            <div className="task-constructor__content-mating">
              {appStore.hasSpecialities(SPECIALITIES.CAPTAIN) || appStore.hasSpecialities(SPECIALITIES.CEO)
                ? `направлено доктором в домике №${taskConstructor.houseNumber}`
                : TEAM_TASK_NOTIFICATIONS.CAPTAIN}
            </div>
            <div
              className={classNames({
                'modal__body-cats--each-other-look': true,
              })}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              {taskConstructor.cats.map((cat) => {
                return (
                  <Cat
                    key={cat.cat_id}
                    readonly={true}
                    cat={cat}
                    bottomText={BOTTOM_TEXT.KIND}
                    state={CAT_STATE.SINGLE}
                  />
                );
              })}
            </div>
          </>
        )}
        {taskConstructor.getCredit && (
          <div className="current-task__item current-task__item--get-credit">
            <CreditField readOnly={true} item={taskConstructor.creditData} />
          </div>
        )}
        {taskConstructor.creditRepay && (
          <div className="current-task__item current-task__item--get-credit">
            <EarlyRepaymentComponent
              repaymentAmount={taskConstructor.creditData.full_overpayment}
              prePaymentAmount={
                taskConstructor.creditData.early_repayment_body + taskConstructor.creditData.early_repayment_percent
              }
            />
          </div>
        )}
        {taskConstructor.defaultCat && taskConstructor.contents && (
          <div className="current-task__wrapper">
            {
              <div className="current-task__item">
                <div className="modal__price-title">Старая цена покупки</div>
                <Price
                  customClass="current-task-price"
                  isFakeWithPrice={true}
                  price={taskConstructor.defaultShowcasePrices.buy_price}
                  withoutStopPropagation
                />
                <div className="modal__price-title">Новая цена покупки</div>
                <Price
                  isFakeWithPrice={
                    currentAction?.isOnlyCancelAction || !appStore.hasSpecialities([SPECIALITIES.MARKETEER])
                  }
                  price={buy_price}
                  withoutStopPropagation
                  onMinus={() => {
                    setBuyPrice(buy_price - 1);
                  }}
                  onPlus={() => {
                    setBuyPrice(buy_price + 1);
                  }}
                />
              </div>
            }
            {
              <div className="current-task__item">
                <div className="modal__price-title">Старая цена продажи</div>
                <Price
                  customClass="current-task-price"
                  isFakeWithPrice={true}
                  price={taskConstructor.defaultShowcasePrices.sell_price}
                  withoutStopPropagation
                />
                <div className="modal__price-title">Новая цена продажи</div>
                <Price
                  isFakeWithPrice={
                    currentAction?.isOnlyCancelAction || !appStore.hasSpecialities([SPECIALITIES.MARKETEER])
                  }
                  price={sell_price}
                  withoutStopPropagation
                  onMinus={() => {
                    setSellPrice(sell_price - 1);
                  }}
                  onPlus={() => {
                    setSellPrice(sell_price + 1);
                  }}
                />
              </div>
            }
            <div className="current-task__item">
              <Cat
                readonly={true}
                cat={taskConstructor.defaultCat}
                notShowCount={true}
                state={CAT_STATE.SINGLE}
                bottomText={BOTTOM_TEXT.KIND}
              />
            </div>
          </div>
        )}
        {taskConstructor.import && (
          <>
            <div className="current-task__item">
              <div className="modal__body-icon">
                <WalletIcon color="#C6A788" />
              </div>
              <div className="current-task-price">
                <CurrentTaskModalContentPrice text="итого цена" price={taskConstructor.price} />
              </div>
            </div>
          </>
        )}
        {(taskConstructor.buyFood ||
          taskConstructor.buyHouse ||
          taskConstructor.insuranceHouse ||
          taskConstructor.cityTrade ||
          taskConstructor.buyDrugs) && (
          <div
            className={classNames({
              'current-task__wrapper': true,
              'current-task__wrapper--limits': hasExceededLimits,
            })}
          >
            <div className="current-task__item">
              {constructorContent()}
              {!taskConstructor.mating && !hasExceededLimits && (
                <CurrentTaskModalContentPrice price={taskConstructor.price} text="итого цена" />
              )}
            </div>
          </div>
        )}
        {(!!deviationFromShowcaseLimit || notDeviationFromShowcaseLimitType === LIMITS_TYPE.SPENDING_ON_CAT_BUY) && (
          <div className="current-task__deviation-from-showcase-limit-wrapper">
            <Swiper
              touchStartPreventDefault={false}
              threshold={0}
              touchAngle={40}
              freeModeMomentumRatio={0.2}
              freeModeMomentumVelocityRatio={0.2}
              slidesPerView={'auto'}
              freeMode={true}
              grabCursor={taskConstructor.swiperIsActive}
              noSwiping={!taskConstructor.swiperIsActive}
              noSwipingSelector=".swiper-container"
            >
              {taskConstructor.catsWithShowcaseLimits.map((cat, index) => {
                return (
                  <SwiperSlide key={`catsWithShowcaseLimits--${index}`}>
                    <div
                      key={index}
                      className={classNames({
                        'current-task__wrapper': true,
                        'current-task__wrapper--deviation-from-showcase-limit': true,
                        'current-task__wrapper--deviation-from-showcase-limit-with-other-content':
                          taskConstructor.contents.length ||
                          (taskConstructor.catsWithShowcaseLimits.length > 1 &&
                            index !== taskConstructor.catsWithShowcaseLimits.length - 1),
                      })}
                    >
                      <div className="current-task__item">
                        {currentTaskModalPriceLimitTextsByLimitType(deviationFromShowcaseLimitType).map(
                          (text, priceElementIndex) => {
                            let price;
                            let prefix = '';
                            if (priceElementIndex === 0) {
                              price = cat.limitInfo.showcase_price;
                            } else if (priceElementIndex === 1) {
                              price = cat.limitInfo.limit_value;
                              prefix =
                                deviationFromShowcaseLimitType === LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_SELL
                                  ? '-'
                                  : '+';
                            } else if (priceElementIndex === 2) {
                              price = cat.limitInfo.content_price;
                            }
                            return (
                              <div key={priceElementIndex} className="current-task-price-block">
                                <div className="modal__price-title">{text}</div>
                                <Price
                                  customClass="current-task-price"
                                  isFakeWithPrice={true}
                                  price={`${prefix}${price}`}
                                  rightCoinPosition={!!prefix}
                                  withoutStopPropagation
                                />
                              </div>
                            );
                          },
                        )}
                      </div>
                      <div className="current-task__item">
                        <Cat
                          readonly={true}
                          cat={cat}
                          customCount={cat.count}
                          state={CAT_STATE.SINGLE}
                          bottomText={BOTTOM_TEXT.KIND}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
              {taskConstructor.contents.map((cat, index) => {
                return (
                  <SwiperSlide key={`contents--${index}`}>
                    <div className="current-task__item" key={index}>
                      <Cat
                        readonly={true}
                        cat={cat}
                        customCount={cat.count}
                        state={CAT_STATE.SINGLE}
                        bottomText={BOTTOM_TEXT.KIND}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}
        {notDeviationFromShowcaseLimit && (
          <CurrentTaskModalPriceLimitExceeded
            taskConstructor={taskConstructor}
            currentTask={currentTask}
            exceededLimit={notDeviationFromShowcaseLimit}
            withDeviationShowcaseLimit={deviationFromShowcaseLimit}
          />
        )}
      </div>
      <div
        className={classNames({
          'modal__body-actions': true,
          'current-task__actions': true,
          'current-task__actions--import': taskConstructor.import,
          'current-task__actions--big': !taskConstructor.creditRepay && taskConstructor.modalSize === 'big',
        })}
      >
        {deviationFromShowcaseLimit ||
        (notDeviationFromShowcaseLimitType === LIMITS_TYPE.SPENDING_ON_CAT_BUY && !taskConstructor.isCityTradeLimit) ? (
          <TextButton color="blue" title="Понятно" onClick={() => appStore.hideCurrentTaskModal()} />
        ) : currentAction ? (
          !currentAction.isOnlyCancelAction ? (
            <>
              {currentAction.canSendToRevision && (
                <TextButton color="yellow" title="Доработать" onClick={() => taskDecision(false, true)} />
              )}
              {!currentAction.isOnlyApproveAction && (
                <TextButton color="red" title="Отклонить" onClick={() => taskDecision(false, false)} />
              )}
              <TextButton color="green" title="Согласовать" onClick={() => taskDecision(true, false)} />
            </>
          ) : (
            <>
              <TextButton color="blue" title="Понятно" onClick={() => appStore.hideCurrentTaskModal()} />
              <TextButton color="red" title="Отменить" onClick={() => taskDecision(false, false)} />
            </>
          )
        ) : appStore.meIsCEO &&
          taskConstructor.import &&
          taskConstructor.status === TRANSFER_STATUS.AWAITING_PAYMENT ? (
          <>
            <TextButton
              color="green"
              title="Оплатить"
              onClick={() => {
                appStore.sendImportPaymentRequest(taskConstructor.lotId, taskConstructor.price);
              }}
            />
            <TextButton color="blue" title="Отмена" onClick={() => appStore.hideCurrentTaskModal()} />
          </>
        ) : (
          <TextButton color="blue" title="Понятно" onClick={() => appStore.hideCurrentTaskModal()} />
        )}
      </div>
    </Modal>
  );
}

export default observer(CurrentTaskModal);
