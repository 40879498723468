import Modal from '../Modal/Modal';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import TextButton from '../../buttons/TextButton/TextButton';
import DislikeIcon from '../../icons/DislikeIcon';
import './styles.scss';
import IconButton from '../../buttons/IconButton/IconButton';
import CaptainIcon from '../../icons/roles/CaptainIcon';
import SupplyIcon from '../../icons/roles/SupplyIcon';
import SalesIcon from '../../icons/roles/SalesIcon';
import MarketingIcon from '../../icons/roles/MarketingIcon';
import DoctorIcon from '../../icons/roles/DoctorIcon';
import EstimationTimerIcon from '../../icons/EstimationTimerIcon';
import { LocalStorage } from '../../../common/localStorage';
import CEOIcon from '../../icons/roles/CEOIcon';
import orderPlayersBySpeciality from '../../../common/orderPlayersBySpeciality';
import formattedEstimationTimer from '../../../common/formattedEstimationTimer';
import classNames from '../../../common/classNames';
import { SPECIALITIES, SPECIALITIES_RUSSIAN_NAME } from '../../../common/constants';

function TeamEstimationModal() {
  const { appStore } = RootStore;
  const [playerWithDislike, setPlayerWithDislike] = useState('');
  if (!appStore.isTeamMode || !appStore.estimationModalFlag) {
    return null;
  }

  const formattedCompanyPlayers = !appStore.meIsCEO
    ? orderPlayersBySpeciality(appStore.company_players.slice())
    : corpStore.corporationCompanyPlayers;

  const estimationTimer = appStore.estimationTimer;
  const formattedTimer = formattedEstimationTimer(appStore.estimationTimer);

  const SpecialityIcon = ({ player }) => {
    if (player.specialty === SPECIALITIES.CAPTAIN) {
      return <CaptainIcon style={{ width: '24', height: '24' }} />;
    } else if (player.specialty === SPECIALITIES.SUPPLY) {
      return <SupplyIcon style={{ width: '24', height: '24' }} />;
    } else if (player.specialty === SPECIALITIES.SALES) {
      return <SalesIcon style={{ width: '24', height: '24' }} />;
    } else if (player.specialty === SPECIALITIES.MARKETEER) {
      return <MarketingIcon style={{ width: '24', height: '24' }} />;
    } else if (player.specialty === SPECIALITIES.DOCTOR) {
      return <DoctorIcon style={{ width: '24', height: '24' }} />;
    } else if (player.specialty === SPECIALITIES.CEO) {
      return <CEOIcon style={{ width: '24', height: '24' }} />;
    }
  };

  const DislikeButton = ({ player }) => {
    return (
      <div>
        <IconButton
          style={{
            opacity: playerWithDislike === player.player_id ? '1' : '0.4',
            borderRadius: '4px',
          }}
          color={playerWithDislike === player.player_id ? 'red' : 'purple'}
          onClick={async () => {
            if (estimationTimer) {
              await appStore.estimatePlayer(playerWithDislike === player.player_id, player.player_id);
              setPlayerWithDislike(playerWithDislike !== player.player_id ? player.player_id : '');
            }
          }}
        >
          <DislikeIcon />
        </IconButton>
      </div>
    );
  };

  const estimationPlayers = formattedCompanyPlayers
    .filter((player) => LocalStorage.USER_UID !== player.player_id && player.player_id)
    .map((player, index) => {
      return (
        <div key={player.player_id}>
          {appStore.meIsCEO && index % 5 === 0 && (
            <div
              className={classNames({
                'estimation-wrapper__item-area': true,
                'estimation-wrapper__item-area--indent': index !== 0,
              })}
            >
              <span>Город {corpStore.areaNumByCaptainId(player.player_id)}</span>
            </div>
          )}
          <div className="estimation-wrapper__player">
            <div className="estimation-wrapper__player-title">
              <div className="estimation-wrapper__player-title--icon">
                <SpecialityIcon player={player} />
              </div>
              <div className="estimation-wrapper__player-title--text">
                {SPECIALITIES_RUSSIAN_NAME[player.specialty]}
              </div>
            </div>
            <DislikeButton player={player} />
          </div>
        </div>
      );
    });

  return (
    <Modal
      size={'big'}
      isOpen={appStore.estimationModalFlag}
      onClose={appStore.hideEstimationModal}
      shouldCloseOnOverlayClick={false}
      title={'Оцените членов своей команды'}
      exitIcon={true}
    >
      <div className="modal__body">
        <div className="estimation-wrapper">
          <div className="estimation-wrapper__desc">
            <p>Оценка ставится игроку, изначально выполнявшему роль.</p>
            <p>Отрицательная оценка влияет на рейтинг участника!</p>
            <p>Оценка участников игры необязательна!</p>
          </div>
          <div className="estimation-timer">
            <EstimationTimerIcon />
            <div className="estimation-timer__value notranslate">{estimationTimer ? formattedTimer : '00:00'}</div>
          </div>
          <div className="estimation-wrapper__content">{estimationPlayers}</div>
          {!appStore.estimationTimer && (
            <div className="estimation-timer-desc">Время для оценки игроков своей команды вышло</div>
          )}
        </div>
      </div>
      <div className="modal__body-actions">
        <TextButton color="blue" title="Сохранить" onClick={() => appStore.hideEstimationModal()} />
      </div>
    </Modal>
  );
}

export default observer(TeamEstimationModal);
