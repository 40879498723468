import React, { useState } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import './style.scss';
import corpStore from '../../../store/CorpStore';
import ReactSelect from '../../Select/Select';
import TextButton from '../../buttons/TextButton/TextButton';
import { Checkbox } from '../Checkbox';
import NumberInput from '../../NumberInput/NumberInput';
import { AI_TYPE, CAT_COLOR, CAT_GENDER, SICKNESS } from '../../../common/constants';
import { LocalStorage } from '../../../common/localStorage';
import classNames from '../../../common/classNames';

const TestFieldsTypes = {
  SPAWN_CATS: {
    title: 'Спаун котов',
    type: 'spawn_cats',
    validate: () => RootStore.appStore.isSoloMode || RootStore.appStore.isRealTimeMode,
  },
};

function SoloModeTester() {
  const { appStore } = RootStore;
  const [opened, setOpen] = useState(false);
  const [openedParameters, setOpenedParameters] = useState([]);

  if (!appStore.isSoloMode) {
    return;
  }

  function clearStoragePlayerInfo() {
    localStorage.removeItem('PLAYER_ROLE');
    localStorage.removeItem('IS_PLAYER_CEO');
  }

  const Players = appStore.players
    .filter((item) => item.ai_type === AI_TYPE.HUMAN && item.active)
    .map((player, index) => {
      return (
        <div
          className={classNames({
            'team-mode-tester__field-item': true,
            'team-mode-tester__field-item--background-me': player.uuid === appStore.me.uuid,
          })}
          key={index}
        >
          <a
            onClick={clearStoragePlayerInfo}
            style={{ pointerEvents: player.uuid === appStore.me.uuid ? 'none' : 'auto' }}
            href={`${location.origin}/play/${LocalStorage.GAME_UID}/${player.uuid}?server=${LocalStorage.JOIN_URL}`}
          >
            {player.role} {player.name}
          </a>
        </div>
      );
    });

  const SpawnCat = () => {
    const age = [0, 1, 2, 3];
    const [parametersCat, setParametersCat] = useState({
      color: CAT_COLOR.BLACK,
      gender: CAT_GENDER.MALE,
      sickness: SICKNESS.NONE,
      age: age[0],
      quantity: 1,
    });

    const changeParametersCat = (parameter, value) => {
      if (parameter === 'color') {
        setParametersCat({ ...parametersCat, color: value });
      }
      if (parameter === 'gender') {
        setParametersCat({ ...parametersCat, gender: value });
      }
      if (parameter === 'sickness') {
        setParametersCat({ ...parametersCat, sickness: value });
      }
      if (parameter === 'age') {
        setParametersCat({ ...parametersCat, age: value });
      }
      if (parameter === 'quantity') {
        setParametersCat({ ...parametersCat, quantity: value });
      }
    };

    const getOptions = (parameter) => {
      return Object.values(
        parameter === 'color'
          ? CAT_COLOR
          : parameter === 'gender'
          ? CAT_GENDER
          : parameter === 'sickness'
          ? SICKNESS
          : parameter === 'age'
          ? age
          : [],
      ).map((cat) => {
        return {
          value: cat,
          key: cat,
          label: cat,
        };
      });
    };

    return (
      <div className="team-mode-tester__spawn-cat">
        <span className="team-mode-tester__text">Спаун котов</span>
        <div className="team-mode-tester__spawn-cat-selects">
          {Object.keys(parametersCat)
            .filter((parameter) => parameter !== 'quantity')
            .map((parameter) => {
              return (
                <div key={parameter}>
                  <span>{parameter}</span>
                  <ReactSelect
                    className="team-mode-tester__select"
                    value={{ value: parametersCat[parameter], label: parametersCat[parameter] }}
                    options={getOptions(parameter)}
                    onChange={(event) => changeParametersCat(parameter, event.value)}
                  />
                </div>
              );
            })}
          <div>
            quantity
            <NumberInput
              icon={false}
              onChange={(value) => changeParametersCat('quantity', value)}
              value={parametersCat.quantity}
            />
          </div>
        </div>
        <TextButton title="Добавить котенка" color="green" onClick={() => corpStore.addCatForDev(parametersCat)} />
      </div>
    );
  };

  const Content = () => {
    const _content = [];
    if (openedParameters.includes(TestFieldsTypes.SPAWN_CATS.type)) {
      _content.push(
        <>
          <SpawnCat />
        </>,
      );
    }
    return _content;
  };

  return (
    !location.href.includes('hr.alabuga.ru') &&
    // !location.href.includes('pre-prod') &&
    (appStore.isSoloMode || appStore.isRealTimeMode) && (
      <div id="team-mode-tester">
        <button
          className="team-mode-tester__button"
          onClick={() => {
            setOpen(!opened);
          }}
        >
          {opened ? '>>>>' : '<<<<'}
        </button>
        {opened ? (
          <div className="team-mode-tester__field" style={appStore.isCorpMode ? { width: '35vw' } : {}}>
            {Object.values(TestFieldsTypes)
              .filter((testField) => testField.validate())
              .map((testField) => {
                return (
                  <div key={testField.type} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    <Checkbox
                      value={openedParameters.includes(testField.type)}
                      onClick={() => {
                        if (openedParameters.includes(testField.type)) {
                          setOpenedParameters([
                            ...openedParameters.filter((fieldType) => fieldType !== testField.type),
                          ]);
                        } else {
                          setOpenedParameters([...openedParameters, testField.type]);
                        }
                      }}
                    />
                    <span>{testField.title}</span>
                  </div>
                );
              })}
            <Content />
            <div>============</div>
            {Players}
          </div>
        ) : null}
      </div>
    )
  );
}

export default observer(SoloModeTester);
