import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

const DSN = 'https://5b44151524f34fb79874eb0cb721d355@sentry.cats.hr.alabuga.ru/10';

export function sentryInit() {
  const ThisIsProd = location.href.includes('hr.alabuga.ru');

  Sentry.init({
    dsn: DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: ThisIsProd ? 'production' : 'develop',
  });
}
