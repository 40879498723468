import RootStore from '../../../store';
import { AREA_POSITION, INTERACTION_AREA } from '../../../store/constants';
import corpStore from '../../../store/CorpStore';
import { AI_TYPE, DRAG_AREA, LTD_ACTIONS, PLAYER_ROLE, ROOT_PATHS, SPECIALITIES } from '../../../common/constants';

export const draggableOptionsDefault = {
  canDrag: (data, from) => {
    const { appStore } = RootStore;

    const isLtdDisallowedDragSpot =
      ((appStore.me.role === PLAYER_ROLE.NURSERY && from === DRAG_AREA.SHOP) || from === DRAG_AREA.NURSERY) &&
      appStore.currentPlayer.ai_type !== AI_TYPE.FIFTH_SHOP;

    const isLtdDisallowedDragRole = appStore.isTeamMode
      ? appStore.me.role === PLAYER_ROLE.SHOP
        ? appStore.mySpeciality === SPECIALITIES.SUPPLY && from === DRAG_AREA.NURSERY
        : (appStore.mySpeciality === SPECIALITIES.SUPPLY && from === DRAG_AREA.SHOP) ||
          (appStore.mySpeciality === SPECIALITIES.SALES && from === DRAG_AREA.NURSERY)
      : appStore.me.role === PLAYER_ROLE.SHOP && from === DRAG_AREA.NURSERY;

    if (
      appStore.with_ltd &&
      !appStore.isCorpMode &&
      isLtdDisallowedDragSpot &&
      (!appStore.isTeamMode || isLtdDisallowedDragRole)
    ) {
      appStore.openOnlyLtdAllowedModal();
    }

    const newLTDLogic =
      from === DRAG_AREA.STORAGE_MY_CATS ||
      from === DRAG_AREA.CITY ||
      (from === DRAG_AREA.STORAGE && appStore.me.actionLTD.actionDetail.action_type === LTD_ACTIONS.TO_STORAGE);
    const LTDActionType = appStore.me.actionLTD && appStore.me.actionLTD.actionDetail.action_type;
    const isStorage =
      RootStore.router.currentRoute.rootPath !== ROOT_PATHS.STORAGE || LTDActionType === LTD_ACTIONS.TO_STORAGE;
    if (appStore.isTeamMode) {
      if (appStore.me.role === PLAYER_ROLE.NURSERY) {
        if (from === DRAG_AREA.NURSERY && !appStore.hasSpecialities(SPECIALITIES.SALES)) {
          return false;
        }
        if (from === DRAG_AREA.SHOP && !appStore.hasSpecialities(SPECIALITIES.SUPPLY)) {
          return false;
        }
        if (from === DRAG_AREA.NURSERY && appStore.mySpeciality === SPECIALITIES.SALES) {
          return data.count > 0 || data.cat_id;
        }
        return (
          appStore.isCorpMode ||
          !appStore.with_ltd ||
          (appStore.isRealTeamMode &&
            (newLTDLogic || appStore.currentPlayer.ai_type === AI_TYPE.FIFTH_SHOP) &&
            isStorage)
        );
      } else {
        if (from === DRAG_AREA.NURSERY && !appStore.hasSpecialities(SPECIALITIES.SUPPLY)) {
          return false;
        }
        if (from === DRAG_AREA.SHOP && !appStore.hasSpecialities(SPECIALITIES.SALES)) {
          return false;
        }
        if (from === DRAG_AREA.SHOP && appStore.mySpeciality === SPECIALITIES.SALES) {
          return data.count > 0 || data.cat_id;
        }
        if (from === DRAG_AREA.SHOP && !data.count && !data.cat_id) {
          return false;
        }
        return appStore.isCorpMode || !appStore.with_ltd || (appStore.isRealTeamMode && (newLTDLogic || isStorage));
      }
    } else if (appStore.isSoloMode && appStore.with_ltd) {
      return (
        (newLTDLogic ||
          appStore.currentPlayer.ai_type === AI_TYPE.FIFTH_SHOP ||
          (appStore.me.role === PLAYER_ROLE.SHOP && !isLtdDisallowedDragSpot)) &&
        isStorage
      );
    }
    // Для корпоративной версии ↓
    // Транспортировка ↓
    if (from === DRAG_AREA.TRANSPORTATION_TOP) {
      return (
        (data.contents?.length || data.cat_id) &&
        corpStore.interactionAreaAvailability[AREA_POSITION.TOP] === INTERACTION_AREA.AVAILABLE
      );
    }
    if (from === DRAG_AREA.TRANSPORTATION_BOTTOM) {
      return (
        (data.contents?.length || data.cat_id) &&
        corpStore.interactionAreaAvailability[AREA_POSITION.BOTTOM] === INTERACTION_AREA.AVAILABLE
      );
    }
    if (from === DRAG_AREA.TRANSPORTATION_ZONE || from === DRAG_AREA.TRANSFER_ZONE) {
      return data.detail || data.contents?.length || data.cat_id;
    }
    // Трансфер ↓
    if (from === DRAG_AREA.IMPORT_EXPORT_ZONE) {
      return corpStore.interactionAreaAvailability[AREA_POSITION.TOP] === INTERACTION_AREA.AVAILABLE;
    }
    if (from === DRAG_AREA.COMPANY_ZONE) {
      return (
        corpStore.interactionAreaAvailability[AREA_POSITION.BOTTOM] === INTERACTION_AREA.AVAILABLE &&
        (data.detail || data.contents?.length || data.cat_id)
      );
    }

    if (from === DRAG_AREA.STORAGE) {
      const LTDActionType = appStore.me.actionLTD && appStore.me.actionLTD.actionDetail.action_type;
      // data.contents - есть у сгруппированных котиков склада, не группированные можно таскать, они без contents
      return LTDActionType === LTD_ACTIONS.TO_STORAGE && (!data.contents || data.factCount !== 0);
    } else if (from === DRAG_AREA.STORAGE_MY_CATS) {
      const groupedCat = appStore.openedGroupedCat;
      return (!data.contents && groupedCat.factCount < groupedCat.needCount) || data.count > 0;
    }

    if (from === DRAG_AREA.CITY) {
      if (appStore.isCorpMode && appStore.meIsCEO && RootStore.router.currentRoute.rootPath === ROOT_PATHS.STORAGE) {
        return false;
      }

      if (appStore.isTeamMode && !appStore.hasSpecialities([SPECIALITIES.SUPPLY])) {
        return false;
      }

      if (
        appStore.isTeamMode &&
        appStore.me.role === PLAYER_ROLE.SHOP &&
        [SPECIALITIES.MARKETEER, SPECIALITIES.DOCTOR, SPECIALITIES.SALES].includes(appStore.mySpeciality)
      ) {
        return false;
      }
    }

    return (
      from !== DRAG_AREA.SHOP_CATS &&
      (!appStore.currentPlayer.lot ||
        !appStore.outcomingLots.find((lot) => lot.lot_id === appStore.currentPlayer.lot.lot_id) ||
        from === DRAG_AREA.CITY) &&
      (data.contents?.length || data.cat_id || appStore.me.role !== from)
    );
  },
};
