import React from 'react';
import { Route } from 'mobx-router';
import Main from './pages/Main';
import CEO from './pages/CEO';
import rootStore from './store';
import { LocalStorage } from './common/localStorage';
import Inside from './pages/Inside';
import Nursery from './pages/Nursery';
import AccessDenied from './pages/AccessDenied';
import Shop from './pages/Shop';
import { PLATFORM_URLS } from './common/platformUrls';
import LongTermsDeals from './pages/LongTermsDeals';
import StorageNew from './pages/Storage';
import corpStore from './store/CorpStore';
import Transportation from './pages/Transportation';
import Transfer from './pages/Transfer';
import { PLAYER_ROLE, ROOT_PATHS, SPECIALITIES } from './common/constants';
import Training from './pages/Trainings/Training';
import trainingStore from './store/trainingStore';
import ltdTraining from './store/trainings/LTDTrainings';
import { OPERATION_TYPE, TRAINING_CHAPTER_TYPE, LTD_ARCHIVE_ACTION_TYPE } from './store/constants';

const checkUserUID = () => {
  if (!LocalStorage.USER_UID) {
    rootStore.router.goTo(routes.accessDenied, {});
    return false;
  }
  return true;
};

const generateTrainingChapters = (trainingType) => {
  if (trainingType === TRAINING_CHAPTER_TYPE.LTD) {
    ltdTraining.resetChapters();
    ltdTraining.generateChapters();
  }
};

const checkNursery = (route, params) => {
  if (!checkUserUID()) {
    return false;
  }
  if (!rootStore.appStore.me) {
    return;
  }
  if (rootStore.appStore.me.role === PLAYER_ROLE.NURSERY && params.uuid === rootStore.appStore.me.uuid) {
    rootStore.router.goTo(routes.nursery, {});
    return false;
  } else if (rootStore.appStore.me.role === PLAYER_ROLE.SHOP && params.uuid === rootStore.appStore.me.uuid) {
    rootStore.router.goTo(routes.shop, {});
    return false;
  } else {
    rootStore.appStore.setCurrentPlayer(params.uuid);
  }
};

const checkTrainingTypeIsExist = (trainingType) => {
  return Object.values(TRAINING_CHAPTER_TYPE).includes(trainingType);
};

const checkSpecialtyForCEO = () => {
  if (rootStore.appStore.meIsCEO) {
    rootStore.router.goTo(routes.ceo);
    return;
  }
  rootStore.appStore.addToAfterTeamSynchronize(() => {
    if (rootStore.appStore.meIsCEO) {
      rootStore.router.goTo(routes.ceo);
    } else {
      rootStore.router.goTo(routes.main, {});
    }
  });
};

const onPageReloadForCEO = () => {
  rootStore.appStore.addToAfterSynchronize(() => {
    if (rootStore.appStore.meIsCEO) {
      rootStore.router.goTo(routes.ceo);
    }
  });
};

const checkIsAutotestPipelineRunningQueryParams = (queryParams) => {
  LocalStorage.IS_AUTOTEST_PIPELINE_RUNNING = queryParams.is_autotest_pipeline_running;
  LocalStorage.IS_AUTOTEST_PIPELINE_RUNNING = queryParams.is_autotest_pipeline_running ?? false;
};

const checkMessengerIsEnabled = (queryParams) => {
  LocalStorage.MESSENGER_IS_ENABLED = queryParams?.messenger_is_enabled ?? true;
};

const routes = {
  initTraining: new Route({
    path: '/play/training/:training_type',
    component: <div />,
    onEnter: (route, params, s, queryParams) => {
      checkIsAutotestPipelineRunningQueryParams(queryParams);
      checkMessengerIsEnabled(queryParams);
      trainingStore.changeCurrentTrainingType(params.training_type);
      rootStore.appStore.closeSessionWithoutReload();
      LocalStorage.NEED_KEYCLOAK = true;
      location.href = `/training/${params.training_type}`;
    },
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  init: new Route({
    path: '/play/:gameUid/:userUid',
    component: <div />,
    onEnter: (route, params, s, queryParams) => {
      localStorage.removeItem('IS_PLAYER_CEO');
      localStorage.removeItem('PLAYER_ROLE');

      checkIsAutotestPipelineRunningQueryParams(queryParams);
      checkMessengerIsEnabled(queryParams);
      rootStore.appStore.closeSessionWithoutReload();
      LocalStorage.IS_FIRST_TIME_GAME_RUNNING = true;
      LocalStorage.JOIN_URL = queryParams.server;
      LocalStorage.GAME_UID = params.gameUid;
      checkSpecialtyForCEO();

      if (params.userUid.length < 7) {
        // такая глупая проверка, что это не uuid, а язык переданный с платформы
        // значит мы пришлю сюда с hr и нужно лезть за авторизацией
        LocalStorage.NEED_KEYCLOAK = true;
      } else {
        LocalStorage.USER_UID = params.userUid;
      }

      if (LocalStorage.USER_UID) {
        if (params.initRouteName) {
          rootStore.router.goTo(routes[params.routeName || params.initRouteName], {
            uuid: params.uuid,
          });
        } else {
          rootStore.router.goTo(routes.main, {});
        }
      } else {
        location.href = '/';
      }
    },
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  main: new Route({
    path: ROOT_PATHS.MAIN,
    component: <Main />,
    onEnter: (route, params, queryParams) => {
      rootStore.appStore.init();
      rootStore.appStore.clearAllCurrentPlayerLot();
      rootStore.appStore.changeBackgroundMusic();
      rootStore.appStore.currentLTD = null;
      rootStore.appStore.clearAllCurrentPlayerActionLTD();
      rootStore.appStore.currentLTDDefaultValues = null;
      checkSpecialtyForCEO();
    },
    onParamsChange: (route, params) => {
      console.log('params changed to', params);
    },
    beforeEnter: checkUserUID,
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  ceo: new Route({
    path: ROOT_PATHS.CEO,
    component: <CEO />,
    onEnter: (route, params, queryParams) => {
      rootStore.appStore.init();
      rootStore.appStore.changeBackgroundMusic();
      checkSpecialtyForCEO();
      corpStore.setCurrentAreaNum(null);
      rootStore.appStore.currentLTD = null;
      corpStore.setCurrentTypeOperation(null);
      corpStore.clearContentOperationField();

      if (localStorage.getItem('IS_PLAYER_CEO') !== null) {
        if (localStorage.getItem('IS_PLAYER_CEO') !== 'true') {
          rootStore.router.goTo(routes.main);
        }
      }
    },
    beforeEnter: checkUserUID,
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  transportation: new Route({
    path: ROOT_PATHS.TRANSPORTATION,
    component: <Transportation />,
    onEnter: (route, params, queryParams) => {
      rootStore.appStore.init();
      rootStore.appStore.changeBackgroundMusic();
    },
    beforeEnter: () => {
      checkUserUID();
      corpStore.setCurrentTypeOperation(OPERATION_TYPE.TRANSPORTATION);
    },
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  transfer: new Route({
    path: ROOT_PATHS.TRANSFER,
    component: <Transfer />,
    onEnter: (route, params, queryParams) => {
      rootStore.appStore.init();
      rootStore.appStore.changeBackgroundMusic();
    },
    beforeEnter: () => {
      checkUserUID();
      corpStore.setCurrentTypeOperation(OPERATION_TYPE.TRANSFER);
    },
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  city: new Route({
    path: `${ROOT_PATHS.CITY}/:areaNum`,
    component: <Main />,
    onEnter: (route, params, queryParams) => {
      rootStore.appStore.init();
      corpStore.setCurrentAreaNum(params.areaNum);
      rootStore.appStore.clearAllCurrentPlayerLot();
      rootStore.appStore.changeBackgroundMusic();
      rootStore.appStore.currentLTD = null;
      rootStore.appStore.clearAllCurrentPlayerActionLTD();
    },
    onParamsChange: (route, params) => {
      console.log('params changed to', params);
    },
    beforeEnter: (route, params) => {
      corpStore.setCurrentAreaNum(params.areaNum);
      checkUserUID();
    },
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  longTermsDealsNew: new Route({
    path: `${ROOT_PATHS.LONG_DEAL}/create/:type_ltd/:uuid`,
    component: <LongTermsDeals />,
    onEnter: (route, params, queryParams) => {
      rootStore.appStore.init();
      rootStore.appStore.changeBackgroundMusic();
      rootStore.appStore.setCurrentTaskId(params.taskId);
      rootStore.appStore.createCurrentLtd(params.uuid, params.type_ltd, rootStore.appStore.areaNum);
      if (rootStore.appStore.LTDArchive(params.uuid, params.type_ltd)) {
        rootStore.appStore.afterSynchronize.push(() =>
          rootStore.appStore.openLTDArchiveModal({
            partnerUuid: params.uuid,
            actionType: LTD_ARCHIVE_ACTION_TYPE.LOAD,
            type_ltd: params.type_ltd,
          }),
        );
      }
    },
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  longTermsDealsCorpNew: new Route({
    path: `${ROOT_PATHS.LONG_DEAL}/create/:type_ltd/:uuid/:area_num`,
    component: <LongTermsDeals />,
    onEnter: (route, params, queryParams) => {
      rootStore.appStore.init();
      rootStore.appStore.changeBackgroundMusic();
      if (rootStore.appStore.meIsCEO) {
        rootStore.appStore.createCurrentLtd(
          params.uuid,
          params.type_ltd,
          params.area_num ?? rootStore.appStore.areaNum,
        );
      } else {
        rootStore.appStore.addToAfterTeamSynchronize(() =>
          rootStore.appStore.createCurrentLtd(
            params.uuid,
            params.type_ltd,
            params.area_num ?? rootStore.appStore.areaNum,
          ),
        );
      }
    },
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  longTermsDealsEdit: new Route({
    path: `${ROOT_PATHS.LONG_DEAL}/edit/:deal_id`,
    component: <LongTermsDeals />,
    onEnter: (route, params, queryParams) => {
      rootStore.appStore.init();
      rootStore.appStore.changeBackgroundMusic();
      rootStore.appStore.setCurrentTaskId(params.task?.task_id);

      if ((!params.task?.content.deal_id && !params.deal_id) || params.task?.content.updated) {
        rootStore.appStore.setCurrentLTD(null, params.task.content, params.task);
      } else {
        rootStore.appStore.setCurrentLTD(params.deal_id ?? params.task.content.deal_id);
      }

      if (rootStore.appStore.meIsCEO) {
        corpStore.setCurrentAreaNum(null);
      }
    },
    onParamsChange: (route, params, queryParams) => {
      rootStore.appStore.setCurrentLTD(params.deal_id);
    },
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  longTermsDealsFallback: new Route({
    path: `${ROOT_PATHS.LONG_DEAL}/edit`,
    onEnter: () => rootStore.goTo(routes.main),
  }),
  storage: new Route({
    path: `${ROOT_PATHS.STORAGE}/:uuid`,
    component: <StorageNew />,
    onEnter: (route, params, queryParams) => {
      rootStore.appStore.init();
      rootStore.appStore.changeBackgroundMusic();
      rootStore.appStore.addToAfterTeamSynchronize(() => {
        if (rootStore.appStore.isCorpMode && rootStore.appStore.meIsCEO && !rootStore.appStore.me?.actionLTD) {
          rootStore.router.goTo(routes.ceo, { ceo_uuid: rootStore.appStore.ceo_uuid });
        } else if (
          rootStore.appStore.isRealTeamMode &&
          rootStore.appStore.hasSpecialities(SPECIALITIES.CAPTAIN) &&
          !rootStore.appStore.me?.actionLTD
        ) {
          rootStore.router.goTo(routes.main);
        }
      });
      rootStore.appStore.setCurrentPlayer(params.uuid);
    },
    onParamsChange: (route, params, queryParams) => {
      rootStore.appStore.setCurrentPlayer(params.uuid);
    },
    beforeEnter: checkNursery,
    onExit: () => {
      ltdTraining.resetChapters();
      rootStore.appStore.setCurrentDoctorReviewingLTD(null);
    },
  }),
  nursery: new Route({
    path: ROOT_PATHS.NURSERY,
    component: <Nursery />,
    onEnter: (route, params, queryParams) => {
      if (localStorage.getItem('PLAYER_ROLE') !== null) {
        if (localStorage.getItem('PLAYER_ROLE') !== PLAYER_ROLE.NURSERY && !rootStore.appStore.isSpectatorModeOn) {
          rootStore.router.goTo(routes.main);
        }
      }

      rootStore.appStore.init();
      rootStore.appStore.setCurrentGroupedHousesIndex(0);
      rootStore.appStore.changeBackgroundMusic();
      rootStore.appStore.setCurrentPlayer(null);
    },
    beforeEnter: () => {
      checkUserUID();
      onPageReloadForCEO();
    },
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  _training: new Route({
    path: ROOT_PATHS.TRAINING,
    onEnter: (route, params, queryParams) => {
      rootStore.goTo(routes.training, { training_type: TRAINING_CHAPTER_TYPE.BASIC });
    },
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  training: new Route({
    path: `${ROOT_PATHS.TRAINING}/:training_type`,
    component: <Training />,
    onEnter: (route, params, queryParams) => {
      if (!checkTrainingTypeIsExist(params.training_type)) {
        rootStore.goTo(routes.accessDenied);
        return;
      }
      generateTrainingChapters(params.training_type);
      trainingStore.changeCurrentTrainingType(params.training_type);
      rootStore.appStore.getTutorialProgress({ type: params.training_type });
      rootStore.appStore.setModalNotificationOn();
    },
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  shop: new Route({
    path: ROOT_PATHS.SHOP,
    component: <Shop />,
    onEnter: (route, params, queryParams) => {
      if (localStorage.getItem('PLAYER_ROLE') !== null) {
        if (localStorage.getItem('PLAYER_ROLE') !== PLAYER_ROLE.SHOP && !rootStore.appStore.isSpectatorModeOn) {
          rootStore.router.goTo(routes.main);
        }
      }

      rootStore.appStore.init();
      rootStore.appStore.changeBackgroundMusic();
      rootStore.appStore.setCurrentPlayer(null);
    },
    beforeEnter: () => {
      checkUserUID();
      onPageReloadForCEO();
    },
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  inside: new Route({
    path: `${ROOT_PATHS.INSIDE}/:uuid`,
    component: <Inside />,
    onEnter: (route, params, queryParams) => {
      rootStore.appStore.init();
      rootStore.appStore.setCurrentPlayer(params.uuid);
      rootStore.appStore.changeBackgroundMusic();
      rootStore.appStore.openNotificationAboutBlockingModal();
    },
    onParamsChange: (route, params, queryParams) => {
      rootStore.appStore.setCurrentPlayer(params.uuid);
      rootStore.appStore.openNotificationAboutBlockingModal();
    },
    beforeEnter: checkNursery,
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  accessDenied: new Route({
    path: ROOT_PATHS.ACCESS_DENIED,
    component: <div></div>,
    onEnter: (route, params, s, queryParams) => {
      rootStore.appStore.closeSessionWithoutReload();
      debugger;
      location.href = PLATFORM_URLS.CLIENT;
    },
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  start: new Route({
    path: ROOT_PATHS.START,
    component: <AccessDenied />,
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  _startTraining: new Route({
    path: ROOT_PATHS.START_TRAINING,
    onEnter: (route, params, queryParams) => {
      rootStore.goTo(routes.startTraining, { training_type: TRAINING_CHAPTER_TYPE.BASIC });
    },
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
  startTraining: new Route({
    path: `${ROOT_PATHS.START_TRAINING}/:training_type`,
    component: <Training />,
    onEnter: (route, params, s, queryParams) => {
      if (!checkTrainingTypeIsExist(params.training_type)) {
        rootStore.goTo(routes.accessDenied);
        return;
      }
      checkIsAutotestPipelineRunningQueryParams(queryParams);
      checkMessengerIsEnabled(queryParams);
      generateTrainingChapters(params.training_type);
      trainingStore.changeCurrentTrainingType(params.training_type);
      rootStore.appStore.getTutorialProgress({ fake: true });
      rootStore.appStore.setModalNotificationOn();
    },
    onExit: () => {
      ltdTraining.resetChapters();
    },
  }),
};

export default routes;
