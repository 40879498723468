import instanceOfEnum from '../../../../common/instanceOfEnum';
import corpStore from '../../../../store/CorpStore';
import isDictionary from '../../../../common/isDictionary';
import { TEAM_AND_CORP_PRIVILEGES_MIXIN } from './constants';
import * as teamMode from '../../../../store/teamMode';
import RootStore from '../../../../store';
import CorpStore from '../../../../store/CorpStore';
import { LIMITS_TYPE } from '../../../../store/constants';
import { SPECIALITIES } from '../../../../common/constants';
import _ from 'lodash';

export const DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY = {
  VISIBLE: 'visible',
  PRIVILEGES: 'privileges',
  CALLBACK: 'callback',
  ADDITIONAL_PRIVILEGES: 'additional_privileges',
  RELATED_LIMIT_TYPES: 'related_limit_types',
};
Object.entries(DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY).forEach(([key, value]) => {
  DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY[
    key
  ] = `delegating_switcher_checkbox_for_readonly_property__${value}`;
});
Object.freeze(DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY);

// Типизация передаваемых свойств
const DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_VALIDATES = {
  [DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.VISIBLE]: (visible) => {
    return visible === undefined || _.isFunction(visible) || _.isBoolean(visible);
  },
  [DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.RELATED_LIMIT_TYPES]: (relatedLimitTypes) => {
    return Object.entries(relatedLimitTypes).every(([key, value]) => {
      return instanceOfEnum(LIMITS_TYPE, key) && (_.isBoolean(value) || _.isFunction(value));
    });
  },
  [DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.PRIVILEGES]: (privileges) => {
    return (
      isDictionary(privileges) &&
      Object.entries(privileges).every(([speciality, privileges]) => {
        return (
          instanceOfEnum(SPECIALITIES, speciality) &&
          _.isArray(privileges) &&
          privileges.every((privilege) => instanceOfEnum(TEAM_AND_CORP_PRIVILEGES_MIXIN, privilege))
        );
      })
    );
  },
  [DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.CALLBACK]: (callback) => {
    return _.isFunction(callback);
  },
  [DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.ADDITIONAL_PRIVILEGES]: (nonCEOGivesPrivileges) => {
    return DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_VALIDATES[
      DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.PRIVILEGES
    ](nonCEOGivesPrivileges);
  },
};

class DelegatingSwitcherCheckboxForReadonly {
  constructor(properties) {
    Object.entries(properties).forEach(([property, value]) => {
      if (
        instanceOfEnum(DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY, property) &&
        DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_VALIDATES[property](value)
      ) {
        this[property] = value || null;
      } else {
        console.error(`Свойство ${property} заполнено с ошибкой у поля}`);
        debugger;
      }
    });
  }

  /**
   * Проверка на то, что игроки из города имеют все привилегии для включенного checkbox'а
   * @param areaNum - номер города, для которого проверяется наличие привилегий,
   * может быть как названием города, так и 'all_areas', когда проверка идет для всех городов
   * @returns {boolean}
   */
  #playersHasPrivilegesForCheckBox({ areaNum }) {
    const playersHasAllPrivileges = (privilegesBySpecialities, areaNum) => {
      return Object.entries(privilegesBySpecialities).every(([speciality, privileges]) => {
        const player = CorpStore.playerBySpecialityAndAreaNum(speciality, areaNum);
        return teamMode.playerHasPrivileges(player.privileges, privileges, true);
      });
    };

    const neededAreaNums = areaNum === 'all_areas' ? corpStore.allActiveAreaNums : [areaNum];

    return neededAreaNums.every((areaNum) => {
      return playersHasAllPrivileges(this[DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.PRIVILEGES], areaNum);
    });
  }

  /**
   * Сборка привилегий привилегий по специальностям по переданным объектам
   * @param privilegesBySpeciality - передается объект типа [<SPECIALITIES>]: [<TEAM_AND_CORP_PRIVILEGES_MIXIN>],
   * @returns {Object} со структурой { [<SPECIALITIES>]: [<TEAM_AND_CORP_PRIVILEGES_MIXIN>] }
   */
  #collectPrivilegesBySpeciality(privilegesBySpeciality) {
    const result = {};
    Object.entries(privilegesBySpeciality).forEach(([speciality, privileges]) => {
      if (result[speciality]) {
        result[speciality] = _.uniq(_.concat(result[speciality], privileges));
      } else {
        result[speciality] = privileges;
      }
    });
    return result;
  }

  /**
   * Генерация отправляемых привилегий для игрока
   * @param speciality - Элемент enum'а <SPECIALITIES>
   * @param privileges - Элемент enum'а <PRIVILEGES>
   * @param areaNum - Номер города, в котором находиться игрок
   * @returns {Array} [ [player_id, privilege] ]
   */
  #generatePrivilegesForPlayer({ speciality, privileges, areaNum }) {
    const playerId = CorpStore.playerBySpecialityAndAreaNum(speciality, areaNum).player_id;

    return privileges.map((privilege) => [playerId, privilege]);
  }

  /**
   * Получение значения checbox'а
   * @param areaNum - Номер города, в котором находиться игрок
   * @returns {boolean}
   */
  value({ areaNum }) {
    return this.#playersHasPrivilegesForCheckBox({ areaNum });
  }

  /**
   * Возвращает видимость checkbox'а
   * @returns {boolean}
   */
  get visible() {
    const visible = this[DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.VISIBLE];
    if (visible === undefined) {
      return true;
    } else if (_.isFunction(visible)) {
      return visible();
    } else {
      return visible;
    }
  }

  /**
   * Функция меняет значение checkbox'а, удаляя или добавляя привилегии для игрока.
   * Также выполняется передаваемый в checkbox callback
   * @returns {void}
   */
  changeValue({ areaNum }) {
    const previousValue = _.clone(this.value({ areaNum }));

    const relatedLimitTypes = this[DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.RELATED_LIMIT_TYPES];

    const addingPrivilegesBySpeciality = previousValue
      ? null
      : this.#collectPrivilegesBySpeciality(this[DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.PRIVILEGES]);
    const removingPrivilegesBySpeciality = previousValue
      ? this.#collectPrivilegesBySpeciality({
          ...this[DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.ADDITIONAL_PRIVILEGES],
          ...this[DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.PRIVILEGES],
        })
      : null;

    const neededAreaNums = areaNum === 'all_areas' ? CorpStore.allActiveAreaNums : [areaNum];
    const addingPrivileges = [];
    const removingPrivileges = [];

    neededAreaNums.forEach((areaNum) => {
      if (addingPrivilegesBySpeciality) {
        Object.entries(addingPrivilegesBySpeciality).forEach(([speciality, privileges]) => {
          addingPrivileges.push(...this.#generatePrivilegesForPlayer({ speciality, areaNum, privileges }));
        });
      }

      if (removingPrivilegesBySpeciality) {
        Object.entries(removingPrivilegesBySpeciality).forEach(([speciality, privileges]) => {
          removingPrivileges.push(...this.#generatePrivilegesForPlayer({ speciality, areaNum, privileges }));
        });
      }

      if (previousValue && relatedLimitTypes) {
        Object.entries(relatedLimitTypes).forEach(([limitType, isNeeded]) => {
          if (!isNeeded || (_.isFunction(isNeeded) && !isNeeded({ areaNum }))) {
            return;
          }
          corpStore.setLocalLimitCheckboxValue(areaNum, limitType, true);
        });
      }
    });

    const callback = this[DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.CALLBACK];
    if (callback) {
      callback({ areaNum });
    }

    RootStore.appStore.modifyPrivileges(addingPrivileges, removingPrivileges);
    CorpStore.sendCorpLimits(areaNum);
  }
}

export default DelegatingSwitcherCheckboxForReadonly;
