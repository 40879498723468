import Modal from './Modal/Modal';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import trainingStore from '../../store/trainingStore';
import CoinModalIcon from '../icons/modal/CoinModalIcon';
import TextButton from '../buttons/TextButton/TextButton';
import { TriangleIcon, SeasonResultIcon } from '../icons';
import classNames from '../../common/classNames';
import { AUDIO_LIST, CATS_TEMPLATE } from '../../common/constants';
import WatcherModalIcon from '../icons/modal/WatcherModalIcon';
import CatUrlImage from './CatUrlImage';
import financialHistoryObject from '../../common/financialHistoryObject';
import corpStore from '../../store/CorpStore';
import HeaderHideButton from './Header/HeaderHideButton';
import './SeasonChangeModal.scss';

const catsImagesUnderAverage = () => {
  return (
    <div className="body-balance-row-content-el body-balance-row-content-el-cat">
      <div className="body-balance-row-content-el-col">покупка котиков</div>
      {CATS_TEMPLATE.map((catItem) => (
        <div
          key={catItem.gender + catItem.color}
          className="body-balance-row-content-el-col body-balance-row-content-el-col--cat qq"
        >
          <CatUrlImage color={catItem.color} gender={catItem.gender} />
        </div>
      ))}
      <div
        style={{ marginLeft: '0', textAlign: 'center' }}
        className="body-balance-row-content-el-col body-balance-row-content-el-col--cat"
      >
        итого
      </div>
    </div>
  );
};

function getPrice(price) {
  if (RootStore.appStore.withHideInformation) {
    return '---';
  }
  return price;
}

const averagePriceForCats = (players) => {
  const { appStore } = RootStore;
  const lastSeasonAveragePrice = appStore.averagePrice[appStore.currentSeason - 1];

  if (appStore.isSpectatorModeOn) {
    {
      {
        return (
          <div>
            {catsImagesUnderAverage()}
            {players.map((player) => (
              <div key={player.playerUuid} className="body-balance-row-content-el">
                <div className="body-balance-row-content-el-col">
                  {!player.playerUuid ? 'город' : appStore.playerByUuid(player.playerUuid).name}
                </div>
                {CATS_TEMPLATE.map((catItem) => (
                  <div key={catItem.gender + catItem.color} className="body-balance-row-content-el-col">
                    {player.cats[catItem.gender + catItem.color]?.map((cat) => {
                      let priceDiff = 0;
                      if (player.playerUuid && lastSeasonAveragePrice) {
                        const tradeType = appStore.me.role === 'nursery' ? 'sell' : 'buy';
                        const average = lastSeasonAveragePrice[tradeType][catItem.color + '_' + catItem.gender];
                        if (!!average && average !== cat.price) {
                          priceDiff = Math.ceil((cat.price - average) * 10) / 10;
                        }
                      }
                      return (
                        <div
                          key={catItem.gender + catItem.color + 'data'}
                          className="body-balance-row-content-el-col-data"
                        >
                          <span className="body-balance-row-content-el-col-data-count notranslate">
                            {getPrice(cat.count)}×
                          </span>
                          <CoinModalIcon className="body-balance-row-content-el-col-data-icon" />
                          <span className="body-balance-row-content-el-col-data-price notranslate">{cat.price}</span>
                          {!!priceDiff && (
                            <div style={{ alignItems: 'center', display: 'flex' }}>
                              <WatcherModalIcon style={{ width: '20', height: '20' }} />
                              <span className="body-balance-row-content-el-col-data-average">
                                <span className={`average--color-${priceDiff < 0 ? 'green' : 'red'}`}>
                                  <span>&nbsp;({priceDiff > 0 && '+'}</span>
                                  <span className="notranslate">{priceDiff})</span>
                                </span>
                              </span>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))}
                <div style={{ marginLeft: '0', textAlign: 'center' }} className="body-balance-row-content-el-col">
                  <p style={{ justifyContent: 'center' }} className="body-balance-row-coin">
                    <span className="body-balance-coin notranslate">{getPrice(player.totalPrice)}</span>
                    <CoinModalIcon className="body-balance-coin-icon" />
                  </p>
                </div>
              </div>
            ))}
          </div>
        );
      }
    }
  } else {
    return null;
  }
};

const row = (title, price, hidding = false, isItAveragePrice = false) => {
  return (
    <div
      className={classNames({
        'body-balance-row-header': true,
        'body-balance-row-header--hiding': hidding,
        'body-balance-row-header--without-title': isItAveragePrice,
        'body-balance-row-header--training-hide': trainingStore.launched && price === 0,
      })}
    >
      {!isItAveragePrice && <p className="body-balance-row-title">{title}</p>}
      <p className="body-balance-row-coin">
        <span className="body-balance-coin body-balance-coin--not notranslate">{getPrice(price)}</span>
        <CoinModalIcon className="body-balance-coin-icon" />
      </p>
    </div>
  );
};

const LTDRow = (title, detail) => {
  const { appStore } = RootStore;

  return (
    <>
      <div
        className={classNames({
          'body-balance-row-content-el': true,
          'body-balance-row-content-el--not-cats': true,
        })}
      >
        <div className="body-balance-row-content-el-col">{title}</div>
        <div className="body-balance-row-content-el-col">
          <p className="body-balance-row-coin">
            <span className="body-balance-coin notranslate">{getPrice(detail.total_sum)}</span>
            <CoinModalIcon className="body-balance-coin-icon" />
          </p>
        </div>
      </div>
      {detail.contents &&
        detail.contents.map((content, index) => (
          <div className="body-balance-row-content" key={index + content.uuid}>
            <div
              style={{ paddingLeft: '30px' }}
              className={classNames({
                'body-balance-row-content-el': true,
                'body-balance-row-content-el--not-cats': true,
              })}
            >
              <div style={{ fontSize: '12px' }} className="body-balance-row-content-el-col">
                {appStore.players.find((player) => player.uuid === content.uuid).name}
              </div>
              <div className="body-balance-row-content-el-col">
                <p className="body-balance-row-coin">
                  <span style={{ fontSize: '20px' }} className="body-balance-coin notranslate">
                    {getPrice(content.sum)}
                  </span>
                  <CoinModalIcon className="body-balance-coin-icon" />
                </p>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

function SeasonChangeModal() {
  const { appStore } = RootStore;

  const [incomeVisible, setIncomeVisible] = useState(false);
  const [outcomeVisible, setOutcomeVisible] = useState(false);
  const [creditOutcomeVisible, setCreditOutcomeVisible] = useState(false);
  const [LTDIncomeVisible, setLTDIncomeVisible] = useState(false);
  const [LTDOutcomeVisible, setLTDOutcomeVisible] = useState(false);
  const [budgetAllocationVisible, setBudgetAllocationVisible] = useState(false);

  if (!appStore.seasonChangeModalShow) {
    return null;
  }

  let currentSeason = appStore.currentSeason;
  let currentBalanceSeason = appStore.currentBalanceSeason;
  let balance = appStore.balance;
  let household = appStore.gameCosts.household;
  let financeHistory = appStore.financeHistory;
  // corporateBalanceDetail - true, если роль - СЕО
  let corporateBalanceDetail = false;
  let bankGuarantee = appStore.bankGuarantee;

  if (appStore.meIsCEO) {
    corporateBalanceDetail = true;
    corpStore.corporationCaptains.forEach((captain) => {
      if (captain.active || (!captain.active && captain.game_over_season >= RootStore.appStore.currentBalanceSeason)) {
        household += appStore.gameCosts.household;
      }
      if (!captain.active && captain.game_over_season <= RootStore.appStore.currentBalanceSeason) {
        return;
      }
      balance += captain.balance;
    });
  }

  const financeHistoryObject = financialHistoryObject(
    corporateBalanceDetail,
    currentSeason,
    currentBalanceSeason,
    financeHistory,
    balance,
    household,
    bankGuarantee,
  );

  return (
    <Modal
      isOpen={appStore.seasonChangeModalShow}
      onClose={appStore.hideSeasonChangeModal}
      size="very-big"
      title={`итоги ${appStore.currentSeason} ${!appStore.isRealTimeMode ? 'сезона' : 'квартала'}`}
      customAudio={AUDIO_LIST.SEASON_CHANGE}
      titleIcon={<SeasonResultIcon />}
    >
      <div className="body-balance">
        <div className="body-balance-row">
          <div className="body-balance-row-header">
            <p className="body-balance-row-title">
              деньги на начало {!appStore.isRealTimeMode ? 'сезона' : 'квартала'}
            </p>
            <p className="body-balance-row-coin">
              <span className="body-balance-coin notranslate">{getPrice(financeHistoryObject.startBalance)}</span>
              <CoinModalIcon className="body-balance-coin-icon" />
            </p>
          </div>
        </div>

        {/* Распределение по бюджету */}
        {appStore.isCorpMode && (
          <div className="body-balance-row training-body-balance-row-income">
            <div className="body-balance-row-header">
              <p
                className={classNames({
                  'body-balance-row-title': true,
                  'body-balance-row-title--clickable':
                    !!financeHistoryObject.budgetAllocationFH.selection.length ||
                    !!financeHistoryObject.budgetAllocationFH.withdrawal.length,
                })}
                onClick={() => {
                  setBudgetAllocationVisible(!budgetAllocationVisible);
                }}
              >
                распределение бюджета
                {(!!financeHistoryObject.budgetAllocationFH.selection.length ||
                  !!financeHistoryObject.budgetAllocationFH.withdrawal.length) && (
                  <TriangleIcon top={budgetAllocationVisible} />
                )}
              </p>
              {!!financeHistoryObject.budgetAllocationFH.allAllocation && (
                <p className="body-balance-row-coin">
                  <span className="body-balance-coin body-balance-coin--not">
                    {getPrice(financeHistoryObject.budgetAllocationFH.allAllocation)}
                  </span>
                  <CoinModalIcon className="body-balance-coin-icon" />
                </p>
              )}
            </div>
            {budgetAllocationVisible && (
              <div className="body-balance-row-content">
                {financeHistoryObject.budgetAllocationFH.selection.map((fh, index) => {
                  return <div key={index}>{row(`выделение бюджета ${fh.target}`, fh.delta, true)}</div>;
                })}
                {financeHistoryObject.budgetAllocationFH.withdrawal.map((fh, index) => {
                  return <div key={index}>{row(`изъятие бюджета ${fh.target}`, fh.delta, true)}</div>;
                })}
              </div>
            )}
          </div>
        )}

        {/* Доход с продажи котиков */}
        <div className="body-balance-row">
          <div className="body-balance-row-header">
            <p
              className={classNames({
                'body-balance-row-title': true,
                'body-balance-row-title--clickable': corporateBalanceDetail && !!financeHistoryObject.income.allCats,
              })}
              onClick={() => {
                if (corporateBalanceDetail) {
                  setIncomeVisible(!incomeVisible);
                }
              }}
            >
              продажа котиков
              {corporateBalanceDetail && financeHistoryObject.income.allCats ? (
                <TriangleIcon top={outcomeVisible} />
              ) : null}
            </p>
            <p className="body-balance-row-coin">
              <span className="body-balance-coin body-balance-coin--not notranslate">
                {getPrice(financeHistoryObject.income.allCats)}
              </span>
              <CoinModalIcon className="body-balance-coin-icon" />
            </p>
          </div>
          {incomeVisible && !!financeHistoryObject.income.allCats && (
            <>
              {row('аванс за экспорт', financeHistoryObject.income.exportPrepaidCats.totalPrice, true)}
              {row('постоплата за экспорт', financeHistoryObject.income.exportPostpaidCats.totalPrice, true)}
              {row('продажа котиков в городах', financeHistoryObject.income.totalSellingCats, true)}
            </>
          )}
        </div>

        {!appStore.meIsCEO && (
          <div className="body-balance-row">
            <div className="body-balance-row-header">
              <p className="body-balance-row-title">Число сбежавших котиков</p>
              <p className="body-balance-row-coin">
                <span className="body-balance-coin notranslate">{appStore.purgedCats[appStore.currentSeason]}</span>
              </p>
            </div>
          </div>
        )}

        {/* Доход по долгосрочным сделкам */}
        {appStore.with_ltd && (
          <div className="body-balance-row training-body-balance-row-outcome">
            <div className="body-balance-row-header">
              <p
                className="body-balance-row-title body-balance-row-title--clickable"
                onClick={() => {
                  setLTDIncomeVisible(!LTDIncomeVisible);
                }}
              >
                Доход по долгосрочным сделкам
                {financeHistoryObject.incomeLTD.all !== 0 && <TriangleIcon top={LTDIncomeVisible} />}
              </p>
              <p className="body-balance-row-coin">
                <span className="body-balance-coin body-balance-coin--not notranslate">
                  {getPrice(financeHistoryObject.incomeLTD.all)}
                </span>
                <CoinModalIcon className="body-balance-coin-icon" />
              </p>
            </div>
            {LTDIncomeVisible && (
              <div className="body-balance-row-content">
                {financeHistoryObject.incomeLTD.prepaid &&
                  LTDRow('получение аванса', financeHistoryObject.incomeLTD.prepaid)}
                {financeHistoryObject.incomeLTD.payment_before_shipment &&
                  LTDRow('получение оплаты перед отгрузкой', financeHistoryObject.incomeLTD.payment_before_shipment)}
                {financeHistoryObject.incomeLTD.payment_after_shipment &&
                  LTDRow('получение оплаты после отгрузки', financeHistoryObject.incomeLTD.payment_after_shipment)}
                {financeHistoryObject.incomeLTD.termination &&
                  LTDRow('получение оплаты за расторжение', financeHistoryObject.incomeLTD.termination)}
                {financeHistoryObject.incomeLTD.fine_payment &&
                  LTDRow('получение оплаты штрафа', financeHistoryObject.incomeLTD.fine_payment)}
                {financeHistoryObject.incomeLTD.opponent_bank_guarantee &&
                  LTDRow('Зачисление банковской гарантии', financeHistoryObject.incomeLTD.opponent_bank_guarantee)}
              </div>
            )}
          </div>
        )}

        <div className="body-balance-row-header">
          <p
            className="body-balance-row-title body-balance-row-title--clickable"
            onClick={() => setOutcomeVisible(!outcomeVisible)}
          >
            <span>расходы</span>
            {financeHistoryObject.expenses ? <TriangleIcon top={outcomeVisible} /> : null}
          </p>
          <p className="body-balance-row-coin">
            <span className="body-balance-coin body-balance-coin--not notranslate">
              {getPrice(financeHistoryObject.expenses + financeHistoryObject.credit.paymentPercentCredit)}
            </span>
            <CoinModalIcon className="body-balance-coin-icon" />
          </p>
        </div>

        {outcomeVisible && (
          <>
            {corporateBalanceDetail && (
              <>
                {row('аванс за импорт', financeHistoryObject.outcome.importPrepaidCats.totalPrice, true)}
                {row('постоплата за импорт', financeHistoryObject.outcome.importPostpaidCats.totalPrice, true)}
              </>
            )}
            {!appStore.isSpectatorModeOn &&
              !corporateBalanceDetail &&
              row('покупка котиков', financeHistoryObject.outcome.totalBuyingCats, true)}
            {appStore.isSpectatorModeOn &&
              row('покупка котиков', averagePriceForCats(financeHistoryObject.outcome.totalBuyingCats), false, true)}
            {row('коммунальные услуги', financeHistoryObject.outcome.household, true)}
            {appStore.me && appStore.me.role === 'nursery' && (
              <>
                {row('покупка корма', financeHistoryObject.outcome.food, true)}
                {row('лечение котиков', financeHistoryObject.outcome.treatment, true)}
                {row('покупка домиков', financeHistoryObject.outcome.houses, true)}
                {row('страхование домов', financeHistoryObject.outcome.insurance, true)}
              </>
            )}
            {appStore.credits.credit_mechanics &&
              row('выплата процентов', financeHistoryObject.credit.paymentPercentCredit, true)}
            {corporateBalanceDetail && (
              <>
                {row('покупка котиков в городах', financeHistoryObject.outcome.totalBuyingCats, true)}
                {row('покупка машин', financeHistoryObject.outcome.buyCar, true)}
                {row('аренда машин', financeHistoryObject.outcome.rentCar, true)}
              </>
            )}
          </>
        )}

        {/* Расходы по долгосрочным сделкам */}
        {appStore.with_ltd && (
          <div className="body-balance-row training-body-balance-row-outcome">
            <div className="body-balance-row-header">
              <p
                className="body-balance-row-title body-balance-row-title--clickable"
                onClick={() => {
                  setLTDOutcomeVisible(!LTDOutcomeVisible);
                }}
              >
                <span>Расходы по долгосрочным сделкам</span>
                {financeHistoryObject.outcomeLTD.all !== 0 && <TriangleIcon top={LTDOutcomeVisible} />}
              </p>
              <p className="body-balance-row-coin">
                <span className="body-balance-coin body-balance-coin--not notranslate">
                  {getPrice(financeHistoryObject.outcomeLTD.all)}
                </span>
                <CoinModalIcon className="body-balance-coin-icon" />
              </p>
            </div>
            {LTDOutcomeVisible && (
              <div className="body-balance-row-content">
                {financeHistoryObject.outcomeLTD.prepaid &&
                  LTDRow('Оплата аванса', financeHistoryObject.outcomeLTD.prepaid)}
                {financeHistoryObject.outcomeLTD.payment_before_shipment &&
                  LTDRow('оплата перед отгрузкой', financeHistoryObject.outcomeLTD.payment_before_shipment)}
                {financeHistoryObject.outcomeLTD.payment_after_shipment &&
                  LTDRow('оплата после отгрузки', financeHistoryObject.outcomeLTD.payment_after_shipment)}
                {financeHistoryObject.outcomeLTD.termination &&
                  LTDRow('оплата расторжения', financeHistoryObject.outcomeLTD.termination)}
                {financeHistoryObject.outcomeLTD.fine_payment &&
                  LTDRow('оплата штрафа', financeHistoryObject.outcomeLTD.fine_payment)}
                {financeHistoryObject.outcomeLTD.bank_guarantee &&
                  LTDRow('Оплата банковской гарантии', { total_sum: financeHistoryObject.outcomeLTD.bank_guarantee })}
              </div>
            )}
          </div>
        )}

        {row('прибыль', financeHistoryObject.profit)}

        {appStore.credits.credit_mechanics && (
          <>
            <div className="body-balance-row-header">
              <p
                className="body-balance-row-title body-balance-row-title--clickable"
                onClick={() => setCreditOutcomeVisible(!creditOutcomeVisible)}
              >
                Кредит <TriangleIcon top={creditOutcomeVisible} />
              </p>
              <p className="body-balance-row-coin">
                <span className="body-balance-coin body-balance-coin--not notranslate">
                  {getPrice(financeHistoryObject.credit.takenCredit - financeHistoryObject.credit.paymentBodyCredit)}
                </span>
                <CoinModalIcon className="body-balance-coin-icon" />
              </p>
            </div>
            {creditOutcomeVisible && (
              <>
                {row('получение кредита', financeHistoryObject.credit.takenCredit, true)}
                {row('выплата тела кредита', financeHistoryObject.credit.paymentBodyCredit, true)}
              </>
            )}
          </>
        )}

        {row('Остаток', financeHistoryObject.remainder)}
      </div>
      <div className="body-result">
        <div className="body-result__item">
          <span className="body-balance-season-text body-result__text"> деньги на начало </span>
          <div className="body-result__cost">
            <span className="body-balance-coin body-balance-coin--not notranslate">
              {getPrice(financeHistoryObject.startBalance)}
            </span>
            <CoinModalIcon className="body-balance-coin-icon" />
          </div>
        </div>
        <div className="body-result__item">
          <span className="body-balance-season-text body-result__text"> прибыль </span>
          <div className="body-result__cost">
            <span className="body-balance-coin body-balance-coin--not notranslate">
              {getPrice(financeHistoryObject.profit)}
            </span>
            <CoinModalIcon className="body-balance-coin-icon" />
          </div>
        </div>
        <div className="body-result__item">
          <span className="body-balance-season-text body-result__text">остаток</span>
          <div className="body-result__cost">
            <span className="body-balance-coin body-balance-coin--not notranslate">
              {getPrice(financeHistoryObject.remainder)}
            </span>
            <CoinModalIcon className="body-balance-coin-icon" />
          </div>
        </div>
      </div>
      <div className="modal__body-actions season-change-modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.hideSeasonChangeModal} />
        {appStore.withHideInformationMechanics && <HeaderHideButton />}
      </div>
    </Modal>
  );
}

export default observer(SeasonChangeModal);
