import React from 'react';
import { observer } from 'mobx-react';
import routes from '../routes';
import RootStore from '../store';
import classNames from '../common/classNames';

import Header from '../ui/components/Header/Header';
import containerBG from '../assets/basic/trade/background/trade_bg.png';
import { HouseIcon } from '../ui/icons';
import MagnifierIcon from '../ui/icons/MagnifierIcon';

import './inside.scss';

import DragableItem from '../modules/deal/DragableItem';
import CityField from '../modules/deal/city/CityField';
import ShopField from '../modules/deal/shop/ShopField';
import LotField from '../modules/deal/lot/LotField';
import NurseryField from '../modules/deal/nursery/NurseryField';
import DragElement from '../modules/DragElement';
import AreaIcon from '../ui/icons/AreaIcon';
import Loader from '../ui/components/Loader/Loader';
import { DRAG_AREA, DRAG_BUTTON_TYPE, DRAGGABLE_ITEM_TYPE, SPECIALITIES } from '../common/constants';

function Storage() {
  const { appStore } = RootStore;
  if (!appStore.me) {
    return <Loader />;
  }

  if (!appStore.currentPlayer) {
      return <Loader />
  }

  const openedGroupedCat = appStore.openedGroupedCat;

  return (
    <>
      <Header
        background
        extra={
          appStore.meIsCEO ? (
            <span onClick={() => RootStore.goTo(routes.ceo)}>
              <AreaIcon fill="#C6A788" />
            </span>
          ) : (
            <span onClick={() => RootStore.goTo(routes.main)}>
              <HouseIcon style={{ fill: '#C6A788' }} />
            </span>
          )
        }
      />
      <div
        style={{
          background: `url(${containerBG}) center`,
        }}
        className="inside__container"
      >
        <div className="inside__wrap">
          <CityField />
          <ShopField />
          <LotField />
          <NurseryField />
        </div>
      </div>
      <DragElement />
      {openedGroupedCat &&
        (openedGroupedCat.contents?.some((c) => c.cat_id) || openedGroupedCat.storageContents?.some((c) => c.cat_id)) &&
        !appStore.meIsCEO &&
        !(appStore.isRealTeamMode && appStore.hasSpecialities(SPECIALITIES.CAPTAIN)) && (
          <DragableItem
            type={DRAGGABLE_ITEM_TYPE.BUTTON}
            from={DRAG_AREA.BUTTONS}
            data={{ buttonType: DRAG_BUTTON_TYPE.MAGNIFIER }}
            draggableOptions={{
              canDrag: (data, from, element) => {
                return true;
              },
            }}
            element={(isDragging, dragRef) => {
              return (
                // ToDo Сделать обработку на проверку здоровья
                <p
                  ref={dragRef}
                  onClick={() => {
                    if (appStore.magnifierCats.length) {
                      appStore.setCatUnderMagnifierIndex(0);
                    }
                  }}
                  className={classNames({
                    'magnifier-button': true,
                  })}
                >
                  <MagnifierIcon size={58} />
                </p>
              );
            }}
          />
        )}
    </>
  );
}

export default observer(Storage);
