import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../../../store';
import routes from '../../../../../routes';
import { CoinIcon } from '../../../../icons';
import TextButton from '../../../../buttons/TextButton/TextButton';
import StampSuccessIcon from '../../../../icons/StampSuccessIcon';
import StampTerminatedIcon from '../../../../icons/StampTerminatedIcon';
import classNames from '../../../../../common/classNames';
import { catCount } from '../../../../../common/texts/catCount';
import {
  LTD_ACTIONS,
  LTD_ACTIONS_NAME,
  LTD_ACTIONS_STATUS,
  LTD_FINES_TYPE,
  LTD_FINES_TYPE_NAME,
  ROOT_PATHS,
  SPECIALITIES,
  TIMER_ID,
} from '../../../../../common/constants';
import Timer from '../../../Timer';
import generateLTDActionRowId from '../../../../../common/LTD/generateLTDActionRowId';
import { PRIVILEGES } from '../../../../../store/teamMode';

const getContentTypeByAction = ({ contents, action }) => {
  let result = null;

  contents.find((content) => {
    const contentType = RootStore.appStore.typeOfLTDContent(content);
    if (
      content.actions.find((_action) => {
        return _.isEqual(action, _action);
      })
    ) {
      result = contentType;
      return contentType;
    }
  });

  return result;
};

const ActionTimer = observer(({ timer, actionType }) => {
  const { appStore } = RootStore;
  let value = appStore.timerValue.subtract(timer, 'seconds');
  let fail = false;
  if (value.$ms < 0) {
    fail = true;
    value = value.subtract(value).subtract(value);
  }
  return (
    <Timer
      customClassName="ltd-action__detailed-action-type-timer"
      id={TIMER_ID.LTD.ACTIONS[actionType]}
      style={{ color: fail ? 'red' : null }}
    >
      {value.format('m:ss')}
    </Timer>
  );
});

function LTDActions({ ltd_info, actions }) {
  const { appStore } = RootStore;
  let partnerUUID;
  const myUuid = appStore.meIsCEO ? appStore.ceo_uuid : appStore.me.uuid;

  if (myUuid === ltd_info.partner) {
    partnerUUID = ltd_info.initiator;
  } else {
    partnerUUID = ltd_info.partner;
  }

  let partner = appStore.ltdPartner(partnerUUID, ltd_info);

  const ActionType = ({ action, pretension }) => {
    const isCurrentSeason = appStore.currentSeason + 1 === action.season;
    return (
      <div className="ltd-action__detailed-action-type-container">
        {action.action_type === LTD_ACTIONS.PAYMENT_OF_FINE && action.fine_detail ? (
          <>
            <span className="ltd-action__detailed-text--bold">
              {action.executor === myUuid ? 'Вам' : 'Вы'} отправили штраф за <br />
              {LTD_FINES_TYPE_NAME[action.fine_detail.type]}!
            </span>
            <span>Сделка расторгнута</span>
          </>
        ) : pretension ? (
          <>
            <span className="ltd-action__detailed-text--bold">
              {action.executor === myUuid ? 'Вам' : 'Вы'} отправили претензию за <br />
              {pretension.liability_type.length === 1
                ? LTD_FINES_TYPE_NAME[pretension.liability_type]
                : 'недопоставку и качество котиков'}
            </span>
            <span className="ltd-action__detailed-text--extra">
              {isCurrentSeason && !action.isDisabled ? (
                <ActionTimer timer={action.timer} actionType={action.action_type} />
              ) : (
                'на ' + action.season + ' сезон'
              )}
            </span>
          </>
        ) : action.action_type === LTD_ACTIONS.TERMINATING ? (
          <span className="ltd-action__detailed-text--bold">
            {(myUuid === action.initiator_ltd && action.initiator_confirmed) ||
            (myUuid !== action.initiator_ltd && action.partner_confirmed)
              ? 'Вы отправили '
              : 'Вам отправили '}
            {action.value === action.old_value ? (
              <div>
                расторжение по <br /> соглашению сторон
              </div>
            ) : (
              <div>
                встречное расторжение <br /> по соглашению сторон
              </div>
            )}
          </span>
        ) : (
          <>
            <span>
              {action.action_type === LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT && action.payment_before_shipment.money === 0
                ? LTD_ACTIONS_NAME[LTD_ACTIONS.STORAGE_CONFIRMATION]
                : LTD_ACTIONS_NAME[action.action_type]}
            </span>
            {action.status === LTD_ACTIONS_STATUS.PENDING && (
              <span className="ltd-action__detailed-text--extra">
                {isCurrentSeason && !action.isDisabled && action.status === LTD_ACTIONS_STATUS.PENDING ? (
                  <ActionTimer timer={action.timer} actionType={action.action_type} />
                ) : (
                  'на ' + action.season + ' сезон'
                )}
              </span>
            )}
          </>
        )}
      </div>
    );
  };

  const InformationField = ({ action }) => {
    return (
      <>
        {action.action_type === LTD_ACTIONS.PREPAID ? (
          <div className="ltd-action__detailed-info-container">
            <span className="ltd-action__detailed-text--bold">{action.prepaid.money}</span>
            <CoinIcon style={{ marginLeft: '4px' }} />
          </div>
        ) : action.action_type === LTD_ACTIONS.TO_STORAGE ? (
          <div className="ltd-action__detailed-info-container ltd-action__detailed-info-container--column">
            <span style={{ color: '#B3977B' }}>{catCount(action.catsCount)}</span>
            <span className="ltd-action__detailed-text--extra ltd-action__detailed-text--bold">
              {action.status === LTD_ACTIONS_STATUS.PENDING ? 'Не отправлено' : 'Отправлено'}
            </span>
          </div>
        ) : action.action_type === LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT ? (
          <div
            className={classNames({
              'ltd-action__detailed-info-container': true,
              'ltd-action__detailed-info-container--column': action.payment_before_shipment.money === 0,
            })}
          >
            {action.payment_before_shipment.money !== 0 ? (
              <>
                <span className="ltd-action__detailed-text--bold">{action.payment_before_shipment.money}</span>
                <CoinIcon style={{ marginLeft: '4px' }} />
              </>
            ) : (
              <>
                <span style={{ color: '#B3977B' }}>{catCount(action.catsCount)}</span>
                <span className="ltd-action__detailed-text--extra ltd-action__detailed-text--bold">
                  {action.status === LTD_ACTIONS_STATUS.PENDING ? 'Не согласовано' : 'Согласовано'}
                </span>
              </>
            )}
          </div>
        ) : action.action_type === LTD_ACTIONS.SHIPPING ? (
          <div className="ltd-action__detailed-info-container ltd-action__detailed-info-container--column">
            <span style={{ color: '#B3977B' }}>{catCount(action.catsCount)}</span>
            <span className="ltd-action__detailed-text--extra ltd-action__detailed-text--bold">
              {action.status === LTD_ACTIONS_STATUS.PENDING ? 'Не отгружено' : 'Отгружено'}
            </span>
          </div>
        ) : action.action_type === LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT ? (
          <div className="ltd-action__detailed-info-container">
            <span className="ltd-action__detailed-text--bold">{action.payment_after_shipment.money}</span>
            <CoinIcon style={{ marginLeft: '4px' }} />
          </div>
        ) : action.action_type === LTD_ACTIONS.PAYMENT_OF_FINE && action.fine_detail ? (
          <div className="ltd-action__detailed-info-container ltd-action__detailed-info-container--column">
            <span style={{ fontSize: '10px' }}>Сумма штрафа</span>
            <div>
              <span className="ltd-action__detailed-text--bold">{action.fine_detail.detail.value}</span>
              <CoinIcon style={{ marginLeft: '4px' }} />
            </div>
          </div>
        ) : action.action_type === LTD_ACTIONS.TERMINATING ? (
          <div className="ltd-action__detailed-info-container ltd-action__detailed-info-container--column">
            <span style={{ fontSize: '10px' }}>Сумма расторжения</span>
            <div className="ltd-action__detailed-info-container">
              <span className="ltd-action__detailed-text--bold">{action.value}</span>
              {action.value !== action.old_value && (
                <span style={{ marginLeft: '4px' }} className="price__old-price">
                  {action.old_value}
                </span>
              )}
              <CoinIcon style={{ marginLeft: '4px' }} />
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const NecessaryAction = ({ action }) => {
    const myUuid = appStore.meIsCEO ? appStore.ceo_uuid : appStore.me.uuid;
    const myArea = myUuid === action.executor;

    const isPaymentAction = [
      LTD_ACTIONS.PREPAID,
      LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT,
      LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT,
      LTD_ACTIONS.PAYMENT_OF_FINE,
    ].includes(action.action_type);

    const cantUseShipping =
      (isPaymentAction && ![SPECIALITIES.MARKETEER, SPECIALITIES.CAPTAIN].includes(appStore.mySpeciality)) ||
      ([LTD_ACTIONS.TO_STORAGE, LTD_ACTIONS.SHIPPING].includes(action.action_type) &&
        ![SPECIALITIES.SALES, SPECIALITIES.CAPTAIN].includes(appStore.mySpeciality));

    const marketerCanPayFine =
      appStore.hasSpecialities(SPECIALITIES.MARKETEER) &&
      !appStore.myPrivileges.find((privilege) => privilege === PRIVILEGES.LTD_PAYMENT_ACTION_BY_MARKET) &&
      appStore.withDelegationLtd;

    return (
      <>
        {action.status === LTD_ACTIONS_STATUS.PENDING ? (
          myArea ? (
            <div>
              {action.action_type === LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT && (
                <TextButton
                  title="Осмотреть"
                  color="blue"
                  isDisabled={
                    action.isDisabled ||
                    ![SPECIALITIES.SUPPLY, SPECIALITIES.CAPTAIN, SPECIALITIES.CEO].includes(appStore.mySpeciality)
                  }
                  customClass={classNames({
                    'ltd-action__detailed-btn': true,
                    'ltd-action__detailed-btn--margin-bottom': action.payment_before_shipment.money !== 0,
                  })}
                  onClick={() => {
                    RootStore.goTo(routes.storage, { uuid: partner.uuid });
                    appStore.setCurrentActionLTD(ltd_info.deal_id, action);
                    appStore.hideMyTasksModal();
                  }}
                />
              )}
              {action.action_type === LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT &&
              action.payment_before_shipment.money === 0 ? null : (
                <TextButton
                  title={
                    isPaymentAction
                      ? 'Оплатить'
                      : action.action_type === LTD_ACTIONS.TO_STORAGE
                      ? 'На склад'
                      : action.action_type === LTD_ACTIONS.SHIPPING
                      ? 'Отгрузить'
                      : null
                  }
                  isDisabled={action.isDisabled || (!appStore.meIsCEO && cantUseShipping) || marketerCanPayFine}
                  color={
                    action.action_type === LTD_ACTIONS.TO_STORAGE
                      ? 'blue'
                      : action.action_type === LTD_ACTIONS.PAYMENT_OF_FINE
                      ? 'red'
                      : 'green'
                  }
                  customClass="ltd-action__detailed-btn"
                  onClick={() => {
                    if (action.action_type === LTD_ACTIONS.TO_STORAGE) {
                      RootStore.goTo(routes.storage, { uuid: partner.uuid });
                      appStore.setCurrentActionLTD(ltd_info.deal_id, action);
                      appStore.hideMyTasksModal();
                      return;
                    } else if (action.action_type === LTD_ACTIONS.PAYMENT_OF_FINE) {
                      appStore.LTDFinePaymentRequest({ deal_id: ltd_info.deal_id, action: action });
                    } else {
                      appStore.LTDPerfomAction({
                        deal_id: ltd_info.deal_id,
                        action_type: action.action_type,
                        action: action,
                      });
                    }
                    // ToDo избавиться после рефакторинга склада
                    if (RootStore.router.currentRoute.rootPath === ROOT_PATHS.STORAGE) {
                      RootStore.goTo(routes.main);
                    }
                  }}
                />
              )}
            </div>
          ) : action.action_type === LTD_ACTIONS.PAYMENT_OF_FINE ? (
            <StampTerminatedIcon style={{ margin: 'auto' }} />
          ) : action.action_type === LTD_ACTIONS.TERMINATING ? (
            <TextButton
              title={
                (myUuid === action.initiator_ltd && action.initiator_confirmed) ||
                (myUuid !== action.initiator_ltd && action.partner_confirmed)
                  ? 'Отменить'
                  : 'Подробнее'
              }
              color="blue"
              isDisabled={action.isDisabled}
              customClass="ltd-action__detailed-btn"
              onClick={() => {
                if (
                  (myUuid === action.initiator_ltd && action.initiator_confirmed) ||
                  (myUuid !== action.initiator_ltd && action.partner_confirmed)
                ) {
                  appStore.LTDTerminationCancel(ltd_info.deal_id);
                } else {
                  appStore.openLTDTerminationModal({
                    partner,
                    deal_id: ltd_info.deal_id,
                    currentLTD: ltd_info,
                    isConfirmed: true,
                  });
                }
              }}
            />
          ) : (
            <span></span>
          )
        ) : action.status === LTD_ACTIONS_STATUS.COMPLETED ? (
          <StampSuccessIcon style={{ margin: 'auto' }} />
        ) : (
          <span></span>
        )}
      </>
    );
  };

  const LiabilityBlock = observer(({ action }) => {
    const { appStore } = RootStore;
    const sentFine = action.sent_liabilities?.find((liability) => liability.is_fine);
    const marketerCanPayFine =
      appStore.hasSpecialities(SPECIALITIES.MARKETEER) &&
      !appStore.myPrivileges.find((privilege) => privilege === PRIVILEGES.LTD_PAYMENT_ACTION_BY_MARKET) &&
      appStore.withDelegationLtd;
    if (action.action_type === LTD_ACTIONS.TO_STORAGE) {
    }
    if (
      action.isDisabled ||
      action.isPending ||
      action.status === LTD_ACTIONS_STATUS.COMPLETED ||
      sentFine ||
      !action.liability?.length ||
      appStore.timerValue.subtract(action.timer, 'seconds').$ms > 0 ||
      marketerCanPayFine ||
      appStore.me.uuid === action.executor ||
      (appStore.mySpeciality !== SPECIALITIES.CAPTAIN &&
        appStore.mySpeciality !== SPECIALITIES.MARKETEER &&
        appStore.mySpeciality !== SPECIALITIES.CEO)
    ) {
      return null;
    }
    const sentPretensions =
      action.sent_liabilities?.filter((liability) => !liability.is_fine && !liability.is_closed) || [];
    const lowQualityPretension = sentPretensions.find((p) => p.liability_type.includes(LTD_FINES_TYPE.LOW_QUALITY));
    const liability =
      action.liability.find((item) => item === LTD_FINES_TYPE.OVERDUE_DELIVERY) ||
      action.liability.find((item) => item === LTD_FINES_TYPE.OVERDUE_PAYMENT);
    if (!liability && !lowQualityPretension) {
      return null;
    }

    return (
      <div className="ltd-action__fine-wrapper">
        <TextButton
          title="Претензия"
          color="yellow"
          isDisabled={!!action.sent_liabilities?.length || marketerCanPayFine}
          customClass="ltd-action__detailed-btn ltd-action__detailed-btn--pretension"
          onClick={() => {
            appStore.checkSendLTDLiability({
              is_fine: false,
              liability: [{ liability_type: liability }],
              deal_id: ltd_info.deal_id,
              action_type: action.action_type,
              action,
            });
          }}
        />
        <TextButton
          title="Расторжение"
          color="red"
          isDisabled={marketerCanPayFine}
          customClass="ltd-action__detailed-btn ltd-action__detailed-btn--fine"
          onClick={() => {
            if (lowQualityPretension) {
              // такое может быть только если мы уже отправили
              // претензию за качество, и теперь вышло время
              appStore.openLTDLiabilityModal(
                {
                  liability: [
                    {
                      liability_type: LTD_FINES_TYPE.LOW_QUALITY,
                      value: appStore.getFineAmountForToStorageAction(
                        ltd_info[`${LTD_FINES_TYPE.LOW_QUALITY}_liability`],
                        { ltd_info },
                        action,
                      ),
                    },
                    ...(!!liability
                      ? [
                          {
                            liability_type: LTD_FINES_TYPE.OVERDUE_DELIVERY,
                            value: appStore.getFineAmountForToStorageAction(
                              ltd_info[`${LTD_FINES_TYPE.OVERDUE_DELIVERY}_liability`],
                              { ltd_info },
                              action,
                            ),
                          },
                        ]
                      : []),
                  ],
                  deal_id: ltd_info.deal_id,
                  action_type: action.action_type,
                },
                true,
              );
            } else {
              appStore.checkSendLTDLiability({
                is_fine: true,
                liability: [{ liability_type: liability }],
                deal_id: ltd_info.deal_id,
                action_type: action.action_type,
                action,
              });
            }
          }}
        />
      </div>
    );
  });

  return (
    <>
      {actions.map((action) => {
        let pretension = null;
        if (action.sent_liabilities) {
          pretension = action.sent_liabilities.find((item) => !item.is_closed && !item.is_fine);
        }

        return (
          <div
            className={classNames({
              'ltd-action__detailed-list-actions--wrapper': true,
              'ltd-action__detailed--terminating':
                action.action_type === LTD_ACTIONS.PAYMENT_OF_FINE || action.action_type === LTD_ACTIONS.TERMINATING,
              'ltd-action__detailed--liabilities': pretension,
            })}
            id={generateLTDActionRowId({
              actionType: action.action_type,
              contentType: getContentTypeByAction({
                contents: ltd_info.contents,
                action,
              }),
            })}
            key={action.action_type + ' ' + action.executor}
          >
            <ActionType action={action} pretension={pretension} />
            <InformationField action={action} />
            <NecessaryAction action={action} />
            <LiabilityBlock action={action} />
          </div>
        );
      })}
    </>
  );
}

export default observer(LTDActions);
