import React, { useMemo } from 'react';
import Cat, { CAT_STATE } from '../../../ui/components/Cat/Cat';
import { DropContainer } from '../DropContainer';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import classNames from '../../../common/classNames';
import LotAreaInfo from '../LotAreaInfo';
import { LockIcon } from '../../../ui/icons';
import { draggableOptionsDefault } from '../../../ui/components/Cat/draggableOptionsDefault';
import corpStore from '../../../store/CorpStore';
import OpponentSlider from '../OpponentsSlider';
import { AI_TYPE, DRAG_AREA, LTD_ACTIONS, PLAYER_ROLE, ROOT_PATHS } from '../../../common/constants';
import { checkIfHaveLtdWithCurrentPlayer } from '../../../common/LTD/checkIfHaveLtdWithCurrentPlayer';

const CatMemo = React.memo(Cat, (prevProps, nextProps) => {
  return _.isEqual(
    _.pick(prevProps, ['cat', 'companyIsBlocked', 'readonly']),
    _.pick(nextProps, ['cat', 'companyIsBlocked', 'readonly']),
  );
});

function ShopField() {
  const { appStore } = RootStore;
  const storageCats = appStore.catsLTDToStorage;
  const myArea = appStore.me.role === PLAYER_ROLE.SHOP;
  let groupedCats;
  if (appStore.meIsCEO && !storageCats && myArea) {
    groupedCats = corpStore.corpCats[corpStore.currentAreaNum];
  } else if (storageCats) {
    groupedCats = storageCats;
  } else if (myArea) {
    groupedCats = appStore.playerCats[appStore.me.uuid];
  } else {
    groupedCats = appStore.playerCats[appStore.currentPlayer?.uuid];
  }
  const fromType = storageCats
    ? DRAG_AREA.STORAGE_MY_CATS
    : !myArea && appStore.me.role === appStore.currentPlayer?.role
    ? 'fake'
    : DRAG_AREA.SHOP;
  const Logo = appStore.me?.logo ?? null;
  //
  const catExistVision = true; // нужны конкретные специальности, которые не могут видеть наличие котиков у питомников ( в не зависимости от кол-ва, т.е. > 1)
  const shopFieldVision = true; // тоже, что и выше
  // TODO проверка на привилегии

  const { currentCompanyBlockingInfo, blockedByCurrentCompanyInfo } = appStore;

  const isStorageRoute = RootStore.router.currentRoute.rootPath === ROOT_PATHS.STORAGE;

  const isLtdPartner = checkIfHaveLtdWithCurrentPlayer();

  const content = useMemo(() => {
    return (
      <>
        {Logo && myArea && <Logo />}
        <LotAreaInfo player={myArea ? appStore.me : appStore.currentPlayer} color="#617C8B" />
        <div className="lot-area__content">
          {!myArea && <OpponentSlider />}
          <div className="lot-area__cats">
            {groupedCats?.map((item, index) => (
              <CatMemo
                from={fromType}
                dragAround={true}
                companyIsBlocked={
                  appStore.isTeamMode &&
                  appStore.me?.role === PLAYER_ROLE.NURSERY &&
                  (currentCompanyBlockingInfo || blockedByCurrentCompanyInfo) &&
                  !isStorageRoute &&
                  !isLtdPartner
                }
                onClick={() => {
                  if (myArea) {
                    if (
                      appStore.isCorpMode &&
                      appStore.meIsCEO &&
                      RootStore.router.currentRoute.rootPath === ROOT_PATHS.STORAGE &&
                      item.count > 0
                    ) {
                      appStore.setOpenedGroupedCat({ gender: item.gender, color: item.color, fromType });
                    } else if (!appStore.meIsCEO && (item.buy_price || item.sell_price || item.price)) {
                      appStore.setOpenedGroupedCat({ gender: item.gender, color: item.color, fromType });
                    }
                  }
                }}
                onDoubleClick={() => {
                  if ((currentCompanyBlockingInfo || blockedByCurrentCompanyInfo) && !isStorageRoute && !isLtdPartner) {
                    return;
                  }
                  if (
                    fromType === DRAG_AREA.SHOP &&
                    (item.count > 0 || appStore.me.role !== PLAYER_ROLE.SHOP) &&
                    !appStore.currentLotIsOutcoming &&
                    appStore.me.role !== appStore.currentPlayer?.role &&
                    (!appStore.with_ltd ||
                      appStore.isCorpMode ||
                      appStore.currentPlayer?.ai_type === AI_TYPE.FIFTH_SHOP)
                  ) {
                    appStore.dropItem({ from: fromType, data: item, to: DRAG_AREA.LOT });
                  } else if (
                    fromType === DRAG_AREA.STORAGE_MY_CATS &&
                    draggableOptionsDefault.canDrag(item, DRAG_AREA.STORAGE_MY_CATS) &&
                    item.needCount > item.factCount
                  ) {
                    appStore.dropItem({ data: item, to: DRAG_AREA.STORAGE });
                  }
                }}
                key={index}
                cat={item}
                state={!storageCats ? CAT_STATE.DEFAULT : CAT_STATE.STORAGE_MY_CATS}
                checkAvailable={
                  (catExistVision && appStore.currentPlayer?.role === PLAYER_ROLE.NURSERY && !myArea) ||
                  (appStore.me.actionLTD &&
                    appStore.me.actionLTD.actionDetail.action_type === LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT)
                }
                notShowCount={!myArea || (appStore.isCorpMode && appStore.currentDoctorReviewingLTD)}
                readonly={
                  (fromType === DRAG_AREA.STORAGE_MY_CATS && !item.price) ||
                  (appStore.me.actionLTD &&
                    appStore.me.actionLTD.actionDetail.action_type === LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT) ||
                  appStore.meIsCEO ||
                  ((currentCompanyBlockingInfo || blockedByCurrentCompanyInfo) && !isStorageRoute && !isLtdPartner)
                }
                fakeReadonly={
                  appStore.me?.role === PLAYER_ROLE.SHOP &&
                  RootStore.router.currentRoute.rootPath === ROOT_PATHS.STORAGE &&
                  item.factCount === 0 &&
                  item.needCount === 0
                }
              />
            ))}
          </div>
        </div>
      </>
    );
  }, [
    Logo,
    myArea,
    groupedCats,
    currentCompanyBlockingInfo,
    blockedByCurrentCompanyInfo,
    isStorageRoute,
    isLtdPartner,
  ]);

  if (!shopFieldVision) {
    return (
      <div className="lot-area lot-area--shop lot-area--shop-disallow">
        <LockIcon />
      </div>
    );
  }

  const areaTitle =
    appStore.meIsCEO && appStore.me.actionLTD?.ltd_info
      ? `город ${appStore.me.actionLTD.ltd_info.contents[0].area_num}`
      : '';
  if ((storageCats || appStore.currentDoctorReviewingLTD) && !myArea) {
    return (
      <div
        className={classNames({
          'lot-area': true,
          'lot-area--shop': true,
          'lot-area--opponent': true,
        })}
      >
        <LotAreaInfo player={appStore.currentPlayer} color="#617C8B" />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
          <p
            style={{
              color: '#5A7E93',
              fontSize: '20px',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              margin: '0 0 10px 0',
            }}
          >
            Долгосрочная сделка с зоомагазином {areaTitle}
          </p>
          <div className="lot-area__players-slider-item lot-area__players-slider-item--storage-player">
            {Logo && <Logo />}
            <p style={{ width: '100px', textAlign: 'center' }}>{appStore.currentPlayer?.name}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <DropContainer
      type={!storageCats ? fromType : DRAG_AREA.STORAGE_MY_CATS}
      element={({ canDrop, drop }) => (
        <div
          className={classNames({
            'lot-area': true,
            'lot-area--shop': true,
            'lot-area--opponent': !myArea,
            'lot-area--own-field': myArea,
          })}
          ref={drop}
          style={
            canDrop
              ? {
                  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #B8C9D3',
                  boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.1)',
                }
              : {}
          }
        >
          {content}
        </div>
      )}
    />
  );
}

export default observer(ShopField);
