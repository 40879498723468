import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import Modal from '../Modal/Modal';
import './LTDNotificationsModal.scss';
import { LTD_NOTIFICATION, LTD_NOTIFICATION_NAME, ROLE_NAME } from '../../../store/constants';
import TextButton from '../../buttons/TextButton/TextButton';
import CoinModalIcon from '../../icons/modal/CoinModalIcon';
import { CatFaceIcon, CoinIcon } from '../../icons';
import WalletTitleIcon from '../../icons/WalletTitleIcon';
import ExpectationIcon from '../../icons/modal/ExpectationIcon';

function LTDNotificationsModal() {
  const { appStore } = RootStore;
  const LTDNotifications = appStore.LTDNotificationsModalData;

  if (!LTDNotifications) {
    return null;
  }

  const { notification_type, colorBg, price, isWalletIcon, isCatFaceIcon, partner, description, isExpectationIcon } =
    LTDNotifications;

  const Icon = ({ fill }) => {
    return (
      <>
        {isExpectationIcon && <ExpectationIcon className="modal-ltd-notifications__body-expect" />}
        {isWalletIcon && <WalletTitleIcon fill={fill} className="modal-ltd-notifications__body-wallet" />}
        {isCatFaceIcon && <CatFaceIcon fill={fill} className="modal-ltd-notifications__body-wallet" />}
      </>
    );
  };
  const Price = () => {
    return (
      <div className="modal-ltd-notifications__body-info">
        <div className="task-constructor__content-price task-constructor__content-price--ltd-notification">
          <span>На сумму</span>
          <span className="task-constructor__content-price--coin">
            <CoinIcon
              style={{
                verticalAlign: 'middle',
                marginRight: '4px',
                width: '24px',
                height: '24px',
              }}
            />
            <span className="notranslate">{price}</span>
          </span>
        </div>
      </div>
    );
  };

  let desc;
  let area_num;

  if (appStore.meIsCEO) {
    if (appStore.LTDNotificationsModalData.deal_id) {
      area_num = appStore.ltdAreaNumById(appStore.LTDNotificationsModalData.deal_id);
    } else if (appStore.LTDNotificationsModalData.area_num) {
      area_num = appStore.LTDNotificationsModalData.area_num;
    }
  }
  if (area_num) {
    desc = `СЕО ${partner.name} город ${area_num}`;
  } else {
    desc = `В ${ROLE_NAME[partner.role]} "${partner.name}"`;
  }

  return (
    <Modal
      isOpen={!!appStore.LTDNotificationsModalData}
      onClose={!description && appStore.hideLTDNotificationsModal}
      desc={description || (notification_type !== LTD_NOTIFICATION.ERROR && desc)}
      title={LTD_NOTIFICATION_NAME[notification_type]}
      colorBg={colorBg}
      style={{ zIndex: 101 }}
      customClass="ltd-notifications"
    >
      <div className="modal-ltd-notifications__body--wrapper">
        <Icon fill="#FDF6EE" />
        {!!price && <Price />}
      </div>
      {!description && (
        <div className="modal__body-actions">
          <TextButton
            color="blue"
            title="понятно"
            onClick={() => {
              appStore.hideLTDNotificationsModal();
            }}
          />
        </div>
      )}
    </Modal>
  );
}

export default observer(LTDNotificationsModal);
