import RootStore from './index';
import { LTD_ACTIONS, LTD_ACTIONS_STATUS, LTD_STATUS } from '../common/constants';

export const GAME_TYPES = {
  SOLO: 'solo',
  TEAM: 'team',
  CORP: 'corp',
  REALTIME: 'realtime',
  SINGLE: 'single',
};

export const LOT_STATUS = {
  IN_PROGRESS: 'inprogress',
  CANCELLED: 'cancelled',
  CONFIRMED: 'confirmed',
  // TODO ещё должен быть статус "На переуточнении"
};

export const ROLE_NAME = {
  shop: 'зоомагазин',
  nursery: 'питомник',
};

export const CREDIT_TYPE = {
  CONSUMER: 'consumer',
  INVESTMENT: 'investment',
  SPECIAL: 'special',
};

export const CREDIT_TYPE_NAME = {
  [CREDIT_TYPE.CONSUMER]: 'потребительский кредит',
  [CREDIT_TYPE.INVESTMENT]: 'инвестиционный кредит',
  [CREDIT_TYPE.SPECIAL]: 'кредит со спец. условиями',
};

export const CREDIT_TYPE_NOTIFICATION = {
  APPROVED: 'approved',
  NOT_APPROVED: 'not_approved',
  NOT_CANT_CALC: 'not_cant_calc',
  REPAY_EARLY: 'repay_early',
  PAYOUT: 'payout',
  NOT_CANT_REPAY: 'not_cant_repay',
  OVER_AVAILABLE_CREDIT_SUM: 'over_available_credit_sum',
  CREDIT_NOT_FOUND: 'credit_not_found',
};

export const CREDIT_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  EARLY_REPAY: 'early_repay',
  UPDATE_BODY: 'update_body',
};

export const LTD_ARCHIVE_ACTION_TYPE = {
  SAVE: 'save',
  UPDATE: 'update',
  LOAD: 'load',
};

export const LTD_DOCTOR_REQUEST_STATUSES = {
  REQUESTED: 'requested',
  APPROVED: 'approved',
  DECLINED: 'declined',
  AWAITING_REQUEST: 'awaiting_request',
};

export const LTD_TERMINATION = {
  EMPTY: 'empty',
  PCT_OF_DEAL_AMOUNT: 'pct_of_deal_amount',
  FIXED: 'fixed',
};

export const LTD_TERMINATION_NAME = {
  [LTD_TERMINATION.FIXED]: 'фиксированная выплата',
  [LTD_TERMINATION.PCT_OF_DEAL_AMOUNT]: '% от суммы сделки',
};

export const LTD_NOTIFICATION = {
  CREATING_LTD: 'creating_ltd',
  COUNTER_LTD: 'counter_ltd',
  CONFIRMATION_LTD: 'confirmation_ltd',
  CANCEL_LTD: 'cancel_ltd',
  REJECTION_LTD: 'rejection_ltd',
  PREPAID: 'prepaid',
  PAYMENT_BEFORE_SHIPMENT: 'payment_before_shipment',
  SHIPPING: 'shipping',
  PAYMENT_AFTER_SHIPMENT: 'payment_after_shipment',
  TO_STORAGE: 'to_storage',
  STORAGE_CONFIRMATION: 'storage_confirmation',
  STORAGE_CONFIRMATION_AND_PAYMENT_BEFORE_SHIPMENT: 'storage_confirmation_and_payment_before_shipment',
  SEND_PRETENSION: 'send_pretension',
  SEND_FINE: 'send_fine',
  PAYMENT_OF_FINE: 'payment_of_fine',
  ME_PAYMENT_OF_FINE: 'me_payment_of_fine',
  ONE_WAY_TERMINATION: 'one_way_termination',
  OFFER_TWO_WAY_TERMINATION: 'offer_two_way_termination',
  ME_OFFER_TWO_WAY_TERMINATION: 'me_offer_two_way_termination',
  CONFIRMATION_TWO_WAY_TERMINATION: 'confirmation_two_way_termination',
  CANCEL_TERMINATION: 'cancel_termination',
  NOT_ENOUGH_MONEY: 'not_enough_money',
  ERROR: 'error',
  WAITING: 'waiting',
};

export const LTD_NOTIFICATION_NAME = {
  [LTD_NOTIFICATION.CREATING_LTD]: 'Предложение о долгосрочной сделке отправлено',
  [LTD_NOTIFICATION.COUNTER_LTD]: 'Предложение контрсделки отправлено',
  [LTD_NOTIFICATION.CONFIRMATION_LTD]: 'Долгосрочная сделка подтверждена',
  [LTD_NOTIFICATION.CANCEL_LTD]: 'Долгосрочная сделка отменена',
  [LTD_NOTIFICATION.REJECTION_LTD]: 'Вы отказались от долгосрочной сделки',
  [LTD_NOTIFICATION.PREPAID]: 'Оплата аванса',
  [LTD_NOTIFICATION.PAYMENT_BEFORE_SHIPMENT]: 'Оплата перед отгрузкой',
  [LTD_NOTIFICATION.SHIPPING]: 'Котики отгружены',
  [LTD_NOTIFICATION.PAYMENT_AFTER_SHIPMENT]: 'Оплата после отгрузки',
  [LTD_NOTIFICATION.TO_STORAGE]: 'Котики отправлены на осмотр контрагенту',
  [LTD_NOTIFICATION.STORAGE_CONFIRMATION]: 'Осмотр котиков подтвержден',
  [LTD_NOTIFICATION.STORAGE_CONFIRMATION_AND_PAYMENT_BEFORE_SHIPMENT]: 'Осмотр котиков и оплата произведена',
  [LTD_NOTIFICATION.SEND_PRETENSION]: 'Претензия отправлена',
  [LTD_NOTIFICATION.SEND_FINE]: 'Штраф отправлен',
  [LTD_NOTIFICATION.PAYMENT_OF_FINE]: 'Оплата штрафа',
  [LTD_NOTIFICATION.ME_PAYMENT_OF_FINE]: 'Вам отправлен штраф',
  [LTD_NOTIFICATION.ONE_WAY_TERMINATION]: 'Сделка расторгнута в одностороннем порядке',
  [LTD_NOTIFICATION.CONFIRMATION_TWO_WAY_TERMINATION]: 'Подтверждение условий о двустороннем расторжении',
  [LTD_NOTIFICATION.OFFER_TWO_WAY_TERMINATION]: 'Отправлено предложение о двустороннем расторжении',
  [LTD_NOTIFICATION.ME_OFFER_TWO_WAY_TERMINATION]: 'Вам отправлено предложение о двустороннем расторжении',
  [LTD_NOTIFICATION.CANCEL_TERMINATION]: 'Расторжения сделки отменено',
  [LTD_NOTIFICATION.NOT_ENOUGH_MONEY]: 'Недостаточно средств',
  [LTD_NOTIFICATION.ERROR]: 'Что-то пошло не так',
  [LTD_NOTIFICATION.WAITING]: 'Завершение действия',
};

export const REASONS_FOR_REFUSING_TO_SELL_TO_A_BOT = {
  BAD_RELATIONSHIP: 'bad_relationship',
  SICK_CATS: 'sick_cats',
  NOT_ENOUGH_MONEY: 'not_enough_money',
  NOT_ENOUGH_CATS: 'not_enough_cats',
  ALREADY_BOUGHT: 'already_bought',
  UNINTERESTING_CATS: 'uninteresting_cats',
  TOO_MANY_CATS: 'too_many_cats',
  DEVIATION_PRICE: 'deviation_price',
  DEVIATION_CITY_PRICE: 'deviation_city_price',
};

export const CAT_EVENT_TYPE = {
  PURCHASED: 'purchased',
  SOLD: 'sold',
  CURED: 'cured',
  FED: 'fed',
  MATING: 'mating',
  IS_AVAILABLE: 'is_available',
  MOVED: 'moved',
  EXAMINED: 'examined',
  PURGED: 'purged',
  GROWN_UP: 'grown_up',
  HUNGRY: 'hungry',
};

export const RECEIVE_MESSAGE_TYPE = {
  BALANCE: 'balance',
  SYNCHRONIZE: 'synchronize',
  TEAM_SYNCHRONIZE: 'team_synchronize',
  SPCT_CORP_PLAYERS: 'spectator_corp_players',
  CEO_SYNCHRONIZE: 'ceo_synchronize',
  PLAYER_TIMED_OUT: 'player_timed_out',
  PRIVILEGES_CHANGED: 'privileges_changed',
  SPECIALTIES_CHANGED: 'specialties_changed',
  TASK_UPDATE: 'task_update',
  WAIT_END_TURN: 'wait_end_turn',
  START_TURN: 'start_turn',
  COUNTER_ENDTURN_COMPANIES: 'counter_endturn_companies',
  PLAYER_CATS_EVENT: 'player_cats_event',
  TRADING_LOT_UPDATE: 'trading_lot_update',
  CHANGE_PRESET_PRICE_EVENT: 'change_preset_price_event',
  OTHER_PLAYER_CATS_EVENT: 'other_player_cats_event',
  CREDIT_UPDATE_EVENT: 'credit_update_event',
  LTD_UPDATE: 'longterm_deal_update',
  LTD_ERROR: 'longterm_deal_error',
  CITY_QUOTA_UPDATE: 'city_quota_update',
  IMPORT_EXPORT_QUOTA_UPDATE_EVENT: 'import_export_quota_update_event',
  HOUSE_BUYING_EVENT: 'house_buying_event',
  HOUSE_CATS_UPDATE_EVENT: 'house_cats_update_event',
  GAME_OVER: 'game_over',
  UPDATE_BLOCK_LIST: 'update_block_list',
  REMOVE_FROM_BLOCK_LIST: 'remove_from_block_list',
  RELATION_CHANGED: 'relation_changed',
  OTHER_PLAYER_GAME_OVER: 'other_player_game_over',
  SPCT_PROFIT_AVERAGE_PRICE_HISTORY: 'spct_profit_average_price_history',
  BUY_GOODS: 'buy_goods',
  GOODS_CHANGE: 'goods_change',
  ENDTURN_FLAG_CHANGED: 'endturn_flag_changed',
  SEND_TEAM_HISTORY: 'send_team_history',
  GAME_ON_PAUSE: 'game_on_pause',
  MAINTENANCE: 'maintenance',
  // ↓ Корпоративная версия
  CEO_START_TURN: 'ceo_start_turn',
  CORP_TEAM_START_TURN: 'corp_team_start_turn',
  CORP_TEAM_SYNCHRONIZE: 'corp_team_synchronize',
  RECEIVING_CAR_EVENT: 'receiving_car_event',
  MOVING_CATS_BETWEEN_CITIES_EVENT: 'moving_cats_between_cities_event',
  NOTIFY_CAPTAIN_MOVING_CATS_BETWEEN_CITIES: 'notify_captain_moving_cats_between_cities',
  IMPORT_EXPORT_LOT_UPDATE: 'import_export_lot_update',
  SET_LIMITS_EVENT: 'set_limits_event',
  CACHE_MESSAGE: 'cache_message',
  // ↓ Real-time ВЕРСИЯ
  REALTIME_SYNCHRONIZE: 'realtime_synchronize',
  REALTIME_START_TURN: 'realtime_start_turn',
  HOUSE_CATS_BORN_EVENT: 'house_cats_born_event',
};

export const MESSAGE_TYPE = {
  TRADING_REQUEST: 'trading_request',
  TRADING_CANCEL: 'trading_cancel',
  TRADING_CONFIRM: 'trading_confirm',
  ENDTURN_FLAG: 'endturn_flag',
  CHANGE_PRESET_PRICE: 'change_preset_price',
  TRADING_ACK: 'trading_ack',
  BUYING_HOUSE: 'buying_house',
  BUDGET_ALLOCATION: 'budget_allocation',
  CREDIT_UPDATE: 'credit_update',
  CREDIT_REPAY: 'credit_repay',
  LTD_REQUEST: 'longterm_deal_request',
  LTD_DOCTOR_REVIEW_REQUEST: 'ltd_doctor_review_request',
  LTD_DOCTOR_APPROVE_EVENT: 'ltd_doctor_approve_event',
  LTD_CONFIRM: 'longterm_deal_confirm',
  LTD_CANCEL: 'longterm_deal_cancel',
  STORAGE_PUT: 'storage_put',
  STORAGE_FETCH: 'storage_fetch',
  PERFORM_ACTION: 'perform_action',
  FINE_PAYMENT_REQUEST: 'fine_payment_request',
  LTD_CAT_EXAMINATION: 'ltd_cat_examination',
  LIABILITY_REQUEST: 'liability_request',
  LTD_ONE_WAY_TERMINATION_REQUEST: 'ltd_one_way_termination_request',
  LTD_TWO_WAY_TERMINATION_REQUEST: 'ltd_two_way_termination_request',
  LTD_TWO_WAY_TERMINATION_CANCEL: 'ltd_two_way_termination_cancel',
  MOVE_CAT: 'move_cat',
  FEED_CAT: 'feed_cat',
  CATS_MATING: 'cats_mating',
  HOUSE_INSURANCE: 'house_insurance',
  CAT_TREATMENT: 'cat_treatment',
  CITY_QUOTA_UPDATE: 'city_quota_update',
  SPCT_SWITCH_PLAYER: 'spct_switch_player',
  SPCT_PAUSE_GAME: 'spct_pause_game',
  GET_TEAM_HISTOTY: 'get_team_history',
  // ↓ Командная игра
  ACTIONS_ON_TASK: 'actions_on_task',
  MODIFY_PRIVILEGES: 'modify_privileges',
  MODIFY_SPECIALTIES: 'modify_specialties',
  EXAMINE_CAT: 'examine_cat',
  BLOCK_COMPANY: 'block_company',
  UNBLOCK_COMPANY: 'unblock_company',
  // ↓ Корпоративная версия
  MOVING_CATS_BETWEEN_CITIES: 'moving_cats_between_cities',
  RECEIVING_CAR_REQUEST: 'receiving_car_request',
  IMPORT_REQUEST: 'import_request',
  IMPORT_PAYMENT_REQUEST: 'import_payment_request',
  EXPORT_REQUEST: 'export_request',
  SET_CORP_LIMIT: 'set_corp_limit',
  // ↓ Командная игра для тестирования
  ADD_CAT_FOR_DEVELOP: 'add_cat_for_develop',
};

export const SEASON_TYPE = {
  SUMMER: 'summer',
  FALL: 'fall',
  WINTER: 'winter',
  SPRING: 'spring',
};

export const CAT_RUNAWAY_TYPE = {
  HUNGRY: 'hungry',
  SICK: 'sick',
  WITHOUT_HOUSE: 'without_house',
};

export const ALLOCATION_NOTIFICATION_TYPES = {
  YOU_ALLOCATED_BUDGET: 'you_allocated_budget',
  CEO_ALLOCATED_YOU_BUDGET: 'ceo_allocated_you_budget',
  YOU_WITHDRAWN_BUDGET: 'you_withdrawn_budget',
  CEO_WITHDREW_YOUR_BUDGET: 'ceo_withdrew_your_budget',
  YOU_DONT_HAVE_ENOUGH_MONEY: 'you_dont_have_enough_money',
  CAPTAIN_DONT_HAVE_ENOUGH_MONEY: 'captain_dont_have_enough_money',
};

export const ALLOCATION_TYPES = {
  ALLOCATION: 'allocation',
  WITHDRAWAL: 'withdrawal',
};

export const TASK_TYPES = {
  CREATE_CHANGE_TRADE_REQUEST: 'create_change_trade_request',
  CANCEL_OWN_TRADE_REQUEST: 'cancel_own_trade_request',
  BUY_FOOD: 'buy_food',
  BUY_DRUGS: 'buy_drugs',
  BUY_HOUSE: 'buy_house',
  GET_CREDIT: 'get_credit',
  CREDIT_REPAY: 'credit_repay',
  MATING: 'mating',
  CREATE_CHANGE_LTD_REQUEST: 'create_change_ltd_request',
  LTD_PAYMENT_ACTION_REQUEST: 'ltd_payment_action_request',
  // ToDo Ждем 2ю итерацию в release(3.1.0) необходимо переименовать FAKE_IMPORT_TASK в IMPORT_TASK в taskId feel BCC-466
  FAKE_IMPORT_TASK: 'fake_import_task',
  FAKE_EXPORT_TASK: 'fake_export_task',
  BUY_HOUSE_INSURANCE: 'buy_house_insurance',
  CHANGE_SHOWCASE_PRICES: 'change_showcase_prices',
  CITY_TRADE: 'city_trade',
  TRADING_LOT_ACKNOWLEDGE: 'trading_lot_acknowledge',
  FAKE_DOCTOR_REVIEW_REQUEST: 'doctor_review_request',
  FAKE_LTD_ACTION_TASK: 'fake_ltd_action_task',
  FAKE_BUDGET_ALLOCATION_TASK: 'fake_budget_allocation_task',
  FAKE_TRANSPORTATION_TASK: 'fake_transportation_task',
  _ANY_TASK: '_any_task',
  _NONE: '_none',
};

export const LTD_DELEGATED_ACTION_TYPES = {
  APPROVE_LTD_TASK_BY_MARKET: 'approve_ltd_task_by_market',
  APPROVE_LTD_TASK_BY_CAPTAIN: 'approve_ltd_task_by_captain',
  APPROVE_LTD_TASK_BY_CEO: 'approve_ltd_task_by_ceo',
  APPROVE_LTD_TASK: 'approve_ltd_task',
  PUSH_LTD_TO_CAPTAIN: 'push_ltd_to_captain',
  CREATE_LTD_TASK: 'create_ltd_task',
};

export const CORP_DELEGATING_TYPES = {
  CATS_SELL: 'cats_sell',
  CATS_BUY: 'cats_buy',
  CATS_BUY_FROM_CITY: TASK_TYPES.CITY_TRADE,
  CAT_SELL_TO_CITY: TASK_TYPES.CITY_TRADE,
  BUY_FOOD: TASK_TYPES.BUY_FOOD,
  BUY_DRUGS: TASK_TYPES.BUY_DRUGS,
  BUY_HOUSE: TASK_TYPES.BUY_HOUSE,
  CHANGE_SHOWCASE_PRICES: TASK_TYPES.CHANGE_SHOWCASE_PRICES,
  MATING: TASK_TYPES.MATING,
  GET_CREDIT: TASK_TYPES.GET_CREDIT,
  CREDIT_REPAY: TASK_TYPES.CREDIT_REPAY,
  DELEGATE_LTD_REQUEST: TASK_TYPES.CREATE_CHANGE_LTD_REQUEST,
};

export const CORP_DELEGATING_PRIVILEGES = {
  CATS_BUY_FROM_CITY: 'delegate_cats_buy_from_city',
  CATS_SELL_TO_CITY: 'delegate_cats_sell_to_city',
  [TASK_TYPES.BUY_HOUSE]: 'delegate_house_purchasing',
  [TASK_TYPES.BUY_FOOD]: 'delegate_food_purchasing',
  [TASK_TYPES.BUY_DRUGS]: 'delegate_drugs_purchasing',
  [TASK_TYPES.GET_CREDIT]: 'delegate_get_credit',
  [TASK_TYPES.CREDIT_REPAY]: 'delegate_credit_repay',
  [TASK_TYPES.MATING]: 'delegate_mating',
  [TASK_TYPES.CHANGE_SHOWCASE_PRICES]: 'delegate_showcase_prices',
  [TASK_TYPES.CREATE_CHANGE_LTD_REQUEST]: 'delegate_ltd_request',
};

export const LIMITS_TYPE = {
  GET_CREDIT_BY_TEAM: 'get_credit_by_team',
  GET_CREDIT: 'get_credit',
  SPENDING_ON_FOOD: 'buy_food',
  SPENDING_ON_MEDICINES: 'buy_drugs',
  SPENDING_ON_HOUSE: 'buy_house',
  SPENDING_ON_CAT_BUY: 'buying_cats_total',
  DEVIATION_FROM_SHOWCASE_PRICES_BUY: 'buying_cats_price',
  DEVIATION_FROM_SHOWCASE_PRICES_SELL: 'selling_cats_price',
};

export const OPERATION_TYPE = {
  TRANSPORTATION: 'transportation',
  TRANSFER: 'transfer',
};

export const AREA_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

export const INTERACTION_AREA = {
  AVAILABLE: 'available',
  BLOCKED: 'blocked',
};

export const TRANSFER_TYPE = {
  IMPORT: 'import',
  EXPORT: 'export',
};

export const TRANSFER_STATUS = {
  AWAITING_DELIVERY: 'awaiting_delivery',
  AWAITING_PAYMENT: 'awaiting_payment',
  PAID: 'paid',
};

export const TRANSPORTATION_STATUS = {
  TRANSPORTATION: 'transportation',
  TRANSPORTATED: 'transportated',
};

export const TRANSFER_TYPE_NAME = {
  [TRANSFER_TYPE.IMPORT]: 'Импорт',
  [TRANSFER_TYPE.EXPORT]: 'Экспорт',
};

export const CAR_STATE = {
  DEFAULT: 'default',
  INTERACTION: 'interaction',
};

export const CAR_TYPE = {
  SMALL: 'small',
  BIG: 'big',
};

export const CAR_SIZE = {
  [CAR_TYPE.SMALL]: 6,
  [CAR_TYPE.BIG]: 12,
};

export const CAR_ACTION_TYPE = {
  BUY: 'buy',
  RENT: 'rent',
};

export const CAR_ACTION_TYPE_NAME = {
  [CAR_ACTION_TYPE.BUY]: 'купить',
  [CAR_ACTION_TYPE.RENT]: 'арендовать',
};

export const DELEGATING_STATES = {
  CEO: 'ceo',
  CAPTAIN: 'captain',
  TEAM: 'team',
};

export const DELEGATING_STATE_TITLES = {
  [DELEGATING_STATES.CEO]: 'СЕО',
  [DELEGATING_STATES.CAPTAIN]: 'Капитан',
  [DELEGATING_STATES.TEAM]: 'Команда',
};

export const LIMITS_TITLE = {
  [LIMITS_TYPE.GET_CREDIT]: 'Взятие кредита',
  [LIMITS_TYPE.SPENDING_ON_FOOD]: 'Траты на корм',
  [LIMITS_TYPE.SPENDING_ON_MEDICINES]: 'Траты на лекарства',
  [LIMITS_TYPE.SPENDING_ON_HOUSE]: 'Траты на домики',
  [LIMITS_TYPE.SPENDING_ON_CAT_BUY]: 'Траты на покупку котиков',
  [LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_BUY]: 'Отклонение от витринных цен при покупке',
  [LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_SELL]: 'Отклонение от витринных цен при продаже',
};

// ToDo: переписать на ENUM во всем коде {https://gamesalabuga.atlassian.net/browse/BCC-437}
export const TEAM_TASK_STATUS = {
  CONFIRMED: 'confirmed',
  CANCELLED: 'cancelled',
  IN_PROGRESS: 'inprogress',
};

export const TEAM_TASKS_CATEGORY = {
  FOR_APPROVAL: 'for_approval',
  IN_WAITING: 'in_waiting',
  REPORT: 'report',
  LTD: 'ltd',
};

export const TEAM_TASKS_CATEGORY_NAME = {
  [TEAM_TASKS_CATEGORY.FOR_APPROVAL]: 'На согласование',
  [TEAM_TASKS_CATEGORY.IN_WAITING]: 'В ожидании',
  [TEAM_TASKS_CATEGORY.REPORT]: 'Отчеты',
  [TEAM_TASKS_CATEGORY.LTD]: 'Долгосрочные сделки',
};

export const TRAINING_TASK_STATUS = {
  COMPLETED: 'completed',
  WRONG: 'wrong',
  IN_PROGRESS: 'in_progress',
};

export const TRAINING_CHAPTER_TYPE = {
  BASIC: 'basic',
  LTD: 'ltd',
};

export const MAIL_TYPE = {
  INCOMING: 'incoming',
  OUTCOMING: 'outcoming',
  REVISION: 'revision',
  PENDING_REVISION: 'pendingRevision',
  COUNTER: 'counter',
  CONFIRMED: 'confirmed',
  CANCELLED: 'cancelled',
};

export const FAKE_LTD_TEAM_TASK_STATUS = {
  SENT: 'fake_ltd_task_sent',
  RECEIVED: 'fake_ltd_task_received',
  COUNTER_RECEIVED: 'fake_ltd_task_counter_received',
  COUNTER_SENT: 'fake_ltd_task_counter_sent',
  AGREED: 'fake_ltd_task_agreed',
  PRETENSION: 'fake_ltd_task_pretension',
  FINE: 'fake_ltd_task_fine',
  TWO_WAY_TERMINATION: 'fake_ltd_two_way_termination',
  EXAMINED_BY_DOCTOR: 'fake_ltd_task_examined_by_doctor',
  ACTION_COMPLETED: 'fake_ltd_task_action_completed',
  CANCELLED: 'fake_ltd_task_cancelled',
  TERMINATED: 'fake_ltd_task_terminated',
};

export const FAKE_LTD_TEAM_TASK_STATUS_NAME = {
  [FAKE_LTD_TEAM_TASK_STATUS.SENT]: 'отправлено на согласование',
  [FAKE_LTD_TEAM_TASK_STATUS.RECEIVED]: 'получена',
  [FAKE_LTD_TEAM_TASK_STATUS.COUNTER_RECEIVED]: 'получена',
  [FAKE_LTD_TEAM_TASK_STATUS.COUNTER_SENT]: 'отправлено на согласование',
  [FAKE_LTD_TEAM_TASK_STATUS.AGREED]: 'согласована',
  [FAKE_LTD_TEAM_TASK_STATUS.PRETENSION]: 'не выполнено',
  [FAKE_LTD_TEAM_TASK_STATUS.FINE]: 'выполнено',
  [FAKE_LTD_TEAM_TASK_STATUS.TWO_WAY_TERMINATION]: 'выполнено',
  [FAKE_LTD_TEAM_TASK_STATUS.EXAMINED_BY_DOCTOR]: 'осмотрено',
  [FAKE_LTD_TEAM_TASK_STATUS.ACTION_COMPLETED]: 'выполнено',
  [FAKE_LTD_TEAM_TASK_STATUS.CANCELLED]: 'не согласовано',
  [FAKE_LTD_TEAM_TASK_STATUS.TERMINATED]: 'сделка расторгнута',
};

export const FAKE_LTD_TEAM_TASK_STATUS_MAIL_TYPE = {
  [FAKE_LTD_TEAM_TASK_STATUS.SENT]: MAIL_TYPE.OUTCOMING,
  [FAKE_LTD_TEAM_TASK_STATUS.RECEIVED]: MAIL_TYPE.INCOMING,
  [FAKE_LTD_TEAM_TASK_STATUS.COUNTER_RECEIVED]: MAIL_TYPE.INCOMING,
  [FAKE_LTD_TEAM_TASK_STATUS.COUNTER_SENT]: MAIL_TYPE.OUTCOMING,
  [FAKE_LTD_TEAM_TASK_STATUS.AGREED]: MAIL_TYPE.CONFIRMED,
  [FAKE_LTD_TEAM_TASK_STATUS.PRETENSION]: MAIL_TYPE.INCOMING,
  [FAKE_LTD_TEAM_TASK_STATUS.FINE]: MAIL_TYPE.CANCELLED,
  [FAKE_LTD_TEAM_TASK_STATUS.TWO_WAY_TERMINATION]: MAIL_TYPE.CANCELLED,
  [FAKE_LTD_TEAM_TASK_STATUS.EXAMINED_BY_DOCTOR]: MAIL_TYPE.CONFIRMED,
  [FAKE_LTD_TEAM_TASK_STATUS.ACTION_COMPLETED]: MAIL_TYPE.CONFIRMED,
  [FAKE_LTD_TEAM_TASK_STATUS.CANCELLED]: MAIL_TYPE.CANCELLED,
  [FAKE_LTD_TEAM_TASK_STATUS.TERMINATED]: MAIL_TYPE.CANCELLED,
};

export const FAKE_LTD_TEAM_TASK_DATA = {
  // SENT:
  // title: “Сделка отправлена“
  // description: “x[количество_котиков] котиков“
  // subdescription: “Отправлено на согласование“
  // Пояснение. Статус описывает состояние сделки, когда вы - отправитель, и она была первично отправлена контрагенту и не подтверждена контрагентом
  // Тип конверта: исходящая сделка, синяя стрелочка
  [FAKE_LTD_TEAM_TASK_STATUS.SENT]: {
    validate: (ltd) => {
      return (
        ltd.status === LTD_STATUS.TERMS_AGREEMENT &&
        (!ltd.updater || ltd.updater === RootStore.appStore.ourCeoUuid) &&
        ltd.initiator === RootStore.appStore.ourCeoUuid &&
        !ltd.partner_confirmed
      );
    },
  },
  // RECEIVED:
  // title: “Вам отправили сделку“
  // description: “x[количество_котиков] котиков“
  // subdescription “Получена“
  // Пояснение. Статус описывает состояние сделки, когда вы - получатель, и она была первично отправлена контрагентом и не подтверждена вами
  // Тип конверта: входящая сделка, фиолетовая стрелочка
  [FAKE_LTD_TEAM_TASK_STATUS.RECEIVED]: {
    validate: (ltd) => {
      return (
        ltd.status === LTD_STATUS.TERMS_AGREEMENT &&
        !ltd.updater &&
        ltd.initiator !== RootStore.appStore.ourCeoUuid &&
        !ltd.partner_confirmed
      );
    },
  },
  // COUNTER_RECEIVED:
  // title: “Встречные условия сделки“
  // description: “x[количество_котиков] котиков“
  // subdescription: “получена“
  // Пояснение. Статус описывает состояние сделки, когда вы получаете встречные условия сделки
  // Тип конверта: входящая сделка, фиолетовая стрелочка
  [FAKE_LTD_TEAM_TASK_STATUS.COUNTER_RECEIVED]: {
    validate: (ltd) => {
      return ltd.status === LTD_STATUS.TERMS_AGREEMENT && ltd.updater !== RootStore.appStore.ourCeoUuid;
    },
  },
  // COUNTER_SENT:
  // title: “Встречные условия сделки“
  // description: “x[количество_котиков] котиков“
  // subdescription: “Отправлено на согласование“
  // Пояснение. Статус описывает состояние сделки, когда вы отправляете встречные условия сделки
  // Тип конверта: исходящая сделка, синяя стрелочка
  [FAKE_LTD_TEAM_TASK_STATUS.COUNTER_SENT]: {
    validate: (ltd) => {
      return ltd.status === LTD_STATUS.TERMS_AGREEMENT && ltd.updater === RootStore.appStore.ourCeoUuid;
    },
  },
  // AGREED:
  // title: “Согласование сделки“
  // description: “x[количество_котиков] котиков“
  // subdescription: “Согласована“
  // Пояснение. Статус описывает состояние сделки, когда сделка согласована и вы последний согласующий в сделке
  // Тип конверта: сделка оформлена, зеленая галочка
  [FAKE_LTD_TEAM_TASK_STATUS.AGREED]: {
    validate: (ltd) => {
      return (
        ltd.status === LTD_STATUS.ACTIONS_PHASE &&
        ltd.contents.every((content) => {
          return (
            !content.actions.length || content.actions.every((action) => action.status === LTD_ACTIONS_STATUS.PENDING)
          );
        }) &&
        !ltd.contents.some((content) => {
          return RootStore.appStore.LTDContentHasLiability(content, false, true);
        }) &&
        !ltd.two_way_termination
      );
    },
  },
  // EXAMINED_BY_DOCTOR:
  // title: “Доктор“
  // description: “x[количество_котиков] котиков“
  // subdescription: “Выполнено“
  // Пояснение. Статус описывает состояние сделки, когда ваш доктор осмотрел склад при отправке склада или при осмотре склада перед оплатой
  // Тип конверта: сделка оформлена, зеленая галочка
  [FAKE_LTD_TEAM_TASK_STATUS.EXAMINED_BY_DOCTOR]: {
    validate: (ltd, content) => {
      const firstPendingAction = RootStore.appStore.nextLTDAction(content.actions);
      const ourDoctorRequest = RootStore.appStore.getOurDoctorInfoInDoctorRequests(content.doctor_requests);
      return (
        [LTD_ACTIONS.TO_STORAGE, LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT].includes(firstPendingAction?.action_type) &&
        [LTD_DOCTOR_REQUEST_STATUSES.APPROVED, LTD_DOCTOR_REQUEST_STATUSES.DECLINED].includes(ourDoctorRequest?.status)
      );
    },
  },
  // PRETENSION:
  // title: “Претензия за [тип_нарушенного_действия]“
  // description: “x[количество_котиков] котиков“
  // subdescription: “Не выполнено“
  // Пояснение. Статус описывает состояние сделки, когда вы или вам отправили претензию за определенное действие
  // Тип конверта: входящая сделка, фиолтовая стрелочка
  [FAKE_LTD_TEAM_TASK_STATUS.PRETENSION]: {
    validate: (ltd, content) => {
      return ltd.status === LTD_STATUS.ACTIONS_PHASE && RootStore.appStore.LTDContentHasLiability(content, false, true);
    },
  },
  // FINE:
  // title: “Штраф за [тип_нарушенного_действия]“
  // description: я отправитель штрафа ? “Получение оплаты штрафа“ : “Оплата штрафа“
  // subdescription: “Выполнено“
  // Пояснение. Статус описывает состояние сделки, когда вы или вам отправили претензию за определенное действие
  // Тип конверта: отмена сделки, красный крестик
  [FAKE_LTD_TEAM_TASK_STATUS.FINE]: {
    validate: (ltd) => {
      return (
        ltd.status === LTD_STATUS.TERMINATED &&
        ltd.contents.some((content) => {
          return RootStore.appStore.LTDContentHasLiability(content, true, false, true);
        })
      );
    },
  },
  // TWO_WAY_TERMINATION:
  // title: “Расторжение по соглашению сторон“
  // descrtiption: я отправитель двустороннее расторжения ? “Оплата расторжения“ : “Получение оплаты расторжения“
  // subdescription: “Выполнено“
  // Пояснение. Статус описывает состояние сделки, когда вы или вам отправили двустороннее расторжение
  // Тип конверта: отмена сделки, красный крестик
  [FAKE_LTD_TEAM_TASK_STATUS.TWO_WAY_TERMINATION]: {
    validate: (ltd) => {
      return ltd.status === LTD_STATUS.TERMINATED && !!ltd.two_way_termination;
    },
  },
  // ACTION_COMPLETED:
  // title: “[тип_действия]“
  // description: “x[количество_котиков] котиков“
  // subdescription: “Выполнено“
  // Пояснение. Статус описывает состояние сделки, когда завершено какое-либо из действий по сделке
  // Тип конверта: сделка оформлена, зеленая галочка
  [FAKE_LTD_TEAM_TASK_STATUS.ACTION_COMPLETED]: {
    validate: (ltd, content) => {
      const lastCompletedByMeAction = RootStore.appStore.lastCompletedByMeLTDAction(content.actions);
      const firstPendingAction = RootStore.appStore.nextLTDAction(content.actions);
      return (
        [LTD_STATUS.ACTIONS_PHASE, LTD_STATUS.COMPLETED].includes(ltd.status) &&
        lastCompletedByMeAction &&
        lastCompletedByMeAction.season === RootStore.appStore.currentSeason + 1 &&
        (!firstPendingAction || firstPendingAction.executor !== RootStore.appStore.ourCeoUuid)
      );
    },
  },
  // CANCELLED:
  // title: “Сделка отменена“
  // description: “x[количество_котиков] котиков“
  // subdescription: “сделка отменена“
  // Пояснение. Статус описывает состояние сделки, когда сделка была отменена на этапе согласования
  // Тип конверта: сделка отменена, красный крестик
  [FAKE_LTD_TEAM_TASK_STATUS.CANCELLED]: {
    validate: (ltd) => {
      return ltd.status === LTD_STATUS.CANCELED;
    },
  },
  // TERMINATED:
  // title: “Сделка расторгнута“
  // description: “x[количество_котиков] котиков“
  // subdescription: “сделка расторгнута“
  // Пояснение. Статус описывает состояние сделки, когда согласованная сделка была расторгнута
  // Тип конверта: сделка отменена, красный крестик
  [FAKE_LTD_TEAM_TASK_STATUS.TERMINATED]: {
    validate: (ltd) => {
      return ltd.status === LTD_STATUS.TERMINATED;
    },
  },
};

export const SLIDER_THEME = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const TIME_FORMATS = {
  DAYS: 'days',
  HOURS: 'hours',
  MILLISECONDS: 'milliseconds',
  MINUTES: 'minutes',
  MONTHS: 'months',
  SECONDS: 'seconds',
  YEARS: 'years',
};

export const TIME_DECLINATION = {
  [TIME_FORMATS.DAYS]: ['день', 'дня', 'дней'],
  [TIME_FORMATS.HOURS]: ['час', 'часа', 'часов'],
  [TIME_FORMATS.MILLISECONDS]: ['миллисекунда', 'миллисекунды', 'миллисекунд'],
  [TIME_FORMATS.MINUTES]: ['минута', 'минуты', 'минут'],
  [TIME_FORMATS.MONTHS]: ['месяц', 'месяца', 'месяцев'],
  [TIME_FORMATS.SECONDS]: ['секунда', 'секунды', 'секунд'],
  [TIME_FORMATS.YEARS]: ['год', 'года', 'лет'],
};
