import React, { useCallback } from 'react';
import RootStore from '../../../../store';
import { observer } from 'mobx-react';
import { CoinIcon, CloseIcon, TeamReworkIcon } from '../../../../ui/icons';
import classNames from '../../../../common/classNames';
import LotTimer from './LotTimer';
import { catCount } from '../../../../common/texts/catCount';
import { LTD_FINES_TYPE } from '../../../../common/constants';

function LotPanelPrice() {
  const { appStore } = RootStore;
  const lot_id = appStore.currentPlayer?.lot?.lot_id;
  const disable = !appStore.currentPlayer?.lot;
  const isRevisioning = appStore.currentLotIsRevisioning;
  const onCancel = useCallback(() => {
    if (!disable) {
      appStore.cancelLot();
    }
  }, [disable]);

  const isTeamMode = appStore.isTeamMode;
  const currentAction = appStore.currentAction();

  const isLotAllreadyCancelled = () => {
    const task_id = appStore.currentPlayer.lot?.task_id;
    if (task_id) {
      return appStore.tasks.find((task) => task.task_id === task_id && task.status === 'cancelled');
    } else {
      return false;
    }
  };

  // ToDo Информация по складу для LTD
  let LTDDetail = {};
  let LTDCats;

  if (appStore.currentDoctorReviewingLTD) {
    LTDDetail.actionDetail = appStore.currentDoctorReviewingLTD.ltd_action;
    LTDCats = appStore.currentDoctorReviewingLTD.content.contents;
  } else {
    LTDDetail = appStore.me.actionLTD;
    LTDCats = LTDDetail && LTDDetail.cats.filter((cat) => cat.price);
  }
  let countInfo = {
    need: 0,
    fact: 0,
  };
  if (LTDCats) {
    LTDCats.forEach((cat) => {
      countInfo.need += cat.needCount;
      countInfo.fact += cat.factCount;
    });
  }
  const actionLTDIsNotFine = appStore.me?.actionLTD && !appStore.LTDActionIsFine;
  const canSendToRevisionAndLotIsNotCancelled = currentAction?.canSendToRevision && !isLotAllreadyCancelled();
  const withRework =
    canSendToRevisionAndLotIsNotCancelled || actionLTDIsNotFine || appStore.isCaptainCanReworkWhenDoctorNotApprove;

  return (
    <div
      className={classNames({
        'lot-panel__price': true,
        'lot-panel__price--ltd': LTDDetail,
      })}
    >
      {withRework && (
        <button
          onClick={() => {
            if (canSendToRevisionAndLotIsNotCancelled) {
              appStore.actionOnTask(true);
            } else if (actionLTDIsNotFine) {
              appStore.openLTDLiabilityModal(appStore.currentLiabilityLTD, false, true);
            } else if (appStore.isCaptainCanReworkWhenDoctorNotApprove) {
              appStore.openLTDLiabilityModal(
                {
                  ...appStore.currentLiabilityLTD,
                  liability: [
                    {
                      liability_type: LTD_FINES_TYPE.LOW_QUALITY,
                    },
                  ],
                },
                false,
                true,
              );
            }
          }}
          className="lot-panel__price--rework"
        >
          <TeamReworkIcon />
        </button>
      )}
      <div
        className={classNames({
          'coins-counter-wrap': true,
          'coins-counter-wrap--with-revision':
            (currentAction?.canSendToRevision && !isLotAllreadyCancelled()) || !appStore.LTDActionIsFine,
        })}
      >
        {!currentAction?.isCancelActionByCaptain ? (
          !LTDDetail ? (
            <p className="coins-counter-description">
              <span className="notraslate">x&nbsp;</span>
              <span className="notranslate">{appStore.lotCatCount}</span>
              <span>&nbsp;{appStore.catsInflectedWord(appStore.lotCatCount).toUpperCase()}</span>
            </p>
          ) : (
            <p className="coins-counter-description">
              <span>x </span>
              <span className="notranslate">
                {countInfo.fact}/{countInfo.need}
              </span>
              <span> {appStore.catsInflectedWord(countInfo.need).toUpperCase()}</span>
            </p>
          )
        ) : (
          <p className="coins-counter-description coins-counter-cancel-description">Хотите отменить?</p>
        )}
        {!LTDDetail && (
          <div className="coins-counter">
            <CoinIcon />
            <span className="coins-counter-value notranslate">{appStore.getLotPrice(appStore.currentPlayer?.lot)}</span>
          </div>
        )}
        {LTDDetail && <LotTimer timer={LTDDetail.actionDetail.timer} />}
      </div>
      {!isTeamMode && !LTDDetail && (!lot_id || isRevisioning) ? (
        <CloseIcon
          style={{
            fill: disable ? '#CC7272' : '#C70000',
          }}
          onClick={onCancel}
        />
      ) : null}
    </div>
  );
}

export default observer(LotPanelPrice);
