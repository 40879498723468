import React, { useState } from 'react';
import RootStore from '../store';
import routes from '../routes';
import { Link } from 'mobx-router';
import { LocalStorage } from '../common/localStorage';
import api from '../api';
import _ from 'lodash';
import { TRAINING_CHAPTER_TYPE } from '../store/constants';

// eslint-disable-next-line react/display-name
export default () => {
  const [gameUuid, setGameUuid] = useState(LocalStorage.GAME_UID);
  const [shopCount, setShopCount] = useState(2);
  const [nurseryCount, setNurseryCount] = useState(1);
  const [withBots, setWithBots] = useState(false);
  const [withSupportBot, setWithSupportBot] = useState(false);
  const [botSpectate, setBotSpectate] = useState(false);
  const [withHideInformation, setWithHideInformation] = useState(false);
  const [withCredit, setWithCredit] = useState(false);
  const [withLtd, setWithLtd] = useState(false);
  const [withDelegationLtd, setWithDelegationLtd] = useState(false);
  const [withChanceBankruptcy, setWithChanceBankruptcy] = useState(false);
  const [chancePercent, setChancePercent] = useState(10);
  const [withPrivilegesChange, setWithPrivilegesChange] = useState(false);
  const [maxPossibleFine, setMaxPossibleFine] = useState(120);
  const [bankGuarantee, setBankGuarantee] = useState(100);
  const [possibleCreditForNursery, setPossibleCreditForNursery] = useState(80);
  const [possibleCreditForShop, setPossibleCreditForShop] = useState(80);
  const [consumerCreditRate, setConsumerCreditRate] = useState(5);
  const [investmentCreditRate, setInvestmentCreditRate] = useState(10);
  const [specialCreditRate, setSpecialCreditRate] = useState(15);
  const [seasonMinuteDuration, setSeasonMinuteDuration] = useState('10, 5, 5, 5, 15, 5, 5, 5, 15, 5, 15, 10, 15');
  const [withRandomGameEnd, setWithRandomGameEnd] = useState(false);
  const [randomGameEndBegin, setRandomGameEndBegin] = useState(10);
  const [initialMoneyShop, setInitialMoneyShop] = useState(70);
  const [initialMoneyNursery, setInitialMoneyNursery] = useState(70);
  const [initialMoneySingleShopBot, setInitialMoneySingleShopBot] = useState(200);
  const [initialMoneySingleNurseryBot, setInitialMoneySingleNurseryBot] = useState(70);
  const [cityBaseQuotaMin, setCityBaseQuotaMin] = useState(120);
  const [cityBaseQuotaMax, setCityBaseQuotaMax] = useState(200);
  const [houseHoldCostShop, setHouseHoldCostShop] = useState(1);
  const [houseHoldCostNursery, setHouseHoldCostNursery] = useState(3);
  const [absenceTimeout, setAbsenceTimeout] = useState(60 * 10);
  const [isTeamGame, setIsTeamGame] = useState(false);
  // корп версия
  const [playersForCorp, setPlayersForCorp] = useState([]);
  const [shopCountForCorp, setShopCountForCorp] = useState(4);
  const [nurseryCountForCorp, setNurseryCountForCorp] = useState(20);
  const [areaCount, setAreaCount] = useState(5);
  const ceoCount = shopCountForCorp + nurseryCountForCorp;
  const [isCorpGame, setIsCorpGame] = useState(false);
  const [initialMoneyCeo, setInitialMoneyCeo] = useState(350);
  const [initialMoneyTeamInCity, setInitialMoneyTeamInCity] = useState(70);
  const [householdCostShopCeo, setHouseholdCostShopCeo] = useState(1);
  const [householdCostNurseryCeo, setHouseholdCostNurseryCeo] = useState(3);
  const [cityCorpMinQuota, setCityCorpMinQuota] = useState(250);
  const [cityCorpMaxQuota, setCityCorpMaxQuota] = useState(350);
  const [bigCarBuyCost, setBigCarBuyCost] = useState(100);
  const [bigCarRentCost, setBigCarRentCost] = useState(40);
  const [smallCarBuyCost, setSmallCarBuyCost] = useState(60);
  const [smallCarRentCost, setSmallCarRentCost] = useState(25);
  const [variableImportExport, setVariableImportExport] = useState(3);
  // realtime
  const [isRealTimeGame, setIsRealTimeGame] = useState(false);
  const [monthDuration, setMonthDuration] = useState(180);
  const [seasonsNumber, setSeasonsNumber] = useState(4);

  const renderAllTeamLinks = () => {
    if (!isTeamGame) {
      return;
    }
    const teamLinks = [];
    let teamNumber = 1;
    for (let i = 0; i < parseInt(nurseryCount) + parseInt(shopCount); i++) {
      if (i === parseInt(nurseryCount)) {
        teamNumber = 1;
      }
      for (let j = 1; j <= 5; j++) {
        let playerUid = () => {
          const firstPart = '00000000-0000-0000-0000-000000000';
          let secondPart = i === 0 ? 0 : i + 1;
          if (i < 10) {
            secondPart = '0' + secondPart;
          }
          return `${firstPart}${secondPart}${j}`;
        };
        let specialty = '';
        if (j === 1) {
          specialty = 'капитан';
        } else if (j === 2) {
          specialty = 'маркетолог';
        } else if (j === 3) {
          specialty = 'продажник';
        } else if (j === 4) {
          specialty = 'закупщик';
        } else if (j === 5) {
          specialty = 'доктор';
        }

        const content = (
          <div style={{ display: 'flex', flexDirection: 'column' }} key={`${i}+${j}`}>
            {j === 1 && (
              <span style={{ marginTop: '8px', display: 'inline-block' }}>
                {i < parseInt(nurseryCount) ? `ПИТОМНИК №${teamNumber}` : `ЗООМАГАЗИН №${teamNumber}`}
              </span>
            )}
            <div style={{ display: 'flex' }}>
              <a
                style={{ display: 'inline-block' }}
                href={`${location.origin}/play/${gameUuid}/${playerUid()}?server=${LocalStorage.JOIN_URL}`}
              >
                {specialty}
              </a>
              <span>{` - ${location.origin}/play/${gameUuid}/${playerUid()}?server=${LocalStorage.JOIN_URL}`}</span>
            </div>
          </div>
        );
        teamLinks.push(content);
      }
      teamNumber++;
    }
    return teamLinks;
  };

  const renderAllCorpTeams = () => {
    const listCEO = _.range(1, ceoCount + 1, 1).map((i) => {
      let team = 'shop';
      if (i <= nurseryCountForCorp) {
        team = 'nursery';
      }
      return {
        uid: '',
        name: 'ceo fake name',
        team: team,
        areaNum: null,
        corpNum: i,
        role: 'ceo',
      };
    });
    const listCompanies = _.flattenDeep(
      listCEO.map((ceo) => {
        return _.range(1, areaCount + 1, 1).map((area) => {
          return _.range(1, 6, 1).map((player) => {
            let specialty = '';
            if (player === 1) {
              specialty = 'captain';
            } else if (player === 2) {
              specialty = 'marketeer';
            } else if (player === 3) {
              specialty = 'sales';
            } else if (player === 4) {
              specialty = 'supply';
            } else if (player === 5) {
              specialty = 'doctor';
            }
            return {
              uid: '',
              name: `${specialty} fake name`,
              team: ceo.team,
              areaNum: area,
              corpNum: ceo.corpNum,
              role: specialty,
            };
          });
        });
      }),
    );

    const players = _.concat(listCEO, listCompanies);
    players.forEach((i, index) => {
      const prefix = index + 1;
      const uuid = () => {
        const firstPart = '00000000-0000-0000-0000-00000000';
        let secondPart = '0';
        if (prefix < 10) {
          secondPart = '00' + secondPart;
        } else if (prefix < 100) {
          secondPart = '0' + secondPart;
        }
        return `${firstPart}${secondPart}${prefix}`;
      };
      i.uid = uuid();
    });
    setPlayersForCorp(players);
  };

  return (
    <div style={{ height: '100%', overflow: 'scroll' }}>
      {!gameUuid ? (
        <>
          <div>
            Количество магазинов:{' '}
            <input type={'number'} max={4} value={shopCount} onChange={(e) => setShopCount(e.target.value)} />
          </div>
          <div>
            Количество питомников:{' '}
            <input type={'number'} max={20} value={nurseryCount} onChange={(e) => setNurseryCount(e.target.value)} />
          </div>
          <div>
            С ботами: <input type={'checkbox'} checked={withBots} onChange={(e) => setWithBots(e.target.checked)} />
          </div>
          <div>
            С саппорт ботом:
            <input type={'checkbox'} checked={withSupportBot} onChange={(e) => setWithSupportBot(e.target.checked)} />
          </div>
          <div>
            Наблюдение за ботами:
            <input type={'checkbox'} checked={botSpectate} onChange={(e) => setBotSpectate(e.target.checked)} />
          </div>
          <div>
            Очный режим:
            <input
              type={'checkbox'}
              checked={withHideInformation}
              onChange={(e) => setWithHideInformation(e.target.checked)}
            />
          </div>
          <div>
            Механика кредитов:
            <input type={'checkbox'} checked={withCredit} onChange={(e) => setWithCredit(e.target.checked)} />
          </div>
          <div>
            Механика долгосрочных сделок:
            <input type={'checkbox'} checked={withLtd} onChange={(e) => setWithLtd(e.target.checked)} />
          </div>
          <div>
            Делегирование прав на заключение долгосрочной сделки :
            <input
              type={'checkbox'}
              checked={withDelegationLtd}
              onChange={(e) => setWithDelegationLtd(e.target.checked)}
            />
          </div>
          <div>
            Включить шанс банкротства при отсутствии банковской гарантии:
            <input
              type={'checkbox'}
              checked={withChanceBankruptcy}
              onChange={(e) => setWithChanceBankruptcy(Boolean(e.target.checked))}
            />
          </div>
          <div>
            Шанс банкротства при отсутсвии банковской гарантии:{' '}
            <input
              type={'number'}
              max={100}
              value={chancePercent}
              onChange={(e) => setChancePercent(Number(e.target.value))}
            />
          </div>
          <div>
            Делегирование прав продажи/покупки:
            <input
              type={'checkbox'}
              checked={withPrivilegesChange}
              onChange={(event) => setWithPrivilegesChange(event.target.checked)}
            />
          </div>
          <div>
            Доступная сумма кредитования для питомника:{' '}
            <input
              type={'number'}
              max={10000}
              value={possibleCreditForNursery}
              onChange={(e) => setPossibleCreditForNursery(e.target.value)}
            />
          </div>
          <div>
            Доступная сумма кредитования для магазина:{' '}
            <input
              type={'number'}
              max={10000}
              value={possibleCreditForShop}
              onChange={(e) => setPossibleCreditForShop(e.target.value)}
            />
          </div>
          <div>
            Проценты для потребительского кредита:{' '}
            <input
              type={'number'}
              max={10000}
              value={consumerCreditRate}
              onChange={(e) => setConsumerCreditRate(e.target.value)}
            />
          </div>
          <div>
            Проценты для инвестиционного кредита:{' '}
            <input
              type={'number'}
              max={10000}
              value={investmentCreditRate}
              onChange={(e) => setInvestmentCreditRate(e.target.value)}
            />
          </div>
          <div>
            Проценты для кредита со спец. условиями:{' '}
            <input
              type={'number'}
              max={10000}
              value={specialCreditRate}
              onChange={(e) => setSpecialCreditRate(e.target.value)}
            />
          </div>
          <div>
            Максимально доступная сумма штрафа по долгосрочной сделке:{' '}
            <input
              type={'number'}
              max={10000}
              value={maxPossibleFine}
              onChange={(e) => setMaxPossibleFine(e.target.value)}
            />
          </div>
          <div>
            Максимально доступная сумма банковской гарантии долгосрочной сделки:{' '}
            <input
              type={'number'}
              max={10000}
              value={bankGuarantee}
              onChange={(e) => setBankGuarantee(e.target.value)}
            />
          </div>
          <div>
            Количество минут в сезоне:{' '}
            <input value={seasonMinuteDuration} onChange={(e) => setSeasonMinuteDuration(e.target.value)} />
          </div>
          <div>
            Включить завершение сессии в случайный сезон:{' '}
            <input
              type={'checkbox'}
              checked={withRandomGameEnd}
              onChange={(e) => setWithRandomGameEnd(Boolean(e.target.checked))}
            />
          </div>
          <div>
            Начальный сезон рандомного завершения сессии:{' '}
            <input
              type="number"
              value={randomGameEndBegin}
              onChange={(e) => setRandomGameEndBegin(Number(e.target.value) || undefined)}
            />
          </div>
          <div>
            Стоимость коммунальных услуг у магазина:{' '}
            <input
              type={'number'}
              max={10000}
              value={houseHoldCostShop}
              onChange={(e) => setHouseHoldCostShop(e.target.value)}
            />
          </div>
          <div>
            Стоимость коммунальных услуг у питомника:{' '}
            <input
              type={'number'}
              max={10000}
              value={houseHoldCostNursery}
              onChange={(e) => setHouseHoldCostNursery(e.target.value)}
            />
          </div>
          <div>
            Количество монет в начале у зоомагазина:{' '}
            <input
              type={'number'}
              max={10000}
              value={initialMoneyShop}
              onChange={(e) => setInitialMoneyShop(e.target.value)}
            />
          </div>
          <div>
            Количество монет в начале у питомника:{' '}
            <input
              type={'number'}
              max={10000}
              value={initialMoneyNursery}
              onChange={(e) => setInitialMoneyNursery(e.target.value)}
            />
          </div>
          <div>
            Количество секунд возможного отсутствия в игре:{' '}
            <input
              type={'number'}
              max={10000}
              value={absenceTimeout}
              onChange={(e) => setAbsenceTimeout(e.target.value)}
            />
          </div>
          <div>
            Минимальное значение квоты:{' '}
            <input
              type={'number'}
              max={1000}
              value={cityBaseQuotaMin}
              onChange={(e) => setCityBaseQuotaMin(e.target.value)}
            />
          </div>
          <div>
            Максимальное значение квоты:{' '}
            <input
              type={'number'}
              max={1000}
              value={cityBaseQuotaMax}
              onChange={(e) => setCityBaseQuotaMax(e.target.value)}
            />
          </div>
          <div>
            Стартовый баланс бота зоомагазина:{' '}
            <input
              type={'number'}
              max={10000}
              value={initialMoneySingleShopBot}
              onChange={(e) => setInitialMoneySingleShopBot(e.target.value)}
            />
          </div>
          <div>
            Стартовый баланс бота питомника:{' '}
            <input
              type={'number'}
              max={10000}
              value={initialMoneySingleNurseryBot}
              onChange={(e) => setInitialMoneySingleNurseryBot(e.target.value)}
            />
          </div>

          <div style={{ padding: '4px' }}>
            Включить механику корпоративной версии игры:
            <input type={'checkbox'} onChange={() => setIsCorpGame(!isCorpGame)} />
          </div>
          {isCorpGame && (
            <div style={{ padding: '4px', border: '1px solid lightgrey' }}>
              <div style={{ padding: '3px 0' }}>
                Количество зоомагазинов:{' '}
                <input
                  type={'number'}
                  max={4}
                  value={shopCountForCorp}
                  onChange={(e) => setShopCountForCorp(parseInt(e.target.value))}
                />
              </div>
              <div style={{ padding: '3px 0' }}>
                Количество питомников:{' '}
                <input
                  type={'number'}
                  max={20}
                  value={nurseryCountForCorp}
                  onChange={(e) => setNurseryCountForCorp(parseInt(e.target.value))}
                />
              </div>
              <div style={{ padding: '3px 0' }}>
                Количество городов:{' '}
                <input
                  type={'number'}
                  min={2}
                  max={5}
                  value={areaCount}
                  onChange={(e) => setAreaCount(parseInt(e.target.value))}
                />
              </div>
              <div style={{ padding: '3px 0' }}>
                Количество CЕО: <input type={'number'} readOnly value={ceoCount} />
              </div>
              <div style={{ padding: '3px 0' }}>
                <input
                  type={'button'}
                  value="Собрать игроков для корпоративной версии"
                  onClick={() => renderAllCorpTeams()}
                />
              </div>
              <div style={{ padding: '3px 0' }}>
                Количество монет в начале у СЕО:{' '}
                <input
                  type={'number'}
                  max={10000}
                  value={initialMoneyCeo}
                  onChange={(e) => setInitialMoneyCeo(e.target.value)}
                />
              </div>

              <div style={{ padding: '3px 0' }}>
                Количество монет в начале у команды:{' '}
                <input
                  type={'number'}
                  max={10000}
                  value={initialMoneyTeamInCity}
                  onChange={(e) => setInitialMoneyTeamInCity(e.target.value)}
                />
              </div>

              <div style={{ padding: '3px 0' }}>
                Стоимость коммунальных услуг у магазина СЕО:{' '}
                <input
                  type={'number'}
                  max={10000}
                  value={householdCostShopCeo}
                  onChange={(e) => setHouseholdCostShopCeo(e.target.value)}
                />
              </div>

              <div style={{ padding: '3px 0' }}>
                Стоимость коммунальных услуг у питомника СЕО:{' '}
                <input
                  type={'number'}
                  max={10000}
                  value={householdCostNurseryCeo}
                  onChange={(e) => setHouseholdCostNurseryCeo(e.target.value)}
                />
              </div>

              <div style={{ padding: '3px 0' }}>
                Минимальная квота города:{' '}
                <input
                  type={'number'}
                  max={10000}
                  value={cityCorpMinQuota}
                  onChange={(e) => setCityCorpMinQuota(e.target.value)}
                />
              </div>

              <div style={{ padding: '3px 0' }}>
                Максимальная квота города:{' '}
                <input
                  type={'number'}
                  max={10000}
                  value={cityCorpMaxQuota}
                  onChange={(e) => setCityCorpMaxQuota(e.target.value)}
                />
              </div>

              <div style={{ padding: '3px 0' }}>
                Стоимость покупки большой машины:{' '}
                <input
                  type={'number'}
                  max={10000}
                  value={bigCarBuyCost}
                  onChange={(e) => setBigCarBuyCost(e.target.value)}
                />
              </div>

              <div style={{ padding: '3px 0' }}>
                Стоимость аренды большой машины:{' '}
                <input
                  type={'number'}
                  max={10000}
                  value={bigCarRentCost}
                  onChange={(e) => setBigCarRentCost(e.target.value)}
                />
              </div>

              <div style={{ padding: '3px 0' }}>
                Стоимость покупки маленькой машины:{' '}
                <input
                  type={'number'}
                  max={10000}
                  value={smallCarBuyCost}
                  onChange={(e) => setSmallCarBuyCost(e.target.value)}
                />
              </div>

              <div style={{ padding: '3px 0' }}>
                Стоимость аренды маленькой машины:{' '}
                <input
                  type={'number'}
                  max={10000}
                  value={smallCarRentCost}
                  onChange={(e) => setSmallCarRentCost(e.target.value)}
                />
              </div>

              <div style={{ padding: '3px 0' }}>
                Переменная Импорт/Экспорт:{' '}
                <input
                  type={'number'}
                  max={10000}
                  value={variableImportExport}
                  onChange={(e) => setVariableImportExport(e.target.value)}
                />
              </div>

              <div style={{ padding: '3px 0' }}>
                <button
                  onClick={async () => {
                    if (!playersForCorp.length) {
                      alert('Игроки не сформированы');
                      return;
                    }
                    const game_uuid =
                      '00006adb-0000-47e7-853e-06a0' +
                      ('' + Math.trunc(Math.random() * 10000)).padStart(4, '0') +
                      '0000';
                    const result = await api.common.createCorpGame(
                      playersForCorp,
                      game_uuid,
                      withBots,
                      seasonMinuteDuration,
                      parseInt(initialMoneyShop),
                      parseInt(initialMoneyNursery),
                      parseInt(houseHoldCostShop),
                      parseInt(houseHoldCostNursery),
                      parseInt(absenceTimeout),
                      withSupportBot,
                      botSpectate,
                      withHideInformation,
                      withCredit,
                      consumerCreditRate,
                      investmentCreditRate,
                      specialCreditRate,
                      possibleCreditForNursery,
                      possibleCreditForShop,
                      initialMoneyCeo,
                      initialMoneyTeamInCity,
                      parseInt(householdCostShopCeo),
                      parseInt(householdCostNurseryCeo),
                      bigCarBuyCost,
                      bigCarRentCost,
                      smallCarBuyCost,
                      smallCarRentCost,
                      variableImportExport,
                      withLtd,
                      withChanceBankruptcy,
                      chancePercent,
                      maxPossibleFine,
                      bankGuarantee,
                      parseInt(cityBaseQuotaMin),
                      parseInt(cityBaseQuotaMax),
                      parseInt(cityCorpMinQuota),
                      parseInt(cityCorpMaxQuota),
                      withRandomGameEnd,
                      randomGameEndBegin,
                      withDelegationLtd,
                    );
                    LocalStorage.JOIN_URL = result.playQueryParams.server;
                    setGameUuid(game_uuid);
                  }}
                >
                  Создать корпоративную игру с параметрами выше ↑
                </button>
              </div>
            </div>
          )}
          <div style={{ padding: '4px' }}>
            Включить механику real-time версии игры:
            <input type={'checkbox'} onChange={() => setIsRealTimeGame(!isRealTimeGame)} />
          </div>
          {isRealTimeGame && (
            <div style={{ padding: '4px', border: '1px solid lightgrey' }}>
              <div style={{ padding: '3px 0' }}>
                Продолжительность одного игрового месяца в секундах{' '}
                <input
                  type={'number'}
                  value={monthDuration}
                  onChange={(e) => setMonthDuration(parseInt(e.target.value))}
                />
              </div>
              <div style={{ padding: '3px 0' }}>
                Количество сезонов на игру{' '}
                <input type={'number'} value={seasonsNumber} onChange={(e) => setSeasonsNumber(e.target.value)} />
              </div>
              <div style={{ padding: '3px 0' }}>
                <button
                  onClick={async () => {
                    const game_uuid =
                      '00006adb-0000-47e7-853e-06a0' +
                      ('' + Math.trunc(Math.random() * 10000)).padStart(4, '0') +
                      '0000';
                    const result = await api.common.createRealTimeGame(
                      parseInt(shopCount),
                      parseInt(nurseryCount),
                      game_uuid,
                      withBots,
                      seasonMinuteDuration,
                      parseInt(initialMoneyShop),
                      parseInt(initialMoneyNursery),
                      parseInt(houseHoldCostShop),
                      parseInt(houseHoldCostNursery),
                      parseInt(absenceTimeout),
                      withSupportBot,
                      botSpectate,
                      withHideInformation,
                      withCredit,
                      consumerCreditRate,
                      investmentCreditRate,
                      specialCreditRate,
                      possibleCreditForNursery,
                      possibleCreditForShop,
                      initialMoneyCeo,
                      initialMoneyTeamInCity,
                      bigCarBuyCost,
                      bigCarRentCost,
                      smallCarBuyCost,
                      smallCarRentCost,
                      variableImportExport,
                      withLtd,
                      maxPossibleFine,
                      bankGuarantee,
                      parseInt(cityBaseQuotaMin),
                      parseInt(cityBaseQuotaMax),
                      parseInt(cityCorpMinQuota),
                      parseInt(cityCorpMaxQuota),
                      parseFloat(seasonsNumber),
                      parseInt(monthDuration),
                    );
                    LocalStorage.JOIN_URL = result.playQueryParams.server;
                    setGameUuid(game_uuid);
                  }}
                >
                  Создать real-time игру с параметрами выше ↑
                </button>
              </div>
            </div>
          )}

          <div>
            <button
              onClick={async () => {
                const result = await api.common.createGame(
                  parseInt(shopCount),
                  parseInt(nurseryCount),
                  withBots,
                  seasonMinuteDuration,
                  parseInt(initialMoneyShop),
                  parseInt(initialMoneyNursery),
                  parseInt(houseHoldCostShop),
                  parseInt(houseHoldCostNursery),
                  parseInt(absenceTimeout),
                  withSupportBot,
                  parseInt(cityBaseQuotaMin),
                  parseInt(cityBaseQuotaMax),
                  parseInt(initialMoneySingleShopBot),
                  parseInt(initialMoneySingleNurseryBot),
                  botSpectate,
                  withHideInformation,
                  withCredit,
                  consumerCreditRate,
                  investmentCreditRate,
                  specialCreditRate,
                  possibleCreditForNursery,
                  possibleCreditForShop,
                  withLtd,
                  withChanceBankruptcy,
                  chancePercent,
                  maxPossibleFine,
                  bankGuarantee,
                  withRandomGameEnd,
                  randomGameEndBegin,
                  withDelegationLtd,
                );
                LocalStorage.JOIN_URL = new URL(result.join_url).host.replace('develop', 'dev');
                setGameUuid(result.game_uuid);
              }}
            >
              Создать игру с параметрами выше ↑
            </button>
          </div>

          <div>
            <button
              onClick={async () => {
                const game_uuid =
                  '00006adb-0000-47e7-853e-06a0' + ('' + Math.trunc(Math.random() * 10000)).padStart(4, '0') + '0000';
                const result = await api.common.createTeamGame(
                  game_uuid,
                  parseInt(shopCount),
                  parseInt(nurseryCount),
                  withBots,
                  seasonMinuteDuration,
                  parseInt(initialMoneyShop),
                  parseInt(initialMoneyNursery),
                  parseInt(houseHoldCostShop),
                  parseInt(houseHoldCostNursery),
                  parseInt(absenceTimeout),
                  withSupportBot,
                  parseInt(cityBaseQuotaMin),
                  parseInt(cityBaseQuotaMax),
                  parseInt(initialMoneySingleShopBot),
                  parseInt(initialMoneySingleNurseryBot),
                  botSpectate,
                  withHideInformation,
                  withCredit,
                  consumerCreditRate,
                  investmentCreditRate,
                  specialCreditRate,
                  possibleCreditForNursery,
                  possibleCreditForShop,
                  withLtd,
                  withChanceBankruptcy,
                  chancePercent,
                  withPrivilegesChange,
                  maxPossibleFine,
                  bankGuarantee,
                  withRandomGameEnd,
                  randomGameEndBegin,
                  withDelegationLtd,
                );
                LocalStorage.JOIN_URL = result.playQueryParams.server;
                setGameUuid(game_uuid);
                setIsTeamGame(true);
              }}
            >
              Создать командную игру с параметрами выше ↑
            </button>
          </div>

          <div>
            <button
              onClick={async () => {
                const game_uuid =
                  '00006adb-0000-47e7-853e-06a0' + ('' + Math.trunc(Math.random() * 10000)).padStart(4, '0') + '0000';
                const result = await api.common.createTeamGameTwoPlayers(game_uuid);
                LocalStorage.JOIN_URL = result.playQueryParams.server;
                setGameUuid(game_uuid);
                setIsTeamGame(true);
              }}
            >
              Создать командную игру на двух игроков и саппорт бота. вылет - 60 секунд
            </button>
          </div>

          <div>
            <button
              onClick={async () => {
                const result = await api.common.createGameShop(
                  withCredit,
                  consumerCreditRate,
                  investmentCreditRate,
                  specialCreditRate,
                  possibleCreditForNursery,
                  possibleCreditForShop,
                );
                LocalStorage.JOIN_URL = new URL(result.join_url).host.replace('develop', 'dev');
                setGameUuid(result.game_uuid);
              }}
            >
              Создать игру с одним игроком-зоомагазином и ботами
            </button>
          </div>

          <div>
            <button
              onClick={async () => {
                const result = await api.common.createGameNursery(
                  withCredit,
                  consumerCreditRate,
                  investmentCreditRate,
                  specialCreditRate,
                  possibleCreditForNursery,
                  possibleCreditForShop,
                );
                LocalStorage.JOIN_URL = new URL(result.join_url).host.replace('develop', 'dev');
                setGameUuid(result.game_uuid);
              }}
            >
              Создать игру с одним игроком-питомником и ботами
            </button>
          </div>

          <div>
            <button
              onClick={async () => {
                const result = await api.common.createGameShortSeason(
                  withCredit,
                  consumerCreditRate,
                  investmentCreditRate,
                  specialCreditRate,
                  possibleCreditForNursery,
                  possibleCreditForShop,
                );
                LocalStorage.JOIN_URL = new URL(result.join_url).host.replace('develop', 'dev');
                setGameUuid(result.game_uuid);
              }}
            >
              Создать игру с двумя игроками-питомниками и двумя игроками-зоомагазинами и ботами. с короткими сезонами по
              30секунд
            </button>
          </div>

          <div style={{ display: 'flex', gap: '15px' }}>
            {Object.values(TRAINING_CHAPTER_TYPE).map((trainingType) => {
              return (
                <button
                  key={trainingType}
                  onClick={() => {
                    RootStore.goTo(routes.training, { training_type: trainingType });
                  }}
                >
                  ОБУЧЕНИЕ {trainingType}
                </button>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div>ВНИМАНИЕ: В одном браузере одновременно можно зайти только под одним игроком.</div>
          <div>
            Поэтому эти ссылки нужно открывать в разных браузерах(или одного в инкогнито), чтобы играть одновременно
          </div>

          <div>
            <Link
              route={routes.init}
              params={{
                gameUid: gameUuid,
                userUid: '00000000-0000-0000-0000-000000000001',
              }}
              queryParams={{
                server: LocalStorage.JOIN_URL,
              }}
              router={RootStore.router}
            >
              Перейти в игру
            </Link>
          </div>
          {<div style={{ marginTop: '10px' }}>{renderAllTeamLinks()}</div>}
        </>
      )}
    </div>
  );
};
