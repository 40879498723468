import DelegatingSwitcher, { DELEGATING_SWITCHER_PROPERTY } from '../classes/DelegatingSwitcher';
import corpStore from '../../../../store/CorpStore';
import RootStore from '../../../../store';
import * as teamMode from '../../../../store/teamMode';
import DelegatingSwitcherCheckboxForReadonly, {
  DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY,
} from '../classes/DelegatingSwitcherCheckboxForReadonly';
import { PLAYER_ROLE, SPECIALITIES } from '../../../../common/constants';
import { PRIVILEGES } from '../../../../store/teamMode';
import {
  CORP_DELEGATING_PRIVILEGES,
  CORP_DELEGATING_TYPES,
  DELEGATING_STATES,
  LIMITS_TYPE,
  TASK_TYPES,
} from '../../../../store/constants';

export const CORP_DELEGATING_SWITCHERS = [
  new DelegatingSwitcher({
    [DELEGATING_SWITCHER_PROPERTY.TYPE]: CORP_DELEGATING_TYPES.CATS_SELL,
    [DELEGATING_SWITCHER_PROPERTY.LABEL]: 'Продажа котиков',
    [DELEGATING_SWITCHER_PROPERTY.RELATED_LIMIT_TYPES]: {
      [LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_SELL]: true,
    },
    [DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]: {
      [DELEGATING_STATES.CEO]: {
        [SPECIALITIES.CAPTAIN]: [],
      },
      [DELEGATING_STATES.CAPTAIN]: {
        [SPECIALITIES.CAPTAIN]: [PRIVILEGES.APPROVE_CATS_SELL_BY_CEO],
      },
    },
  }),
  new DelegatingSwitcher({
    [DELEGATING_SWITCHER_PROPERTY.TYPE]: CORP_DELEGATING_TYPES.CATS_BUY,
    [DELEGATING_SWITCHER_PROPERTY.LABEL]: 'Покупка котиков',
    [DELEGATING_SWITCHER_PROPERTY.RELATED_LIMIT_TYPES]: {
      [LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_BUY]: true,
      [LIMITS_TYPE.SPENDING_ON_CAT_BUY]: ({ areaNum }) =>
        RootStore.appStore.me.role === PLAYER_ROLE.NURSERY ||
        !teamMode.playerHasPrivileges(
          corpStore.playerBySpecialityAndAreaNum(SPECIALITIES.CAPTAIN, areaNum).privileges,
          PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CEO,
        ),
    },
    [DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]: {
      [DELEGATING_STATES.CEO]: {
        [SPECIALITIES.CAPTAIN]: [],
      },
      [DELEGATING_STATES.CAPTAIN]: {
        [SPECIALITIES.CAPTAIN]: [PRIVILEGES.APPROVE_CATS_PURCHASE_BY_CEO],
      },
    },
  }),
  new DelegatingSwitcher({
    [DELEGATING_SWITCHER_PROPERTY.TYPE]: CORP_DELEGATING_TYPES.CATS_BUY_FROM_CITY,
    [DELEGATING_SWITCHER_PROPERTY.LABEL]: 'Продажа котиков городу',
    [DELEGATING_SWITCHER_PROPERTY.VISIBLE]: () => RootStore.appStore.me?.role === PLAYER_ROLE.SHOP,
    [DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]: {
      [DELEGATING_STATES.CEO]: {
        [SPECIALITIES.CAPTAIN]: [],
      },
      [DELEGATING_STATES.CAPTAIN]: {
        [SPECIALITIES.CAPTAIN]: [PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CEO],
      },
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.CAPTAIN]: [CORP_DELEGATING_PRIVILEGES.CATS_SELL_TO_CITY],
      },
    },
    [DELEGATING_SWITCHER_PROPERTY.NON_CEO_GIVES_PRIVILEGES]: {
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.SALES]: [PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN, PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CEO],
        [SPECIALITIES.MARKETEER]: [
          PRIVILEGES.CITY_SELL_PUSH_TO_CAPTAIN,
          PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN,
          PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CEO,
        ],
      },
    },
    [DELEGATING_SWITCHER_PROPERTY.CALLBACK]: ({ state, areaNum }) => {
      if (state !== DELEGATING_STATES.TEAM) {
        corpStore.addPrivilegesForSpeciality(SPECIALITIES.SALES, areaNum, [PRIVILEGES.CITY_SELL_PUSH_TO_CAPTAIN]);
      }
    },
  }),
  new DelegatingSwitcher({
    [DELEGATING_SWITCHER_PROPERTY.TYPE]: CORP_DELEGATING_TYPES.CATS_BUY,
    [DELEGATING_SWITCHER_PROPERTY.LABEL]: 'Покупка котиков из города',
    [DELEGATING_SWITCHER_PROPERTY.VISIBLE]: () => RootStore.appStore.me?.role === PLAYER_ROLE.SHOP,
    [DELEGATING_SWITCHER_PROPERTY.RELATED_LIMIT_TYPES]: {
      [LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_BUY]: true,
      [LIMITS_TYPE.SPENDING_ON_CAT_BUY]: ({ areaNum }) =>
        RootStore.appStore.me.role === PLAYER_ROLE.NURSERY ||
        !teamMode.playerHasPrivileges(
          corpStore.playerBySpecialityAndAreaNum(SPECIALITIES.CAPTAIN, areaNum).privileges,
          PRIVILEGES.APPROVE_CATS_PURCHASE_BY_CEO,
        ),
    },
    [DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]: {
      [DELEGATING_STATES.CEO]: {
        [SPECIALITIES.CAPTAIN]: [],
      },
      [DELEGATING_STATES.CAPTAIN]: {
        [SPECIALITIES.CAPTAIN]: [PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CEO],
      },
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.CAPTAIN]: [CORP_DELEGATING_PRIVILEGES.CATS_BUY_FROM_CITY],
      },
    },
    [DELEGATING_SWITCHER_PROPERTY.NON_CEO_GIVES_PRIVILEGES]: {
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.SUPPLY]: [PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CAPTAIN, PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CEO],
      },
    },
  }),
  new DelegatingSwitcher({
    [DELEGATING_SWITCHER_PROPERTY.TYPE]: CORP_DELEGATING_TYPES.BUY_FOOD,
    [DELEGATING_SWITCHER_PROPERTY.LABEL]: 'Покупка корма',
    [DELEGATING_SWITCHER_PROPERTY.VISIBLE]: () => RootStore.appStore.me?.role === PLAYER_ROLE.NURSERY,
    [DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]: {
      [DELEGATING_STATES.CEO]: {
        [SPECIALITIES.CAPTAIN]: [],
      },
      [DELEGATING_STATES.CAPTAIN]: {
        [SPECIALITIES.CAPTAIN]: [PRIVILEGES.APPROVE_FOOD_PURCHASE_BY_CEO],
      },
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.CAPTAIN]: [CORP_DELEGATING_PRIVILEGES[TASK_TYPES.BUY_FOOD]],
      },
    },
    [DELEGATING_SWITCHER_PROPERTY.NON_CEO_GIVES_PRIVILEGES]: {
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.DOCTOR]: [PRIVILEGES.APPROVE_FOOD_PURCHASE_BY_CAPTAIN, PRIVILEGES.APPROVE_FOOD_PURCHASE_BY_CEO],
      },
    },
    [DELEGATING_SWITCHER_PROPERTY.RELATED_LIMIT_TYPES]: {
      [LIMITS_TYPE.SPENDING_ON_FOOD]: true,
    },
  }),
  new DelegatingSwitcher({
    [DELEGATING_SWITCHER_PROPERTY.TYPE]: CORP_DELEGATING_TYPES.BUY_DRUGS,
    [DELEGATING_SWITCHER_PROPERTY.LABEL]: 'Покупка лекарств',
    [DELEGATING_SWITCHER_PROPERTY.VISIBLE]: () => RootStore.appStore.me?.role === PLAYER_ROLE.NURSERY,
    [DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]: {
      [DELEGATING_STATES.CEO]: {
        [SPECIALITIES.CAPTAIN]: [],
      },
      [DELEGATING_STATES.CAPTAIN]: {
        [SPECIALITIES.CAPTAIN]: [PRIVILEGES.APPROVE_DRUGS_PURCHASE_BY_CEO],
      },
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.CAPTAIN]: [CORP_DELEGATING_PRIVILEGES[TASK_TYPES.BUY_DRUGS]],
      },
    },
    [DELEGATING_SWITCHER_PROPERTY.NON_CEO_GIVES_PRIVILEGES]: {
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.DOCTOR]: [PRIVILEGES.APPROVE_DRUGS_PURCHASE_BY_CAPTAIN, PRIVILEGES.APPROVE_DRUGS_PURCHASE_BY_CEO],
      },
    },
    [DELEGATING_SWITCHER_PROPERTY.RELATED_LIMIT_TYPES]: {
      [LIMITS_TYPE.SPENDING_ON_MEDICINES]: true,
    },
  }),
  new DelegatingSwitcher({
    [DELEGATING_SWITCHER_PROPERTY.TYPE]: CORP_DELEGATING_TYPES.BUY_HOUSE,
    [DELEGATING_SWITCHER_PROPERTY.LABEL]: 'Покупка домиков',
    [DELEGATING_SWITCHER_PROPERTY.VISIBLE]: () => RootStore.appStore.me?.role === PLAYER_ROLE.NURSERY,
    [DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]: {
      [DELEGATING_STATES.CEO]: {
        [SPECIALITIES.CAPTAIN]: [],
      },
      [DELEGATING_STATES.CAPTAIN]: {
        [SPECIALITIES.CAPTAIN]: [PRIVILEGES.APPROVE_HOUSE_PURCHASE_BY_CEO],
      },
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.CAPTAIN]: [CORP_DELEGATING_PRIVILEGES[TASK_TYPES.BUY_HOUSE]],
      },
    },
    [DELEGATING_SWITCHER_PROPERTY.NON_CEO_GIVES_PRIVILEGES]: {
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.DOCTOR]: [PRIVILEGES.APPROVE_HOUSE_PURCHASE_BY_CAPTAIN, PRIVILEGES.APPROVE_HOUSE_PURCHASE_BY_CEO],
      },
    },
    [DELEGATING_SWITCHER_PROPERTY.RELATED_LIMIT_TYPES]: {
      [LIMITS_TYPE.SPENDING_ON_HOUSE]: true,
    },
  }),
  new DelegatingSwitcher({
    [DELEGATING_SWITCHER_PROPERTY.TYPE]: CORP_DELEGATING_TYPES.CHANGE_SHOWCASE_PRICES,
    [DELEGATING_SWITCHER_PROPERTY.LABEL]: 'Изменение цен в витрине',
    [DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]: {
      [DELEGATING_STATES.CEO]: {
        [SPECIALITIES.CAPTAIN]: [],
      },
      [DELEGATING_STATES.CAPTAIN]: {
        [SPECIALITIES.CAPTAIN]: [PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_CEO],
      },
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.CAPTAIN]: [CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CHANGE_SHOWCASE_PRICES]],
      },
    },
    [DELEGATING_SWITCHER_PROPERTY.NON_CEO_GIVES_PRIVILEGES]: {
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.MARKETEER]: [
          PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_CAPTAIN,
          PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_CEO,
        ],
      },
    },
  }),
  new DelegatingSwitcher({
    [DELEGATING_SWITCHER_PROPERTY.TYPE]: CORP_DELEGATING_TYPES.MATING,
    [DELEGATING_SWITCHER_PROPERTY.LABEL]: 'Скрещивание котиков',
    [DELEGATING_SWITCHER_PROPERTY.VISIBLE]: () => RootStore.appStore.me?.role === PLAYER_ROLE.NURSERY,
    [DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]: {
      [DELEGATING_STATES.CEO]: {
        [SPECIALITIES.CAPTAIN]: [],
      },
      [DELEGATING_STATES.CAPTAIN]: {
        [SPECIALITIES.CAPTAIN]: [PRIVILEGES.APPROVE_MATING_BY_CEO],
      },
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.CAPTAIN]: [CORP_DELEGATING_PRIVILEGES[TASK_TYPES.MATING]],
      },
    },
    [DELEGATING_SWITCHER_PROPERTY.NON_CEO_GIVES_PRIVILEGES]: {
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.DOCTOR]: [PRIVILEGES.APPROVE_MATING_BY_CAPTAIN, PRIVILEGES.APPROVE_MATING_BY_CEO],
      },
    },
  }),
  new DelegatingSwitcher({
    [DELEGATING_SWITCHER_PROPERTY.TYPE]: CORP_DELEGATING_TYPES.DELEGATE_LTD_REQUEST,
    [DELEGATING_SWITCHER_PROPERTY.LABEL]: 'Делегирование долгосрочных сделок',
    [DELEGATING_SWITCHER_PROPERTY.VISIBLE]: () => RootStore.appStore.withDelegationLtd,
    [DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]: {
      [DELEGATING_STATES.CEO]: {
        [SPECIALITIES.CAPTAIN]: [],
      },
      [DELEGATING_STATES.CAPTAIN]: {
        [SPECIALITIES.CAPTAIN]: [
          PRIVILEGES.APPROVE_LTD_TASK_BY_CAPTAIN,
          PRIVILEGES.CREATE_LTD_TASK,
          PRIVILEGES.APPROVE_LTD_TASK,
        ],
      },
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.CAPTAIN]: [CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CREATE_CHANGE_LTD_REQUEST]],
      },
    },
    [DELEGATING_SWITCHER_PROPERTY.NON_CEO_GIVES_PRIVILEGES]: {
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.SUPPLY]: [
          PRIVILEGES.CREATE_LTD_TASK,
          CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CREATE_CHANGE_LTD_REQUEST],
          PRIVILEGES.APPROVE_LTD_TASK,
        ],
        [SPECIALITIES.SALES]: [
          PRIVILEGES.CREATE_LTD_TASK,
          CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CREATE_CHANGE_LTD_REQUEST],
          PRIVILEGES.APPROVE_LTD_TASK,
        ],
        [SPECIALITIES.MARKETEER]: [PRIVILEGES.APPROVE_LTD_TASK_BY_MARKET, PRIVILEGES.LTD_PAYMENT_ACTION_BY_MARKET],
      },
    },
  }),
  new DelegatingSwitcher({
    [DELEGATING_SWITCHER_PROPERTY.TYPE]: CORP_DELEGATING_TYPES.GET_CREDIT,
    [DELEGATING_SWITCHER_PROPERTY.LABEL]: 'Взятие кредита командой',
    [DELEGATING_SWITCHER_PROPERTY.CHECK_BOX_FOR_READONLY]: new DelegatingSwitcherCheckboxForReadonly({
      [DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.VISIBLE]: true,
      [DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.RELATED_LIMIT_TYPES]: {
        [LIMITS_TYPE.GET_CREDIT]: true,
      },
      [DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.PRIVILEGES]: {
        [SPECIALITIES.MARKETEER]: [PRIVILEGES.GET_CREDIT, PRIVILEGES.CREDIT_REPAY],
      },
      [DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.ADDITIONAL_PRIVILEGES]: {
        [SPECIALITIES.MARKETEER]: [
          PRIVILEGES.APPROVE_GET_CREDIT_BY_CAPTAIN,
          PRIVILEGES.APPROVE_GET_CREDIT_BY_CEO,
          PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CAPTAIN,
          PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CEO,
        ],
        [SPECIALITIES.CAPTAIN]: [
          PRIVILEGES.APPROVE_GET_CREDIT_BY_CEO,
          PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CEO,
          CORP_DELEGATING_PRIVILEGES[TASK_TYPES.GET_CREDIT],
          CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CREDIT_REPAY],
        ],
      },
      [DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.CALLBACK]: ({ areaNum }) => {
        const switcher = Object.values(CORP_DELEGATING_SWITCHERS).find((switcher) => {
          return switcher[DELEGATING_SWITCHER_PROPERTY.TYPE] === CORP_DELEGATING_TYPES.CREDIT_REPAY;
        });
        switcher.changePrivileges({ state: DELEGATING_STATES.CEO, areaNum });
      },
    }),
    [DELEGATING_SWITCHER_PROPERTY.VISIBLE]: () => RootStore.appStore.credits?.credit_mechanics,
    [DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]: {
      [DELEGATING_STATES.CEO]: {
        [SPECIALITIES.CAPTAIN]: [],
      },
      [DELEGATING_STATES.CAPTAIN]: {
        [SPECIALITIES.CAPTAIN]: [PRIVILEGES.APPROVE_GET_CREDIT_BY_CEO],
      },
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.CAPTAIN]: [CORP_DELEGATING_PRIVILEGES[TASK_TYPES.GET_CREDIT]],
      },
    },
    [DELEGATING_SWITCHER_PROPERTY.NON_CEO_GIVES_PRIVILEGES]: {
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.MARKETEER]: [PRIVILEGES.APPROVE_GET_CREDIT_BY_CAPTAIN, PRIVILEGES.APPROVE_GET_CREDIT_BY_CEO],
      },
    },
    [DELEGATING_SWITCHER_PROPERTY.RELATED_LIMIT_TYPES]: {
      [LIMITS_TYPE.GET_CREDIT]: true,
    },
  }),
  new DelegatingSwitcher({
    [DELEGATING_SWITCHER_PROPERTY.TYPE]: CORP_DELEGATING_TYPES.CREDIT_REPAY,
    [DELEGATING_SWITCHER_PROPERTY.LABEL]: 'Досрочное погашение кредита',
    [DELEGATING_SWITCHER_PROPERTY.CHECK_BOX_FOR_READONLY]: new DelegatingSwitcherCheckboxForReadonly({
      [DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.VISIBLE]: false,
      [DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.RELATED_LIMIT_TYPES]: {
        [LIMITS_TYPE.GET_CREDIT]: true,
      },
      [DELEGATING_SWITCHER_CHECKBOX_FOR_READONLY_PROPERTY.PRIVILEGES]: {
        [SPECIALITIES.MARKETEER]: [PRIVILEGES.GET_CREDIT, PRIVILEGES.CREDIT_REPAY],
      },
    }),
    [DELEGATING_SWITCHER_PROPERTY.VISIBLE]: () => RootStore.appStore.credits?.credit_mechanics,
    [DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]: {
      [DELEGATING_STATES.CEO]: {
        [SPECIALITIES.CAPTAIN]: [],
      },
      [DELEGATING_STATES.CAPTAIN]: {
        [SPECIALITIES.CAPTAIN]: [PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CEO],
      },
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.CAPTAIN]: [CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CREDIT_REPAY]],
      },
    },
    [DELEGATING_SWITCHER_PROPERTY.NON_CEO_GIVES_PRIVILEGES]: {
      [DELEGATING_STATES.TEAM]: {
        [SPECIALITIES.MARKETEER]: [PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CAPTAIN, PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CEO],
      },
    },
  }),
];
