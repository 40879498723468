import RootStore from '../store';
import _ from 'lodash';
import corpStore from '../store/CorpStore';
import { LTD_STATUS } from './constants';

export const MONEY_TRANSFER_REASON = {
  // Различные статьи расходов или доходов игрока.
  SELLING_CATS: 'selling_cats',
  BUYING_CATS: 'buying_cats',
  BUYING_HOUSES: 'buying_houses',
  HOUSEHOLD: 'household',
  HOUSEHOLD_FOR_MY_COMPANY: 'household_for_my_company',
  FOOD: 'food',
  TREATMENT: 'treatment',
  INSURANCE: 'insurance',
  CREDIT: 'credit',
  PAYMENT_BODY_CREDIT: 'payment_body_credit',
  PAYMENT_PERCENT_CREDIT: 'payment_percent_credit',
  PREPAID: 'prepaid',
  PAYMENT_BEFORE_SHIPMENT: 'payment_before_shipment',
  PAYMENT_AFTER_SHIPMENT: 'payment_after_shipment',
  TERMINATION: 'termination',
  FINE_PAYMENT: 'fine_payment',
  BANK_GUARANTEE_PAYMENT: 'bank_guarantee_payment',
  OPPONENT_BANK_GUARANTEE: 'opponent_bank_guarantee',
  // ↓ Корпоративная версия ↓
  IMPORT_PREPAID: 'import_prepaid',
  EXPORT_PREPAID: 'export_prepaid',
  IMPORT_AUTO_POSTPAID: 'import_auto_postpaid',
  EXPORT_AUTO_POSTPAID: 'export_auto_postpaid',
  IMPORT_POSTPAID: 'import_postpaid',
  BUY_CAR: 'buy_car',
  RENT_CAR: 'rent_car',
  BUDGET_ALLOCATION: 'budget_allocation',
};

const getGroupedCatsContent = (content) => {
  let result = [];
  content.forEach((fh) => {
    let player = _.find(result, { playerUuid: fh.target });
    if (!player) {
      player = { playerUuid: fh.target, cats: {}, totalPrice: 0 };
      result.push(player);
    }
    fh.content.forEach((cat) => {
      if (!player.cats[cat.gender + cat.color]) {
        player.cats[cat.gender + cat.color] = [];
      }
      let priceObj = _.find(player.cats[cat.gender + cat.color], { price: cat.price });
      if (!priceObj) {
        priceObj = { count: 0, price: cat.price };
        player.cats[cat.gender + cat.color].push(priceObj);
      }
      priceObj.count += cat.count;
      player.totalPrice += cat.count * cat.price;
    });
  });
  return result;
};

const financialHistoryObject = (
  corporateBalanceDetail,
  currentSeason,
  currentBalanceSeason,
  financeHistory,
  balance,
  household,
  currentTargetBalanceDetails,
) => {
  const { appStore } = RootStore;
  const result = {};
  const currentFinanceHistory = financeHistory.filter((fh) => {
    if (corporateBalanceDetail) {
      const company = corpStore.corporationCaptains.find((c) => c.player_id === fh.target);
      if (
        company?.game_over_season === currentBalanceSeason &&
        [
          MONEY_TRANSFER_REASON.HOUSEHOLD,
          MONEY_TRANSFER_REASON.PAYMENT_BODY_CREDIT,
          MONEY_TRANSFER_REASON.PAYMENT_PERCENT_CREDIT,
        ].includes(fh.reason)
      ) {
        return false;
      }
    }
    return fh;
  });

  const financeHistoryForSeason = currentFinanceHistory.filter((fh) => fh.season === currentBalanceSeason);
  const financeHistoryForStartBalance = currentFinanceHistory.filter((fh) => fh.season >= currentBalanceSeason);

  const differenceHouseholdForMyCompany = financeHistoryForSeason
    .filter((i) => i.reason === MONEY_TRANSFER_REASON.HOUSEHOLD_FOR_MY_COMPANY)
    .reduce((acc, fh) => acc + appStore.gameCosts.household + fh.delta, 0);

  result.startBalance =
    balance - financeHistoryForStartBalance.reduce((sum, fh) => sum + fh.delta, 0) + differenceHouseholdForMyCompany;

  /*==================РАСПРЕДЕЛЕНИЕ БЮДЖЕТА (ДЛЯ КОРПОРАЦИИ)/*==================*/
  const budgetAllocationFH = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.BUDGET_ALLOCATION,
  );

  let selection = [];
  let withdrawal = [];
  let incomeSelection = 0;
  let outcomeWithdrawal = 0;

  if (appStore.me) {
    selection = budgetAllocationFH
      .filter((fh) => {
        if (!corporateBalanceDetail && appStore.meIsCEO) {
          return fh.target === appStore.me.uuid && fh.delta > 0;
        } else if (corporateBalanceDetail) {
          return fh.target !== appStore.ceo_uuid && fh.delta < 0;
        } else {
          return fh.delta > 0;
        }
      })
      .map((item) => {
        if (!corporateBalanceDetail) {
          incomeSelection += Math.abs(item.delta);
          return {
            delta: item.delta,
            target: '',
          };
        } else {
          return {
            delta: Math.abs(item.delta),
            target: `в город ${
              corpStore.corporationCaptains.find((captain) => captain.player_id === item.target).areaNum
            }`,
          };
        }
      });
    withdrawal = budgetAllocationFH
      .filter((fh) => {
        if (!corporateBalanceDetail && appStore.meIsCEO) {
          return fh.target === appStore.me.uuid && fh.delta < 0;
        } else if (corporateBalanceDetail) {
          return fh.target !== appStore.ceo_uuid && fh.delta > 0;
        } else {
          return fh.delta < 0;
        }
      })
      .map((item) => {
        if (!corporateBalanceDetail) {
          outcomeWithdrawal += Math.abs(item.delta);
          return {
            delta: Math.abs(item.delta),
            target: '',
          };
        } else {
          return {
            delta: item.delta,
            target: `из города ${
              corpStore.corporationCaptains.find((captain) => captain.player_id === item.target).areaNum
            }`,
          };
        }
      });
  }

  result.budgetAllocationFH = {
    selection: selection,
    withdrawal: withdrawal,
    allAllocation: incomeSelection - outcomeWithdrawal,
  };
  /*==================КОНЕЦ РАСПРЕДЕЛЕНИЯ БЮДЖЕТА/*==================*/

  /*==================ДОХОД/*==================*/
  const sellingCatsFH = financeHistoryForSeason.filter((fh) => fh.reason === MONEY_TRANSFER_REASON.SELLING_CATS);
  const exportPrepaidFH = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.EXPORT_PREPAID && fh.delta > 0,
  );
  const exportPostpaidFH = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.EXPORT_AUTO_POSTPAID && fh.delta > 0,
  );

  // total price за аванс и постоплату экспорт
  const totalPriceExportPrepaidFH = financeHistoryForSeason
    .filter((fh) => fh.reason === MONEY_TRANSFER_REASON.EXPORT_PREPAID && fh.delta > 0)
    .reduce((sum, fh) => sum + fh.delta, 0);
  const totalPriceExportPostpaidFH = financeHistoryForSeason
    .filter((fh) => fh.reason === MONEY_TRANSFER_REASON.EXPORT_AUTO_POSTPAID && fh.delta > 0)
    .reduce((sum, fh) => sum + fh.delta, 0);

  const totalSellingCats = getGroupedCatsContent(sellingCatsFH).reduce((sum, player) => sum + player.totalPrice, 0);
  const totalExportCats = totalPriceExportPrepaidFH + totalPriceExportPostpaidFH;
  result.income = {
    sellingCats: getGroupedCatsContent(sellingCatsFH),
    totalSellingCats: totalSellingCats,
    exportPrepaidCats: { cats: getGroupedCatsContent(exportPrepaidFH), totalPrice: totalPriceExportPrepaidFH },
    exportPostpaidCats: { cats: getGroupedCatsContent(exportPostpaidFH), totalPrice: totalPriceExportPostpaidFH },
    totalExportCats: totalExportCats,
    allCats: totalSellingCats + totalPriceExportPrepaidFH + totalPriceExportPostpaidFH,
  };
  /*==================КОНЕЦ ДОХОД/*==================*/

  /*==================ДОХОД ПО ДОЛГОСРОЧНЫМ СДЕЛКАМ/*==================*/
  const prepaid_income = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.PREPAID && Math.sign(fh.delta) === 1,
  );
  const payment_before_shipment_income = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.PAYMENT_BEFORE_SHIPMENT && Math.sign(fh.delta) === 1,
  );
  const payment_after_shipment_income = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.PAYMENT_AFTER_SHIPMENT && Math.sign(fh.delta) === 1,
  );
  const termination_income = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.TERMINATION && Math.sign(fh.delta) === 1,
  );
  const fine_payment_income = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.FINE_PAYMENT && Math.sign(fh.delta) === 1,
  );
  const bank_guarantee_income = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.BANK_GUARANTEE_PAYMENT && Math.sign(fh.delta) === 1,
  );
  const opponent_bank_guarantee = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.OPPONENT_BANK_GUARANTEE && Math.sign(fh.delta) === 1,
  );

  const prepaidSumIncome = prepaid_income.reduce((sum, fh) => sum + fh.delta, 0);
  const paymentBeforeShipmentSumIncome = payment_before_shipment_income.reduce((sum, fh) => sum + fh.delta, 0);
  const paymentAfterShipmentSumIncome = payment_after_shipment_income.reduce((sum, fh) => sum + fh.delta, 0);
  const terminationSumIncome = termination_income.reduce((sum, fh) => sum + fh.delta, 0);
  const finePaymentSumIncome = fine_payment_income.reduce((sum, fh) => sum + fh.delta, 0);
  const bankGuaranteeSumIncome = bank_guarantee_income.reduce((sum, fh) => sum + fh.delta, 0);
  const opponentBankGuarantee = opponent_bank_guarantee.reduce((sum, fh) => sum + fh.delta, 0);

  result.incomeLTD = {
    prepaid: prepaidSumIncome !== 0 && {
      total_sum: prepaidSumIncome,
      contents: prepaid_income.map((content) => {
        return {
          uuid: content.target,
          sum: content.delta,
        };
      }),
    },
    payment_before_shipment: paymentBeforeShipmentSumIncome !== 0 && {
      total_sum: paymentBeforeShipmentSumIncome,
      contents: payment_before_shipment_income.map((content) => {
        return {
          uuid: content.target,
          sum: content.delta,
        };
      }),
    },
    payment_after_shipment: paymentAfterShipmentSumIncome !== 0 && {
      total_sum: paymentAfterShipmentSumIncome,
      contents: payment_after_shipment_income.map((content) => {
        return {
          uuid: content.target,
          sum: content.delta,
        };
      }),
    },
    termination: terminationSumIncome !== 0 && {
      total_sum: terminationSumIncome,
      contents: termination_income.map((content) => {
        return {
          uuid: content.target,
          sum: content.delta,
        };
      }),
    },
    fine_payment: finePaymentSumIncome !== 0 && {
      total_sum: finePaymentSumIncome,
      contents: fine_payment_income.map((content) => {
        return {
          uuid: content.target,
          sum: content.delta,
        };
      }),
    },
    bank_guarantee: bankGuaranteeSumIncome !== 0 && bankGuaranteeSumIncome,
    opponent_bank_guarantee: opponentBankGuarantee !== 0 && {
      total_sum: opponentBankGuarantee,
      contents: opponent_bank_guarantee.map((content) => {
        return {
          uuid: content.target,
          sum: content.delta,
        };
      }),
    },
    all:
      prepaidSumIncome +
      paymentBeforeShipmentSumIncome +
      paymentAfterShipmentSumIncome +
      terminationSumIncome +
      finePaymentSumIncome +
      bankGuaranteeSumIncome +
      opponentBankGuarantee,
  };
  /*==================КОНЕЦ ДОХОДА ПО ДОЛГОСРОЧНЫМ СДЕЛКАМ/*==================*/

  /*==================РАСХОД ПО ДОЛГОСРОЧНЫМ СДЕЛКАМ/*==================*/
  const prepaid_outcome = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.PREPAID && Math.sign(fh.delta) === -1,
  );
  const payment_before_shipment_outcome = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.PAYMENT_BEFORE_SHIPMENT && Math.sign(fh.delta) === -1,
  );
  const payment_after_shipment_outcome = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.PAYMENT_AFTER_SHIPMENT && Math.sign(fh.delta) === -1,
  );
  const termination_outcome = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.TERMINATION && Math.sign(fh.delta) === -1,
  );
  const fine_payment_outcome = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.FINE_PAYMENT && Math.sign(fh.delta) === -1,
  );
  const bank_guarantee_outcome = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.BANK_GUARANTEE_PAYMENT && Math.sign(fh.delta) === -1,
  );

  const prepaidSumOutcome = prepaid_outcome.reduce((sum, fh) => sum + fh.delta, 0) * -1;
  const paymentBeforeShipmentSumOutcome = payment_before_shipment_outcome.reduce((sum, fh) => sum + fh.delta, 0) * -1;
  const paymentAfterShipmentSumOutcome = payment_after_shipment_outcome.reduce((sum, fh) => sum + fh.delta, 0) * -1;
  const terminationSumOutcome = termination_outcome.reduce((sum, fh) => sum + fh.delta, 0) * -1;
  const finePaymentSumOutcome = fine_payment_outcome.reduce((sum, fh) => sum + fh.delta, 0) * -1;
  let bankGuaranteeSumOutcome = bank_guarantee_outcome.reduce((sum, fh) => sum + fh.delta, 0) * -1;

  if (currentBalanceSeason === currentSeason && appStore.with_ltd) {
    bankGuaranteeSumOutcome = appStore.longTermsDeals?.ltd_info?.reduce((sum, deal) => {
      const initiatorConfirmed = deal.initiator_confirmed === deal.partner_confirmed;
      const hasInitiatorBankGuarantee = deal.initiator_bank_guarantee;
      const hasPartnerBankGuarantee = deal.partner_bank_guarantee;

      if (
        initiatorConfirmed &&
        deal.status !== LTD_STATUS.CANCELED &&
        deal.status !== LTD_STATUS.TERMINATED &&
        deal.status !== LTD_STATUS.TERMINATING &&
        (hasInitiatorBankGuarantee || hasPartnerBankGuarantee)
      ) {
        const meInitiator = deal.initiator === appStore.me.uuid;
        let bgPaymentKeys = null;

        if (meInitiator && hasInitiatorBankGuarantee) {
          bgPaymentKeys = Object.keys(deal.initiator_bank_guarantee.payments_schedule).find(
            (key) => key == currentSeason + 1,
          );
        } else if (!meInitiator && hasPartnerBankGuarantee) {
          bgPaymentKeys = Object.keys(deal.partner_bank_guarantee.payments_schedule).find(
            (key) => key == currentSeason + 1,
          );
        }

        if (bgPaymentKeys && bgPaymentKeys == currentSeason + 1) {
          const payment = meInitiator
            ? deal.initiator_bank_guarantee.payments_schedule[bgPaymentKeys]
            : deal.partner_bank_guarantee.payments_schedule[bgPaymentKeys];

          return sum + payment;
        }
      }

      return sum;
    }, 0);
  }

  result.outcomeLTD = {
    prepaid: prepaidSumOutcome !== 0 && {
      total_sum: prepaidSumOutcome,
      contents: prepaid_outcome.map((content) => {
        return {
          uuid: content.target,
          sum: content.delta * -1,
        };
      }),
    },
    payment_before_shipment: paymentBeforeShipmentSumOutcome !== 0 && {
      total_sum: paymentBeforeShipmentSumOutcome,
      contents: payment_before_shipment_outcome.map((content) => {
        return {
          uuid: content.target,
          sum: content.delta * -1,
        };
      }),
    },
    payment_after_shipment: paymentAfterShipmentSumOutcome !== 0 && {
      total_sum: paymentAfterShipmentSumOutcome,
      contents: payment_after_shipment_outcome.map((content) => {
        return {
          uuid: content.target,
          sum: content.delta * -1,
        };
      }),
    },
    termination: terminationSumOutcome !== 0 && {
      total_sum: terminationSumOutcome,
      contents: termination_outcome.map((content) => {
        return {
          uuid: content.target,
          sum: content.delta * -1,
        };
      }),
    },
    fine_payment: finePaymentSumOutcome !== 0 && {
      total_sum: finePaymentSumOutcome,
      contents: fine_payment_outcome.map((content) => {
        return {
          uuid: content.target,
          sum: content.delta * -1,
        };
      }),
    },
    bank_guarantee: bankGuaranteeSumOutcome !== 0 && bankGuaranteeSumOutcome,
    all:
      prepaidSumOutcome +
      paymentBeforeShipmentSumOutcome +
      paymentAfterShipmentSumOutcome +
      terminationSumOutcome +
      finePaymentSumOutcome +
      bankGuaranteeSumOutcome,
  };
  /*==================КОНЕЦ РАСХОДА ПО ДОЛГОСРОЧНЫМ СДЕЛКАМ/*==================*/

  /*==================КРЕДИТ/*==================*/
  let paymentBodyCredit =
    financeHistoryForSeason
      .filter((fh) => fh.reason === MONEY_TRANSFER_REASON.PAYMENT_BODY_CREDIT)
      .reduce((sum, fh) => sum + fh.delta, 0) * -1;

  let paymentPercentCredit =
    financeHistoryForSeason
      .filter((fh) => fh.reason === MONEY_TRANSFER_REASON.PAYMENT_PERCENT_CREDIT)
      .reduce((sum, fh) => sum + fh.delta, 0) * -1;

  if (currentBalanceSeason === currentSeason) {
    appStore.activeCreditBodies?.forEach((item) => {
      if (
        !appStore.isCorpMode ||
        (appStore.isCorpMode && !appStore.meIsCEO) ||
        corporateBalanceDetail ||
        (!corporateBalanceDetail && item.areaNum === currentTargetBalanceDetails)
      ) {
        item.credit_bodies
          .filter((itemBody) => itemBody.season === currentBalanceSeason)
          .forEach((itemBody) => {
            paymentBodyCredit += itemBody.body_payment;
            paymentPercentCredit += itemBody.percent_payment;
          });
      }
    });
  }

  const takenCredit = financeHistoryForSeason
    .filter((fh) => fh.reason === MONEY_TRANSFER_REASON.CREDIT)
    .reduce((sum, fh) => sum + fh.delta, 0);

  result.credit = {
    takenCredit,
    paymentBodyCredit,
    paymentPercentCredit,
  };
  /*==================КОНЕЦ КРЕДИТ/*==================*/

  /*==================РАСХОД/*==================*/
  const buyingCatsFH = financeHistoryForSeason.filter((fh) => fh.reason === MONEY_TRANSFER_REASON.BUYING_CATS);
  const importPrepaidFH = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.IMPORT_PREPAID && fh.delta < 0,
  );
  const importPostpaidFH = financeHistoryForSeason.filter(
    (fh) => fh.reason === MONEY_TRANSFER_REASON.IMPORT_AUTO_POSTPAID && fh.delta < 0,
  );

  // total price за аванс и постоплату импорт
  const totalPriceImportPrepaidFH =
    financeHistoryForSeason
      .filter((fh) => fh.reason === MONEY_TRANSFER_REASON.IMPORT_PREPAID && fh.delta < 0)
      .reduce((sum, fh) => sum + fh.delta, 0) * -1;
  let totalPriceImportPostpaidFH =
    financeHistoryForSeason
      .filter((fh) => fh.reason === MONEY_TRANSFER_REASON.IMPORT_AUTO_POSTPAID && fh.delta < 0)
      .reduce((sum, fh) => sum + fh.delta, 0) * -1;

  // Т.к. списание за постоплату по импорту происходит при пересчете (после завершения сезона)
  // необходимо отрисовать данную инф-ю заранее по corpStore.transfers
  if (currentBalanceSeason === currentSeason && corporateBalanceDetail) {
    const currentTransfers = corpStore.transfers.filter(
      (transfer) => transfer.buyer && transfer.delivery_season === currentBalanceSeason,
    );
    currentTransfers.forEach((transfer) => {
      if (!_.find(corpStore.corporationCaptains, { player_id: transfer.buyer })?.active) {
        return;
      }
      transfer.contents.forEach((content) => {
        totalPriceImportPostpaidFH += content.count * content.price;
      });
      importPostpaidFH.push({
        delta: 0,
        reason: MONEY_TRANSFER_REASON.IMPORT_POSTPAID,
        season: transfer.delivery_season,
        target: null,
        content: transfer.contents,
      });
    });
    /**
     *  Аванс = сумма котят * 30%
     *  Постоплата = сумма котят - сумма аванса
     */
    totalPriceImportPostpaidFH = totalPriceImportPostpaidFH - Math.ceil(totalPriceImportPostpaidFH * 0.3);
  }

  const totalBuyingCats = getGroupedCatsContent(buyingCatsFH).reduce((sum, player) => sum + player.totalPrice, 0);
  const totalImportCats = totalPriceImportPrepaidFH + totalPriceImportPostpaidFH;

  const food =
    financeHistoryForSeason
      .filter((fh) => fh.reason === MONEY_TRANSFER_REASON.FOOD)
      .reduce((sum, fh) => sum + fh.delta, 0) * -1;

  const treatment =
    financeHistoryForSeason
      .filter((fh) => fh.reason === MONEY_TRANSFER_REASON.TREATMENT)
      .reduce((sum, fh) => sum + fh.delta, 0) * -1;

  const insurance =
    financeHistoryForSeason
      .filter((fh) => fh.reason === MONEY_TRANSFER_REASON.INSURANCE)
      .reduce((sum, fh) => sum + fh.delta, 0) * -1;

  const houses =
    financeHistoryForSeason
      .filter((fh) => fh.reason === MONEY_TRANSFER_REASON.BUYING_HOUSES)
      .reduce((sum, fh) => sum + fh.delta, 0) * -1;

  const buyCar =
    financeHistoryForSeason
      .filter((fh) => fh.reason === MONEY_TRANSFER_REASON.BUY_CAR)
      .reduce((sum, fh) => sum + fh.delta, 0) * -1;

  const rentCar =
    financeHistoryForSeason
      .filter((fh) => fh.reason === MONEY_TRANSFER_REASON.RENT_CAR)
      .reduce((sum, fh) => sum + fh.delta, 0) * -1;

  result.outcome = {
    household,
    buyingCats: getGroupedCatsContent(buyingCatsFH),
    totalBuyingCats: totalBuyingCats,
    importPrepaidCats: { cats: getGroupedCatsContent(importPrepaidFH), totalPrice: totalPriceImportPrepaidFH },
    importPostpaidCats: { cats: getGroupedCatsContent(importPostpaidFH), totalPrice: totalPriceImportPostpaidFH },
    totalImportCats: totalImportCats,
    food,
    treatment,
    insurance,
    houses,
    buyCar,
    rentCar,
    all: household + totalBuyingCats + totalImportCats + food + treatment + insurance + houses + buyCar + rentCar,
  };
  /*==================КОНЕЦ РАСХОДА/*==================*/

  /*==================ЗАТРАТЫ/*==================*/
  result.expenses = result.outcome.all;
  /*==================КОНЕЦ ЗАТРАТ/*==================*/

  /*==================ПРИБЫЛЬ/*==================*/
  result.profit =
    result.income.allCats -
    result.expenses -
    result.credit.paymentPercentCredit +
    result.incomeLTD.all -
    result.outcomeLTD.all;
  /*==================КОНЕЦ ПРИБЫЛИ/*==================*/

  /*==================ДЕНЬГИ НА КОНЕЦ ХОДА/*==================*/
  result.endBalance =
    result.startBalance +
    result.budgetAllocationFH.allAllocation +
    result.income.allCats +
    result.credit.takenCredit -
    result.outcome.all -
    result.credit.paymentBodyCredit -
    result.credit.paymentPercentCredit +
    result.incomeLTD.all -
    result.outcomeLTD.all;
  /*==================КОНЕЦ ДЕНЕГ НА КОНЕЦ ХОДА/*==================*/

  /*==================ОСТАТОК/*==================*/
  result.remainder =
    result.startBalance +
    result.budgetAllocationFH.allAllocation +
    result.credit.takenCredit +
    result.income.allCats -
    result.outcome.all -
    result.credit.paymentBodyCredit -
    result.credit.paymentPercentCredit +
    result.incomeLTD.all -
    result.outcomeLTD.all;
  /*==================КОНЕЦ ОСТАТОК/*==================*/

  return result;
};

export default financialHistoryObject;
